import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { OwlOptions } from "ngx-owl-carousel-o";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgwWowService } from "ngx-wow";

import { Solution } from "../types/solution.type";
import { SolutionService } from "../services/solution.service";

@Component({
  selector: "app-blockchain",
  templateUrl: "./blockchain.component.html",
  styleUrls: ["./blockchain.component.css"],
})
export class BlockchainComponent implements OnInit {
  content: any = {};
  contentLoaded: boolean = false;
  @ViewChild("scrollHere") scrollHere: any;

  customOptions: OwlOptions = {
    loop: true,
    margin: 10,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    dots: true,
    items: 2,
    responsive: {
      0: {
        items: 1,
      },
    },
  };

  customOptions1: OwlOptions = {
    loop: true,
    margin: 10,
    dots: false,
    items: 4,
    autoplay: true,
    autoplayTimeout: 2500,
    autoWidth: true,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
        nav: true,
        autoWidth: false,
      },
      479: {
        items: 2,
        nav: true,
        autoWidth: false,
      },
      767: {
        items: 4,
        nav: true,
      },
    },
  };

  constructor(
    private ngxService: NgxUiLoaderService,
    private wowService: NgwWowService,
    private router: Router,
    private solutionService: SolutionService,
    @Inject(DOCUMENT) private _doc: Document
  ) {}

  ngOnInit(): void {
    this.wowService.init();
    this.ngxService.start("loading-blockchain");

    const solution: Solution = {
      route: "blockchain",
      id: 0,
      data: null,
    };

    this.solutionService
      .get(solution)
      .toPromise()
      .then(
        (data: any) => {
          this.content = data;
          this.contentLoaded = true;
          this.ngxService.stop("loading-blockchain");
        },
        () => {
          this.ngxService.stop("loading-blockchain");
        }
      );
  }

  scroll() {
    var element = this.scrollHere.nativeElement;
    const offset = 100;
    const bodyRect = this._doc.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    this._doc.defaultView.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
}
