import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgwWowService } from 'ngx-wow';

import { Product } from '../types/product.type';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-sylosys',
  templateUrl: './sylosys.component.html',
  styleUrls: ['./sylosys.component.css']
})
export class SylosysComponent implements OnInit {

  content: any = {};
  contentLoaded: boolean = false;
  @ViewChild('scrollHere') scrollHere: any;

  constructor(private ngxService: NgxUiLoaderService, private wowService: NgwWowService, private productService: ProductService, @Inject(DOCUMENT) private _doc: Document) { }

  ngOnInit(): void {
    this.wowService.init(); 
    this.ngxService.start('loading-silosys');

    const product: Product = {
      route: 'silosys',
      id: 0,
      data: null
    }

    this.productService.get(product)
    .toPromise()
    .then((data: any) => {
      this.content = data;
      this.contentLoaded = true;
      this.ngxService.stop('loading-silosys');
    }, () => {
      this.ngxService.stop('loading-silosys');
    });
  }

  scroll(){
    var element = this.scrollHere.nativeElement;
    const offset = 45;
    const bodyRect = this._doc.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    
    this._doc.defaultView.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
    
  }
}
