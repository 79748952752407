import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgwWowService } from 'ngx-wow';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Home } from '../types/home.type';
import { HomeService } from '../services/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  content: any = {};
  contentLoaded: boolean = false;

  customOptions: OwlOptions = {
    loop: true,
    margin: 10,
    navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
    dots: false,
    nav: true,
    items: 5,
    responsive: {
      0: {
        items: 5
      }
    }
  }

  modalRef: BsModalRef | null;

  constructor(private router: Router, private ngxService: NgxUiLoaderService, private wowService: NgwWowService, private homeService: HomeService, private modalService: BsModalService, @Inject(DOCUMENT) private _doc: Document) { }

  ngOnInit(): void {
    this.wowService.init(); 
    this.ngxService.start('loading-home');

    const home: Home = {
      route: 'index',
      id: 0,
      data: null
    }

    this.homeService.get(home)
    .toPromise()
    .then((data: any) => {
      this.content = data;

      home.route = 'partner';
      this.homeService.getAll(home)
      .toPromise()
      .then((data: any) => {
        this.content.partners = data;
        this.contentLoaded = true;
        this.ngxService.stop('loading-home');
      }, () => {
        this.ngxService.stop('loading-home');
      });
    }, () => {
      this.ngxService.stop('loading-home');
    });
  }

  link(url: string) {
    this._doc.defaultView.open(url,'_blank');
  }

  readMore() {
    this.router.navigateByUrl('/blockchain')
  }

  redPg(num) {
    if (num === 1) {
      this.router.navigateByUrl('/trumodity')
    }
    if (num === 2) {
      this.router.navigateByUrl('/seed-audit')
    }
    if (num === 3) {
      this.router.navigateByUrl('/silosys')
    }
    if (num === 4) {
      this.router.navigateByUrl('/silosys-transformation')
    }
    if (num === 5) {
      this.router.navigateByUrl('/harvx')
    }
  }

  openModal(template: any){
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg modal-dialog-centered' })
    );
  }

  closeModal() {
    this.modalRef.hide();
  }
}
