import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LoginService } from "./../services/login.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {forkJoin} from 'rxjs';
import { Router } from "@angular/router";
import { NgwWowService } from 'ngx-wow';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit {

  @ViewChild('scrollHere') scrollHere: any;

  constructor(private loginService: LoginService,private modalService: BsModalService, private ngxService: NgxUiLoaderService,private formBuilder:FormBuilder,private toastr: ToastrService,private router: Router,private wowService: NgwWowService, @Inject(DOCUMENT) private _doc: Document) { }
  pgContent:any
  imgs:any;
  ngOnInit(): void {
    this.wowService.init(); 
    let getProductContent = this.loginService.getCarrerContent();
    let getProductLower = this.loginService.getCarrierImages();
    this.ngxService.start();

    forkJoin(getProductContent,getProductLower)
    .subscribe(results => {
      
      let getProductContent = results[0]['success'];
      let getProductLower = results[1]['success'];
      this.pgContent = getProductContent[0]
      this.imgs = getProductLower
      console.log(this.imgs)
      this.ngxService.stop();
    })
  }
  viewMore(){
     
    this.router.navigateByUrl('/carrers/all/')
   }

  openLink(link){
   this._doc.defaultView.open(link,'_blank')
  }
  scroll(){
    let el = this.scrollHere.nativeElement;
    if(el !=null){
      el.scrollIntoView({behavior: 'smooth'});
     
    }
  }
}
