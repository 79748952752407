<ng-container *ngIf="contentLoaded">
    <section
        class="banner banner-solution bg-dark-green banner-2"
        [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
    >
        <div class="container-inner">
            <div class="banner-content">
                <h1
                    class="
                        title
                        text-white
                        wow
                        animate__animated animate__fadeInUp
                    "
                >
                    <span>{{ content.a1_text }} </span>
                </h1>
                <p
                    class="
                        text-white
                        wow
                        animate__animated animate__fadeInUp animate__delay0-5s
                    "
                >
                    {{ content.b1_text }}
                </p>
                <app-book-ademo></app-book-ademo>
            </div>
        </div>
        <!-- scroll addded -->
        <div class="btn-scroll-wrapper" (click)="scroll()">
            <button
                class="
                    btn-scroll
                    wow
                    animate__animated animate__fadeInUp animate__delay-1s
                "
            >
                <span class="fa fa-angle-down"></span>
            </button>
        </div>
        <!-- scroll addded -->
    </section>
    <section #scrollHere class="problem bg-dark-green banner-next">
        <div class="container-inner">
            <div class="problem-inner mobile-hide">
                <div class="row">
                    <div class="col-lg-3 col-md-4">
                        <div
                            class="
                                problem-box
                                contain-title
                                text-white
                                wow
                                animate__animated animate__fadeInUp
                            "
                        >
                            <h2
                                class="
                                    title
                                    text-left
                                    bottom
                                    font-weight-bold
                                    text-white
                                "
                            >
                                <span>{{ content.c1_text }}</span>
                            </h2>
                            <p>{{ content.c2_text }}</p>
                        </div>
                    </div>

                    <div
                        class="col-lg-3 col-md-4"
                        *ngFor="let n of [1, 2, 3, 4, 5, 6, 7]"
                    >
                        <div
                            class="
                                problem-box
                                text-white
                                wow
                                animate__animated animate__fadeInUp
                            "
                        >
                            <img
                                class="img-fluid"
                                src="{{ content['b' + n + '_image'] }}"
                                alt=""
                            />
                            <h5 class="text-center">
                                {{ content["d" + n + "_text"] }}
                            </h5>
                            <div class="overlay text-white">
                                <p>{{ content["e" + n + "_text"] }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="problem-inner desktop-hide">
                <h2
                    class="
                        title
                        text-left
                        bottom
                        font-weight-bold
                        text-white
                        wow
                        animate__animated animate__fadeInUp
                    "
                >
                    <span>{{ content.c1_text }} </span>
                </h2>
                <p
                    class="
                        wow
                        animate__animated animate__fadeInUp animate__delay0-5s
                    "
                >
                    {{ content.c2_text }}
                </p>

                <div class="problem-list">
                    <owl-carousel-o [options]="customOptions1">
                        <ng-template
                            *ngFor="
                                let n of [1, 2, 3, 4, 5, 6, 7];
                                let i = index
                            "
                            carouselSlide
                            [id]="i + 1"
                        >
                            <div class="col-lg-3 col-md-4">
                                <div class="problem-box text-white">
                                    <img
                                        class="img-fluid"
                                        src="{{ content['b' + n + '_image'] }}"
                                        alt=""
                                    />
                                    <h5 class="text-center">
                                        {{ content["d" + n + "_text"] }}
                                    </h5>
                                    <div class="overlay text-white">
                                        <p>{{ content["e" + n + "_text"] }}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
        <div class="problem-bottom-bg">
            <img
                class="img-fluid"
                src="assets/images/solutions/bgshape-green.webp"
                alt=""
            />
        </div>
    </section>

    <section class="solutions-main">
        <div class="container-inner">
            <h2
                class="
                    title
                    bottom
                    font-weight-bold
                    text-left
                    orange
                    text-green
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <span>{{ content.f1_text }}</span>
            </h2>
            <div class="solutions-inner">
                <div class="row">
                    <div class="col-md-4" *ngFor="let n of [1, 2, 3]">
                        <div
                            class="
                                solution-box
                                wow
                                animate__animated animate__fadeInUp
                            "
                        >
                            <img
                                class="img-fluid"
                                src="{{ content['c' + n + '_image'] }}"
                                alt=""
                            />
                            <div class="solution-box-text">
                                <h6>{{ content["g" + n + "_text"] }}</h6>
                                <p>{{ content["h" + n + "_text"] }}</p>
                            </div>
                            <a
                                class="bottom-icon"
                                style="cursor: pointer"
                                (click)="redirect(content['a' + n + '_link'])"
                            >
                                <img
                                    class="img-fluid"
                                    src="assets/images/orange-arrow.webp"
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="how-does-it-works how-does-it-works-2">
        <div class="container">
            <div class="how-does-it-works-content">
                <div class="top">
                    <h2 class="title wow animate__animated animate__fadeInUp">
                        <span>{{ content.i1_text }}</span>
                    </h2>
                    <img
                        class="
                            img-fluid
                            mt-5
                            wow
                            animate__animated
                            animate__fadeInUp
                            animate__delay0-5s
                        "
                        src="{{ content.d1_image }}"
                        alt=""
                        (click)="openModal(showImage)"
                        style="cursor: pointer"
                    />
                </div>
            </div>
        </div>
    </div>

    <section class="benefits">
        <div class="container-inner mt-5">
            <h2
                class="
                    title
                    orange
                    font-weight-bold
                    small
                    orange
                    bottom bottom-center
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <span>{{ content.j1_text }}</span>
            </h2>
        </div>
        <app-solution-footer></app-solution-footer>
    </section>

    <ng-template #showImage>
        <div class="modal-dialog modal-lg">
            <div class="modal-content border-0">
                <div class="modal-header">
                    <h4 class="modal-title pull-left"></h4>
                    <button
                        type="button"
                        class="close pull-right"
                        aria-label="Close"
                        (click)="closeModal()"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col">
                            <img
                                class="img-fluid"
                                src="{{ content.d1_image }}"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
