<ng-container *ngIf="contentLoaded">
    <!-- header ends her -->
    <section
        class="banner home-banner"
        [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
    >
        <div class="container">
            <div class="banner-item-list">
                <div class="banner-item">
                    <h1
                        class="banner-title wow animate__animated animate__fadeInUp"
                    >
                        {{ content.a1_text }}
                    </h1>
                    <app-book-ademo></app-book-ademo>
                    <img
                        class="img-fluid banner-img wow animate__animated animate__fadeInDown animate__delay-1s"
                        src="{{ content.b1_image }}"
                        alt="banner image"
                    />
                </div>
            </div>
        </div>
    </section>

    <!-- what we do -->
    <section class="what-we-do">
        <div class="container">
            <div class="what-we-do-content">
                <h2 class="title wow animate__animated animate__fadeInUp">
                    <span>{{ content.b1_text }}</span>
                </h2>
                <p class="wow animate__animated animate__fadeInUp">
                    {{ content.c1_text }}
                </p>
                <button (click)="link(content.a1_link)" class="btn-play">
                    <img src="assets/images/play-btn.webp" alt="play btn" />
                </button>
            </div>
        </div>
    </section>
    <!-- what we do -->

    <!-- how does work -->
    <section class="how-we-do">
        <img
            class="img-fluid w-100"
            src="assets/images/grey-bg-begining-Home-Desktop-Website-GC.webp"
            alt=""
        />
        <div class="how-we-do-inner">
            <div class="container">
                <h2 class="title wow animate__animated animate__fadeInUp">
                    <span>{{ content.d1_text }}</span>
                </h2>
                <p
                    class="text-center wow animate__animated animate__fadeInUp animate__delay-1s"
                >
                    {{ content.e1_text }}
                </p>
                <div class="home-products">
                    <div class="row">
                        <div
                            class="col-6 col-lg"
                            *ngFor="let num of [1, 2, 3, 4, 5]"
                        >
                            <!-- home product -->
                            <div
                                (click)="redPg(num)"
                                style="cursor: pointer"
                                class="home-product wow animate__animated animate__fadeInUp animate__delay-1s"
                                [ngClass]="{
                                    yellow: num === 1,
                                    green: num === 2,
                                    blue: num === 3,
                                    purple: num === 4,
                                    red: num === 5
                                }"
                            >
                                <img
                                    class="img-fluid"
                                    src="{{ content['c' + num + '_image'] }}"
                                    alt="seed audit"
                                />
                                <div class="hover"></div>
                                <div class="product-bottom">
                                    <img
                                        src="{{ content['d' + num + '_image'] }}"
                                        alt=""
                                        class="bottom-logo img-fluid"
                                    />
                                    <h4 class="text-white">
                                        {{ content["f" + num + "_text"] }}
                                    </h4>
                                </div>
                            </div>
                            <!-- home product -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- how does work -->
    <div class="how-does-it-works">
        <div class="container">
            <div class="how-does-it-works-content">
                <div class="top">
                    <h2 class="title wow animate__animated animate__fadeInUp">
                        <span>{{ content.g1_text }}</span>
                    </h2>
                    <img
                        class="img-fluid"
                        src="{{ content.e1_image }}"
                        alt=""
                        (click)="openModal(showImage)"
                        style="cursor: pointer;"
                    />
                </div>
                <div class="bottom">
                    <h2 class="title wow animate__animated animate__fadeInUp">
                        <span>{{ content.h1_text }}</span>
                    </h2>
                    <h3
                        class="wow animate__animated animate__fadeInUp animate__delay-1s"
                    >
                        {{ content.i1_text }}
                    </h3>
                    <p
                        class="wow animate__animated animate__fadeInUp animate__delay-2s"
                    >
                        {{ content.j1_text }}
                    </p>
                    <div
                        class="action wow animate__animated animate__fadeInUp animate__delay-2s"
                    >
                        <button class="btn btn-primary" (click)="readMore()">
                            {{ 'readMore' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- our partners -->
    <section class="our-partners">
        <div class="container" *ngIf="content">
            <div class="title-wrapper">
                <h2
                    class="title wow animate__animated animate__fadeInUp animate__delay-1s"
                >
                    <span>{{ content.k1_text }}</span>
                </h2>
                <p
                    class="wow animate__animated animate__fadeInUp animate__delay-2s"
                >
                    {{ content.l1_text }}
                </p>
            </div>
            <!-- partner's list -->
            <ul class="partners-list">
                <owl-carousel-o [options]="customOptions">
                    <ng-template
                        carouselSlide
                        [id]="i + 1"
                        *ngFor="let x of content.partners; let i = index"
                    >
                        <li class="partner-item">
                            <img src="{{ x.a1_image }}" alt="" />
                        </li>
                    </ng-template>
                </owl-carousel-o>
            </ul>
            <!-- partner's list -->
        </div>
    </section>
    <!-- our partners -->

    <!-- company stats -->
    <section class="company-stats">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-6">
                    <div class="stats-box wow animate__animated animate__fadeInUp">
                        <div class="img-wrapper">
                            <img src="{{ content.f1_image }}" alt="" />
                        </div>
                        <p>{{ content.m1_text }}</p>
                        <h3>{{ content.n1_text }}</h3>
                        <p>{{ content.o1_text }}</p>
                    </div>
                </div>
                <div class="col-md-3 col-6">
                    <div
                        class="stats-box wow animate__animated animate__fadeInUp animate__delay-1s"
                    >
                        <div class="img-wrapper">
                            <img
                                src="{{ content.f2_image }}"
                                alt=""
                            />
                        </div>
                        <p>{{ content.m2_text }}</p>
                        <h3>{{ content.n2_text }}</h3>
                        <p>{{ content.o2_text }}</p>
                    </div>
                </div>
                <div class="col-md-3 col-6">
                    <div
                        class="stats-box wow animate__animated animate__fadeInUp animate__delay-2s"
                    >
                        <div class="img-wrapper">
                            <img
                                src="{{ content.f3_image }}"
                                alt=""
                            />
                        </div>
                        <p>{{ content.m3_text }}</p>
                        <h3>{{ content.n3_text }}</h3>
                        <p>{{ content.o3_text }}</p>
                    </div>
                </div>
                <div class="col-md-3 col-6">
                    <div
                        class="stats-box wow animate__animated animate__fadeInUp animate__delay-3s"
                    >
                        <div class="img-wrapper">
                            <img
                                src="{{ content.f4_image }}"
                                alt=""
                            />
                        </div>
                        <p>{{ content.m4_text }}</p>
                        <h3>{{ content.n4_text }}</h3>
                        <p>{{ content.o4_text }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- company stats -->

    <!-- home contact -->
</ng-container>

<ng-template #showImage>
    <div class="modal-dialog modal-lg">
        <div class="modal-content border-0">
           <div class="modal-header">
              <h4 class="modal-title pull-left"></h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
              </button>
           </div>
           <div class="modal-body">
              <div class="row">
                 <div class="col">
                    <img class="img-fluid" src="{{ content.e1_image }}" alt="">
                 </div>
              </div>
           </div>
       </div>
   </div>
</ng-template>