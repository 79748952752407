<ng-container *ngIf="contentLoaded">
     <!-- banner starts  -->
     <section
        class="banner banner-seed-audit banner-2 bg-transparent"
        [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
    >
        <div class="container-inner">
            <div class="banner-content">
                <img class="title-image img-fluid  wow animate__animated animate__fadeInUp" src="{{ content.b1_image }}" alt="">
                <p class="text-white wow animate__animated animate__fadeInUp animate__delay0-5s">
                    {{ content.a1_text }}
                </p>
                <app-book-ademo></app-book-ademo>
            </div>
        </div>
           <!-- scroll addded -->
           <div class="btn-scroll-wrapper" (click) = "scroll()">
                <button class="btn-scroll wow animate__animated animate__fadeInUp animate__delay-1s">
                    <span class="fa fa-angle-down"></span>
                </button>
            </div>
         <!-- scroll addded -->
    </section>
    <!-- banner starts  -->

    <app-product-timeline [selected]="'seed-audit'"></app-product-timeline>

    <div #scrollHere class="what-is-seed-audit">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="seed-audit-content">
                        <img src="{{ content.c1_image }}" class="section-title img-fluid  wow animate__animated animate__fadeInUp" alt="">
                        <p class=" wow animate__animated animate__fadeInUp animate__delay0-5s">
                            {{ content.b1_text }}
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="{{ content.c2_image }}" alt="" class="img-fluid mt-3 mt-md-0 wow animate__animated animate__fadeInRight animate__delay0-5s display-img">
                </div>
            </div>
        </div>
    </div>


    <!-- seed audit features -->
      <div class="seed-audit-features">
            <div class="container">
                <h2 class="title text-left  bottom font-weight-bold   wow animate__animated animate__fadeInUp">
                    <span>{{ content.c1_text }}</span>
                </h2>
                <div class="seed-features-slider mobile-hide">
                    <ul class="seed-features">
                        <owl-carousel-o [options]="customOptions" (translated)="getPassedData($event)">
                            <ng-template carouselSlide [id]="1">
                                <li class="slide-1">
                                    <img src="{{ content.f1_image }}" class="img-fluid" alt="">
                                </li>
                            </ng-template>  
                            <ng-template carouselSlide [id]="2">
                                <li class="slide-2">
                                    <img src="{{ content.f2_image }}" class="img-fluid" alt="">
                                </li>
                            </ng-template>  
                            <ng-template carouselSlide [id]="3">
                                <li class="slide-3">
                                    <img src="{{ content.f3_image }}" class="img-fluid" alt="">
                                </li>
                            </ng-template>  
                            <ng-template carouselSlide [id]="4">
                                <li class="slide-4">
                                    <img src="{{ content.f4_image }}"  class="img-fluid" alt="">
                                </li>
                            </ng-template>  
                            <ng-template carouselSlide [id]="5">
                                <li class="slide-5">
                                    <img src="{{ content.f5_image }}"  class="img-fluid" alt="">
                                </li>
                            </ng-template>  
                           
                       
                    </owl-carousel-o>
                    </ul>
                    <img src="{{ content.e1_image }}" class="img-fluid slide-bg" [ngClass]="{ 'active': activeSlide === 1 }" id="slide-1" alt="">
                    <img src="{{ content.e2_image }}" class="img-fluid slide-bg" [ngClass]="{ 'active': activeSlide === 2 }" id="slide-2" alt="">
                    <img src="{{ content.e3_image }}" class="img-fluid slide-bg" [ngClass]="{ 'active': activeSlide === 3 }" id="slide-3" alt="">
                    <img src="{{ content.e4_image }}" class="img-fluid slide-bg" [ngClass]="{ 'active': activeSlide === 4 }" id="slide-4" alt="">
                    <img src="{{ content.e5_image }}" class="img-fluid slide-bg" [ngClass]="{ 'active': activeSlide === 5 }" id="slide-5" alt="">
                </div>

                <div class="seed-features-slider desktop-hide">
                    <ul class="seed-features test">
                        <owl-carousel-o [options]="customOptions1"  (translated)="getPassedData($event, 5)">
                            <ng-template carouselSlide [id]="1">
                                <li class="slide-6">
                                    <img src="{{ content.d1_image }}" class="img-fluid" alt="">
                                </li>
                            </ng-template>  
                            <ng-template carouselSlide [id]="2">
                                <li class="slide-7">
                                    <img src="{{ content.d2_image }}" class="img-fluid" alt="">
                                </li>
                            </ng-template>  
                            <ng-template carouselSlide [id]="3">
                                <li class="slide-8">
                                    <img src="{{ content.d3_image }}" class="img-fluid" alt="">
                                </li>
                            </ng-template>  
                            <ng-template carouselSlide [id]="4">
                                <li class="slide-9">
                                    <img src="{{ content.d4_image }}" class="img-fluid" alt="">
                                </li>
                            </ng-template>  
                            <ng-template carouselSlide [id]="5">
                                <li class="slide-10">
                                    <img src="{{ content.d5_image }}" class="img-fluid" alt="">
                                </li> 
                            </ng-template>  
                           
                       
                    </owl-carousel-o>
                       
                    </ul>
                    <img src="{{ content.g1_image }}" class="img-fluid slide-bg" [ngClass]="{ 'active': activeSlide === 6 }" id="slide-6" alt="">
                    <img src="{{ content.g2_image }}" class="img-fluid slide-bg" [ngClass]="{ 'active': activeSlide === 7 }" id="slide-7" alt="">
                    <img src="{{ content.g3_image }}" class="img-fluid slide-bg" [ngClass]="{ 'active': activeSlide === 8 }" id="slide-8" alt="">
                    <img src="{{ content.g4_image }}" class="img-fluid slide-bg" [ngClass]="{ 'active': activeSlide === 9 }" id="slide-9" alt="">
                    <img src="{{ content.g5_image }}" class="img-fluid slide-bg" [ngClass]="{ 'active': activeSlide === 10 }" id="slide-10" alt="">
                </div>
            </div>
        </div>
    <!-- seed audit features -->

    <img src="assets/images/seed-audit/bg-grey-2.webp" class="img-fluid desktop-hide" alt="">

    <!-- product-details -->
    <div class="product-details product-details-2 seed-product-details mt-5 mt-lg-0">
        <div class="title-image-wrapper  wow animate__animated animate__fadeInUp">
            <img src="{{ content.h1_image }}" alt="">
        </div>
        <h2 class="title orange small bottom bottom-center font-weight-bold wow animate__animated animate__fadeInUp">
            <span>{{ content.e1_text }}</span>
        </h2>
        <app-product-footer [hideProduct]="'seed-audit'"></app-product-footer>
    </div>

    <!-- shape -->
    <div class="bottom-img-shape">
        <img src="assets/images/buyers/buyers-shape-bg-2.webp" class="img-fluid desktop-hide w-100" alt="">
    </div>
    <!-- shape -->

</ng-container>