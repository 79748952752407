<div id="bottom-scroll">&nbsp;</div>
<section
    class="home-contact d-none d-md-block"
    id="bottom-contact"
    [ngClass]="{ footer_custom: url === '/products' }"
    *ngIf="url !== '/classic22'"
>
    <div class="container">
        <app-contact-form></app-contact-form>
    </div>
</section>
<section
    class="home-contact d-md-none"
    id="bottom-contact"
    [ngClass]="{ footer_custom: url === '/products' }"
    *ngIf="url !== '/classic22'"
>
    <div class="container">
        <app-contact-form-responsive></app-contact-form-responsive>
    </div>
</section>
<footer>
    <div class="container" *ngIf="contentLoaded">
        <!-- footer top -->
        <div class="footer-top">
            <div class="row">
                <div class="col-md-5">
                    <div
                        class="footer-top-left"
                        (click)="redirect('')"
                        style="cursor: pointer"
                    >
                        <div class="footer-logo">
                            <img
                                class="img-fluid"
                                src="assets/images/footer-logo.webp"
                                alt=""
                            />
                        </div>
                        <div class="contact">
                            <h4>{{ "contact" | translate }}</h4>
                            <p class="tel">
                                <img
                                    src="assets/images/tel-icon.webp"
                                    alt=""
                                />{{ content.a1_text }}
                            </p>
                            <p style="line-height: 15px;"><small [innerHtml]="content.a2_text"></small></p>
                            <p class="tel">
                                <img
                                    src="assets/images/tel-icon.webp"
                                    alt=""
                                />{{ content.a3_text }}
                            </p>
                            <p style="line-height: 15px;"><small [innerHtml]="content.a4_text"></small></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="footer-top-right">
                        <div class="footer-menu-box">
                            <h5
                                (click)="redirect('products')"
                                style="cursor: pointer"
                            >
                                {{ "header.products" | translate }}
                            </h5>
                            <ul>
                                <li>
                                    <a routerLink="/trumodity">{{
                                        "header.trumodity" | translate
                                    }}</a>
                                </li>
                                <li>
                                    <a routerLink="/seed-audit">{{
                                        "header.seed" | translate
                                    }}</a>
                                </li>
                                <li>
                                    <a routerLink="/silosys">{{
                                        "header.siloSys" | translate
                                    }}</a>
                                </li>
                                <li>
                                    <a routerLink="/silosys-transformation">{{
                                        "header.siloSysTransformation"
                                            | translate
                                    }}</a>
                                </li>
                                <li>
                                    <a routerLink="/harvx">{{
                                        "header.harvX" | translate
                                    }}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="footer-menu-box">
                            <h5
                                (click)="redirect('solutions')"
                                style="cursor: pointer"
                            >
                                {{ "header.solutions" | translate }}
                            </h5>
                            <ul>
                                <li>
                                    <a routerLink="/producers">{{
                                        "header.for_prod" | translate
                                    }}</a>
                                </li>
                                <li>
                                    <a routerLink="/storage-operators">{{
                                        "header.for_storage" | translate
                                    }}</a>
                                </li>
                                <li>
                                    <a routerLink="/buyers">{{
                                        "header.for_buyers" | translate
                                    }}</a>
                                </li>
                                <li>
                                    <a routerLink="/blockchain">{{
                                        "header.blockchain" | translate
                                    }}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="footer-menu-box">
                            <h5>{{ "header.about" | translate }}</h5>
                            <ul>
                                <li>
                                    <a routerLink="/team">{{
                                        "header.team" | translate
                                    }}</a>
                                </li>
                                <li>
                                    <a routerLink="/media">{{
                                        "header.media" | translate
                                    }}</a>
                                </li>
                                <li>
                                    <a routerLink="/careers">{{
                                        "header.careers" | translate
                                    }}</a>
                                </li>
                                <li>
                                    <a href="https://grainchain.medium.com/" target="_blank">Blog</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- footer top -->

        <!-- social media links` -->
        <div class="social-media" *ngIf="contentLoaded">
            <ul>
                <li>
                    <a href="{{ content.a5_text }}" target="_blank"
                        ><img
                            src="assets/images/sm-icon-facebook-Home-Desktop-Website-GC.webp"
                            alt="facebook"
                    /></a>
                </li>
                <li>
                    <a href="{{ content.a6_text }}" target="_blank"
                        ><img src="assets/images/intagram.webp" alt=""
                    /></a>
                </li>
                <li>
                    <a href="{{ content.a7_text }}" target="_blank"
                        ><img
                            src="assets/images/sm-icon-telegram-Home-Desktop-Website-GC.webp"
                            alt=""
                    /></a>
                </li>
                <li>
                    <a href="{{ content.a8_text }}" target="_blank"
                        ><img
                            src="assets/images/sm-icon-in-Home-Desktop-Website-GC.webp"
                            alt=""
                    /></a>
                </li>
                <li>
                    <a href="{{ content.a9_text }}" target="_blank"
                        ><img
                            src="assets/images/sm-icon-twit-Home-Desktop-Website-GC.webp"
                            alt=""
                    /></a>
                </li>
            </ul>
        </div>
        <!-- social media links` -->

        <!-- copyright -->
        <div class="copyright">
            <p>
                ©{{ currentYear }} -
                <a routerLink="/privacy-policy">{{ "privacy" | translate }}</a>
                -
                <a routerLink="/terms-of-services">{{ "terms" | translate }}</a>
            </p>
        </div>
        <!-- copyright -->
    </div>
    <button id="back-to-top" class="back-to-top">
        <img src="assets/images/up-arrow.webp" class="img-fluid" alt="" />
    </button>
</footer>
