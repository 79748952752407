import { Component, OnInit } from '@angular/core';
import { NavigationEnd,Router } from "@angular/router";
@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.css']
})
export class AdminSidebarComponent implements OnInit {
  selectedPage = "dashboard"
  constructor(private router : Router) { }

  ngOnInit() {
  }
  redirectUrl(type){
    if(type == 'dashboard'){
      this.selectedPage = 'dashboard'
      this.router.navigateByUrl('admin/dashboard');
    }
    if(type == 'users'){
      this.selectedPage = 'users'
      this.router.navigateByUrl('admin/users');
    } if(type == 'admin-home'){
      this.selectedPage = 'admin-home'
      this.router.navigateByUrl('admin/homepage');
    } if(type == 'social-links'){
      this.selectedPage = 'social-links'
      this.router.navigateByUrl('admin/social-links');
    } if(type == 'admin-solutions'){
      this.selectedPage = 'admin-solutions'
      this.router.navigateByUrl('admin/solutions');
    } 
    if(type == 'admin-solutions-footer'){
      this.selectedPage = 'admin-solutions-footer'
      this.router.navigateByUrl('admin/solutions-footer');
    }
    if(type == 'admin-products'){
      this.selectedPage = 'admin-products'
      this.router.navigateByUrl('admin/products');
    }
    if(type == 'admin-products-footer'){
      this.selectedPage = 'admin-products-footer'
      this.router.navigateByUrl('admin/products-footer');
    } 
    if(type == 'admin-products-timeline'){
      this.selectedPage = 'admin-products-timeline'
      this.router.navigateByUrl('admin/products-timeline');
    }
    if(type == 'admin-users'){
      this.selectedPage = 'admin-users'
      this.router.navigateByUrl('admin/admin-users');
    } 
    if(type == 'admin-producers'){
      this.selectedPage = 'admin-producers'
      this.router.navigateByUrl('admin/producers');
    } 
    if(type == 'admin-storage'){
      this.selectedPage = 'admin-storage'
      this.router.navigateByUrl('admin/storage');
    } 
    if(type == 'admin-blockchain'){
      this.selectedPage = 'admin-blockchain'
      this.router.navigateByUrl('admin/blockchain');
    } 
    if(type == 'admin-trumodity'){
      this.selectedPage = 'admin-trumodity'
      this.router.navigateByUrl('admin/trumodity');
    } 
    if(type == 'admin-seedaudit'){
      this.selectedPage = 'admin-seedaudit'
      this.router.navigateByUrl('admin/seedaudit');
    }
    if(type == 'admin-silosys'){
      this.selectedPage = 'admin-silosys'
      this.router.navigateByUrl('admin/silosys');
    } 
    if(type == 'admin-silosys-transformation'){
      this.selectedPage = 'admin-silosys-transformation'
      this.router.navigateByUrl('admin/silosys-transformation');
    } 
    if(type == 'admin-harvx'){
      this.selectedPage = 'admin-harvx'
      this.router.navigateByUrl('admin/harvx');
    } 
    if(type == 'admin-buyers'){
      this.selectedPage = 'admin-buyers'
      this.router.navigateByUrl('admin/buyers');
    } 
    if(type == 'admin-news'){
      this.selectedPage = 'admin-news'
      this.router.navigateByUrl('admin/news');
    } 
    if(type == 'admin-carrers'){
      this.selectedPage = 'admin-carrers'
      this.router.navigateByUrl('admin/carrers');
    } 
  
    if(type == 'about-grain'){
      this.selectedPage = 'about-grain'
      this.router.navigateByUrl('admin/about-grain');
    } 
    if(type == 'faqs'){
      this.selectedPage = 'faqs'
      this.router.navigateByUrl('admin/faq');
    } 
    if(type == 'terms'){
      this.selectedPage = 'terms'
      this.router.navigateByUrl('admin/terms');
    } 
    if(type == 'privacy'){
      this.selectedPage = 'privacy'
      this.router.navigateByUrl('admin/privacy');
    } 
    if(type == 'about'){
      this.selectedPage = 'about'
      this.router.navigateByUrl('admin/about-us');
    } 
    if(type == 'support-fee'){
      this.selectedPage = 'support-fee'
      this.router.navigateByUrl('admin/support-fee');
    } 
    if(type == 'postal-codes'){
      this.selectedPage = 'postal-codes'
      this.router.navigateByUrl('admin/postal-codes');
    } 
    if(type == 'languages'){
      this.selectedPage = 'languages'
      this.router.navigateByUrl('admin/languages');
    } 

    
  }
}
