import { Component, OnInit, ViewChild, ViewChildren, HostListener, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgwWowService } from 'ngx-wow';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core'; 
import { LoginService } from "./../services/login.service";
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild('menuHeader') menuHeader;
  @ViewChildren('openDrops') openDrops;

  constructor(private loginService: LoginService,public translate: TranslateService,private router:Router,private wowService: NgwWowService,private ngxService: NgxUiLoaderService, private eRef: ElementRef, @Inject(DOCUMENT) private _doc: Document) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.wowService.init(); 
    this.loginService.getListLangs().subscribe(
      res => {
        let data = res['success']
        let arr = []
        for(var i=0; i<data.length; i++){
          arr.push(data[i].name)
        }
       
       this.translate.addLangs(arr);  
        this.ngxService.stop();
      },
      err => { 
        this.ngxService.stop();
        
      }
    )
   
    if (localStorage.getItem('locale')) {  
      const browserLang = localStorage.getItem('locale');  
      this.translate.use(browserLang.match(/English|Spanish/) ? browserLang : 'English');  
    } else {  
      localStorage.setItem('locale', 'English');  
      this.translate.setDefaultLang('English');  
    }

}

@HostListener('window:scroll', ['$event']) onScrollEvent($event) {
  this.resetDrops();
}

@HostListener('document:click', ['$event']) clickout($event) {
  if (this.eRef.nativeElement.contains($event.target)) {
    // clicked inside
  } else {
    this.resetDrops();
  }
}

slideComplete: boolean = true;

opendrop($event) {
  const isSmallDevice = this._doc.defaultView.innerWidth <= 767;

  if (isSmallDevice) {
    if ($event.type === 'click') {
      if ($event.currentTarget.getElementsByTagName('ul')[0].classList.contains('open-drop')) {
        $event.currentTarget.getElementsByTagName('ul')[0].classList.remove('open-drop');
        $event.currentTarget.getElementsByTagName('ul')[0].classList.remove('d-block');
        $event.currentTarget.getElementsByTagName('ul')[0].classList.add('d-none');
      } else {
        this.resetDrops();
        $event.currentTarget.getElementsByTagName('ul')[0].classList.add('open-drop');
        if (this.slideComplete) {
          this.slideComplete = false;
          $event.currentTarget.getElementsByTagName('ul')[0].classList.remove('d-none');
          $event.currentTarget.getElementsByTagName('ul')[0].classList.add('d-block');
          this.slideComplete = true;
        }
      }
    }
  } else {
    if ($event.type === 'mouseenter' || $event.type === 'click') {
      $event.currentTarget.getElementsByTagName('ul')[0].classList.add('open-drop');
      if (this.slideComplete) {
        this.slideComplete = false;
        $event.currentTarget.getElementsByTagName('ul')[0].classList.remove('d-none');
        this.slideComplete = true;
      }
    }

    if ($event.type === 'mouseleave') {
      $event.currentTarget.getElementsByTagName('ul')[0].classList.remove('open-drop');
      $event.currentTarget.getElementsByTagName('ul')[0].classList.add('d-none');
    }
  }
}

  open(){
    this.menuHeader.nativeElement.classList.add('active');
  }

  close(){
    this.resetDrops();
    this.menuHeader.nativeElement.classList.remove('active');
  }

resetDrops() {
  this.openDrops.forEach((openDrop: any) => {
    openDrop.nativeElement.getElementsByTagName('ul')[0].classList.remove('open-drop');
    openDrop.nativeElement.getElementsByTagName('ul')[0].classList.remove('d-block');
    openDrop.nativeElement.getElementsByTagName('ul')[0].classList.add('d-none');
  });
}

routerNavigate(page){
  this.menuHeader.nativeElement.classList.remove('active');
  this.router.navigateByUrl('/'+page)
}
changeLang(language: string) {  
  this.loginService.setLang(language)
  localStorage.setItem('locale', language);  
  this.translate.use(language);  
  this._doc.defaultView.location.reload()
}  
}
