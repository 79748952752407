import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Media } from "../types/media.type";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class MediaService {
  constructor(private httpClient: HttpClient) {}

  getLang() {
    const lang = localStorage.getItem("lang");

    return lang ? JSON.parse(lang) : "English";
  }

  get(media: Media) {
    const lang = this.getLang();

    return this.httpClient.get(
      environment.apiEndpoint + "media/" + media.route + "/" + lang
    );
  }

  updateContent(media: Media) {
    return this.httpClient.post(
      environment.apiEndpoint +
        "media/" +
        media.route +
        "/" +
        media.id +
        "/content",
      media.data
    );
  }

  updateImage(media: Media) {
    return this.httpClient.post(
      environment.apiEndpoint +
        "media/" +
        media.route +
        "/" +
        media.id +
        "/image",
      media.data
    );
  }

  getAll(media: Media) {
    return this.httpClient.get(
      environment.apiEndpoint + "media/" + media.route
    );
  }

  save(media: Media) {
    return this.httpClient.post(
      environment.apiEndpoint + "media/" + media.route,
      media.data
    );
  }

  remove(media: Media) {
    return this.httpClient.delete(
      environment.apiEndpoint + "media/" + media.route + "/" + media.id
    );
  }
}
