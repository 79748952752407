import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { OwlOptions } from "ngx-owl-carousel-o";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgwWowService } from "ngx-wow";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

import { Solution } from "../types/solution.type";
import { SolutionService } from "../services/solution.service";

@Component({
  selector: "app-solutions",
  templateUrl: "./solutions.component.html",
  styleUrls: ["./solutions.component.css"],
})
export class SolutionsComponent implements OnInit {
  content: any = {};
  contentLoaded: boolean = false;
  @ViewChild("scrollHere") scrollHere: any;
  modalRef: BsModalRef | null;

  customOptions1: OwlOptions = {
    //margin: 10,
    // responsiveClass: true,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    loop: true,
    center: true,
    dots: false,
    nav: true,

    responsive: {
      0: {
        items: 1,
        center: true,
      },
      600: {
        items: 2,
      },
    },
  };

  constructor(
    private ngxService: NgxUiLoaderService,
    private wowService: NgwWowService,
    private modalService: BsModalService,
    private router: Router,
    private solutionService: SolutionService,
    @Inject(DOCUMENT) private _doc: Document
  ) {}

  ngOnInit(): void {
    this.wowService.init();
    this.ngxService.start("loading-solutions");

    const solution: Solution = {
      route: "overview",
      id: 0,
      data: null,
    };

    this.solutionService
      .get(solution)
      .toPromise()
      .then(
        (data: any) => {
          this.content = data;
          this.contentLoaded = true;
          this.ngxService.stop("loading-solutions");
        },
        () => {
          this.ngxService.stop("loading-solutions");
        }
      );
  }

  scroll() {
    this.scrollHere.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  redirect(type) {
    this.router.navigateByUrl("/" + type);
  }

  bottomRedirect(type) {
    this.router.navigateByUrl("/" + type);
  }

  openModal(template: any) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "gray modal-lg modal-dialog-centered" })
    );
  }

  closeModal() {
    this.modalRef.hide();
  }
}
