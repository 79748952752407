import { Component} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Contactform } from '../../models/contactform';
import { NgForm } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { RecaptchaModule, RecaptchaFormsModule} from 'ng-recaptcha';


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styles: [
  ]
})
export class ContactFormComponent {

  model = new Contactform('', '', '', '');
  submitted = false;

  constructor( private toastr: ToastrService, private ngxService: NgxUiLoaderService, private loginService: LoginService) { }

  onSubmit(f) {
    this.submitted = true;
    console.log(f);
    if (f.invalid ) {
         this.toastr.error('Please provide the required information.');
         return;
    }
    this.ngxService.start();
    this.loginService.contact(this.model).subscribe((result) => {
        this.toastr.success('Your message sent successfully.');
        f.reset();
        this.ngxService.stop();
    }, (err) => {
        this.ngxService.stop();
    });
  }


  get diagnostic() { return JSON.stringify(this.model); }
}
