import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AdminService } from "./../../admin/services/admin.service";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})

export class AdminLoginComponent implements OnInit {
  signinForm: FormGroup;
  submitted = false
  modalRefStatus:BsModalRef | null;
  modalRefOtp:BsModalRef | null;
  qrData : any
  submittedForget = false
  key1:any
  key2:any
  key3:any
  key4:any
  key5:any
  key6:any
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  userInfo : any
  secretKey:any
  public loginData = { email:'',  password: '',rememberme:'' };
  public forgotPass = { email:'' };
  constructor(private toastr: ToastrService,private modalService: BsModalService,private router : Router, private ngxService: NgxUiLoaderService, private formBuilder: FormBuilder,  private adminService: AdminService, @Inject(DOCUMENT) private _doc: Document) {} 

  ngOnInit() {

    this.signinForm = this.formBuilder.group({    
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      remember:['']
  });
  }
 
  login(template,template1){
    console.log(this.loginData.rememberme)
   this.submitted = true;
   if (this.signinForm.invalid) {
         this.toastr.error('Please provide the required information.');
            return;
    }


    this.ngxService.start()

        this.adminService.login(this.loginData).subscribe(
     res => {
       this.userInfo = res
      if(res.userDetails.secret){
        this.secretKey = res.userDetails.secret
        this.modalRefOtp = this.modalService.show(template1,this.config)
        this.key1 = "";
        this.key2 = "";
        this.key3 = "";
        this.key4 = "";
        this.key5 = "";
        this.key6 = "";
      }else{
      this.ngxService.start()

            this.adminService.qrCode(res.userDetails).subscribe(
        res => {
          this.qrData = res['QR_Image']
          this.secretKey = res.secret
          this.modalRefStatus = this.modalService.show(template,this.config)
        // this.adminService.setAdminDetails(res['userDetails'])
        // this.adminService.setToken(res['success']['token']);
        this.ngxService.stop();
         // this.router.navigate(["admin/dashboard"]); 
        },
        err => {
          this.toastr.error("Internal Server Error.");
        this.ngxService.stop();
        }
      );
      }
     


     
    
     this.ngxService.stop();
       //this.router.navigate(["admin/users"]); 
     },
     err => {
      this.toastr.error("Invalid Username/Password....");
     this.ngxService.stop();
     }
   );

    }

    next(template){
      this.modalRefStatus.hide();
      this.modalRefOtp = this.modalService.show(template,this.config)
      this.key1 = "";
      this.key2 = "";
      this.key3 = "";
      this.key4 = "";
      this.key5 = "";
      this.key6 = "";
      
    }
    onInputEntry(event, id, nextInputIndex) {
      let input = event.target;
      let nexInput = +nextInputIndex + 1;
      let newID = id + nexInput;
      this._doc.getElementById(newID).focus();
    }
    submitOtp(){
      if(!this.key1 || !this.key2 || !this.key3 || !this.key4 || !this.key5 || !this.key6){
        this.toastr.error("Please enter otp.")
        return
      }
      this.ngxService.start()
    let otp = {
      otp:this.key1 + this.key2 + this.key3 + this.key4 + this.key5 + this.key6,
      secret : this.secretKey,
      id : this.userInfo.userDetails.id
    }
      this.adminService.submitOTPData(otp).subscribe(
   res => {
    
   this.ngxService.stop();
     this.adminService.setAdminDetails(this.userInfo['userDetails'])
     this.adminService.setToken(this.userInfo['success']['token']);
     this.modalRefOtp.hide()
     this.router.navigate(["admin/users"]); 
   },
   err => {
    this.toastr.error("Invalid OTP");
   this.ngxService.stop();
   }
 );
    }




  get f() { return this.signinForm.controls; }

}
