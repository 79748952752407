<ng-container *ngIf="contentLoaded">
    <!-- banner -->
    <section
        class="banner banner-media bg-white banner-2"
        [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
    >
        <div class="container-inner">
            <div class="banner-content">
                <h1
                    class="
                        title
                        text-white
                        wow
                        animate__animated animate__fadeInUp
                    "
                >
                    <span
                        >{{ content.a1_text }}:
                        {{
                            selectedTab === "recent-news"
                                ? content.c1_text
                                : content.c2_text
                        }}</span
                    >
                </h1>
                <p
                    class="
                        text-white
                        wow
                        animate__animated animate__fadeInUp animate__delay0-5s
                    "
                >
                    {{ content.b1_text }}
                </p>
            </div>
        </div>
        <!-- scroll addded -->
        <div class="btn-scroll-wrapper" (click)="scroll()">
            <button
                class="
                    btn-scroll
                    wow
                    animate__animated animate__fadeInUp animate__delay-1s
                "
            >
                <span class="fa fa-angle-down"></span>
            </button>
        </div>
        <!-- scroll addded -->
    </section>
    <!-- banner ends -->

    <!--media releases -->
    <section #scrollHere class="media-releases">
        <div class="container-inner">
            <!-- media releases tabs -->
            <ul
                class="
                    media-releases-tabs
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <li
                    class=""
                    [ngClass]="{ active: selectedTab === 'recent-news' }"
                    data-tab="#recent-news-mobile"
                    style="text-transform: capitalize"
                    (click)="selectTab('recent-news')"
                >
                    {{ content.c1_text }}
                </li>
                <li
                    class=""
                    [ngClass]="{ active: selectedTab === 'press' }"
                    (click)="selectTab('press')"
                    data-tab="#press-mobile"
                    style="text-transform: capitalize"
                >
                    {{ content.c2_text }}
                </li>
            </ul>
            <ul class="archive-pg">
                <ng-container
                    *ngFor="
                        let item of items
                            | paginate
                                : {
                                      itemsPerPage: 10,
                                      currentPage: page,
                                      id: 'itemPagination'
                                  };
                        index as i
                    "
                >
                    <li>
                        <p
                            style="cursor: pointer"
                            (click)="linkTo(item.a1_link)"
                        >
                            {{ item.a1_text }}
                        </p>
                        <span> {{ item.b1_text }}, {{ item.c1_text }}</span>
                    </li>
                </ng-container>
            </ul>

            <div class="mb-5">
                <pagination-template
                    #p="paginationApi"
                    [id]="'itemPagination'"
                    (pageChange)="pageChanged($event)"
                    style="cursor: pointer"
                >
                    <ul class="pagination">
                        <li
                            class="pagination-previous"
                            [class.disabled]="p.isFirstPage()"
                        >
                            <a *ngIf="!p.isFirstPage()" (click)="p.previous()">
                                <span aria-hidden="true"
                                    ><i
                                        class="fa fa-chevron-left"
                                        aria-hidden="true"
                                    ></i
                                ></span>
                            </a>
                        </li>

                        <li
                            *ngFor="let page of p.pages"
                            [class.active]="p.getCurrent() === page.value"
                        >
                            <a
                                (click)="p.setCurrent(page.value)"
                                *ngIf="p.getCurrent() !== page.value"
                            >
                                <span>{{ page.label }}</span>
                            </a>
                            <a *ngIf="p.getCurrent() === page.value">
                                <span>{{ page.label }}</span>
                            </a>
                        </li>

                        <li
                            class="pagination-next"
                            [class.disabled]="p.isLastPage()"
                        >
                            <a *ngIf="!p.isLastPage()" (click)="p.next()">
                                <span aria-hidden="true"
                                    ><i
                                        class="fa fa-chevron-right"
                                        aria-hidden="true"
                                    ></i
                                ></span>
                            </a>
                        </li>
                    </ul>
                </pagination-template>
            </div>
        </div>
    </section>
    <!-- media releaes -->
</ng-container>
