<form (ngSubmit)="onSubmit(fr)" #fr="ngForm">
    <!-- form -->
    <div class="form-wrapper">

        <div class="form-left">
            <h2 class="title wow animate__animated animate__fadeInUp"><span>{{'contact' | translate}}</span></h2>

            <div class="form-group   wow animate__animated animate__fadeInUp  animate__delay-1s">
                <label for="">{{'name' | translate}}</label>
                <input class="form-control" [(ngModel)]="model.name" name="name" type="text" placeholder="{{'namePlace' | translate}}" required #name="ngModel">
            </div>
            <div class="form-group   wow animate__animated animate__fadeInUp  animate__delay-1s">
                <label for="">{{'email' | translate}}</label>
                <input [(ngModel)]="model.email" class="form-control" name="email" type="email" placeholder="{{'emailPlace' | translate}}" required #email="ngModel" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
            </div>

        </div>
        <div class="form-right">
            <div class="form-group wow animate__animated animate__fadeInUp  animate__delay-2s">
                <label for="">{{'message' | translate}}</label>
                <div>
                    <textarea [(ngModel)]="model.message" name="message" class="form-control message" id="" cols="30" rows="10" placeholder="{{'messagePlace' | translate}}" required></textarea>
                </div>
            </div>
            <div class="form-group captcha-mobile wow animate__animated animate__fadeInUp  animate__delay-2s">
                <re-captcha [(ngModel)]="model.captcha" name="captcha" siteKey="6LfcItQZAAAAANjX7p_gXjYTZrA_6IZ7vzBBWv-9" required #captchaControlResponsive="ngModel"></re-captcha>
            </div>
            <div class="action">

                <button class="btn rounded-0 btn-primary w-100" type="submit" [disabled]="!fr.form.valid">{{'send' | translate}}</button>
            </div>
        </div>
    </div>
    <!-- form -->
</form>