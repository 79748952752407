<ng-container *ngIf="contentLoaded">
  <!-- benefits  details -->
  <section class="benefits buyers-benefits" style="padding-top: 0; padding-bottom: 0;">
    <div class="container-inner mt-5">
      <div
        class="benefits-box wow animate__animated animate__fadeInUp"
        (click)="accord(1)"
        [ngClass]="{ active: activeBox === 1 }"
      >
        <div class="logo-holder">
          <img src="{{ content.a1_image }}" alt="" />
        </div>
        <div class="content-holder">
          <p>{{ content.a1_text }}</p>
        </div>
        <div class="icon-holder">
          <img src="assets/images/orange-arrow.webp" alt="" />
        </div>
      </div>
      <div
        class="benefits-content wow animate__animated animate__fadeInDown"
        [ngClass]="{ 'd-none': activeBox !== 1 }"
      >
        <h2 class="title text-left bottom orange">
          <span>{{ content.a2_text }}</span>
        </h2>
        <div class="row">
          <div class="col-md-6">
            <ul>
              <li *ngFor="let n of [3, 5, 7, 9, 11]">
                <span
                  class="fa fa-plus text-yellow"
                  *ngIf="content['a' + n + '_text']"
                ></span>
                {{ content["a" + n + "_text"] }}
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <ul>
              <li *ngFor="let n of [4, 6, 8, 10, 12]">
                <span
                  class="fa fa-plus text-yellow"
                  *ngIf="content['a' + n + '_text']"
                ></span>
                {{ content["a" + n + "_text"] }}
              </li>
            </ul>
            <a
              class="btn bg-yellow text-white mt-3"
              [routerLink]="content.a1_link"
              >{{ content.a13_text }}</a
            >
          </div>
        </div>
      </div>

      <div
        class="
          benefits-box
          wow
          animate__animated animate__fadeInUp animate__delay0-5s
        "
        (click)="accord(2)"
        [ngClass]="{ active: activeBox === 2 }"
      >
        <div class="logo-holder">
          <img src="{{ content.b1_image }}" alt="" />
        </div>
        <div class="content-holder">
          <p>{{ content.b1_text }}</p>
        </div>
        <div class="icon-holder">
          <img src="assets/images/light-green-arrow.webp" alt="" />
        </div>
      </div>
      <div
        class="benefits-content wow animate__animated animate__fadeInDown"
        [ngClass]="{ 'd-none': activeBox !== 2 }"
      >
        <h2 class="title text-left bottom light-green">
          <span>{{ content.b2_text }} </span>
        </h2>
        <div class="row">
          <div class="col-md-6">
            <ul>
              <li *ngFor="let n of [3, 5, 7, 9, 11]">
                <span
                  class="fa fa-plus text-light-green"
                  *ngIf="content['b' + n + '_text']"
                ></span
                >{{ content["b" + n + "_text"] }}
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <ul>
              <li *ngFor="let n of [4, 6, 8, 10, 12]">
                <span
                  class="fa fa-plus text-light-green"
                  *ngIf="content['b' + n + '_text']"
                ></span
                >{{ content["b" + n + "_text"] }}
              </li>
            </ul>
            <a
              class="btn bg-light-green text-white mt-3"
              [routerLink]="content.b1_link"
              >{{ content.b13_text }}</a
            >
          </div>
        </div>
      </div>

      <!-- Silosys -->
      <div
        class="
          benefits-box
          wow
          animate__animated animate__fadeInUp animate__delay0-5s
        "
        (click)="accord(3)"
        [ngClass]="{ active: activeBox === 3 }"
      >
        <div class="logo-holder">
          <img src="{{ content.c1_image }}" alt="" />
        </div>
        <div class="content-holder">
          <p>{{ content.c1_text }}</p>
        </div>
        <div class="icon-holder">
          <img src="assets/images/blue-arrow.webp" alt="" />
        </div>
      </div>

      <div
        class="benefits-content wow animate__animated animate__fadeInDown"
        [ngClass]="{ 'd-none': activeBox !== 3 }"
      >
        <h2 class="title text-left bottom blue">
          <span>{{ content.c2_text }}</span>
        </h2>
        <div class="row">
          <div class="col-md-6">
            <ul>
              <li *ngFor="let n of [3, 5, 7, 9, 11]">
                <span
                  class="fa fa-plus text-blue"
                  *ngIf="content['c' + n + '_text']"
                ></span
                >{{ content["c" + n + "_text"] }}
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <ul>
              <li *ngFor="let n of [4, 6, 8, 10, 12]">
                <span
                  class="fa fa-plus text-blue"
                  *ngIf="content['c' + n + '_text']"
                ></span
                >{{ content["c" + n + "_text"] }}
              </li>
            </ul>
            <a class="btn bg-blue text-white mt-3" [routerLink]="c1_link">{{
              content.c13_text
            }}</a>
          </div>
        </div>
      </div>

      <!-- Silosys -->

      <!-- HarvX -->
      <div
        class="
          benefits-box
          wow
          animate__animated animate__fadeInUp animate__delay0-8s
        "
        (click)="accord(4)"
        [ngClass]="{ active: activeBox === 4 }"
      >
        <div class="logo-holder">
          <img src="{{ content.d1_image }}" alt="" />
        </div>
        <div class="content-holder">
          <p>{{ content.d1_text }}</p>
        </div>
        <div class="icon-holder">
          <img src="assets/images/red-arrow.webp" alt="" />
        </div>
      </div>
      <div
        class="benefits-content wow animate__animated animate__fadeInDown"
        [ngClass]="{ 'd-none': activeBox !== 4 }"
      >
        <h2 class="title text-left bottom red">
          <span>{{ content.d2_text }}</span>
        </h2>
        <div class="row">
          <div class="col-md-6">
            <ul>
              <li *ngFor="let n of [3, 5, 7, 9, 11]">
                <span
                  class="fa fa-plus text-red"
                  *ngIf="content['d' + n + '_text']"
                ></span
                >{{ content["d" + n + "_text"] }}
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <ul>
              <li *ngFor="let n of [4, 6, 8, 10, 12]">
                <span
                  class="fa fa-plus text-red"
                  *ngIf="content['d' + n + '_text']"
                ></span
                >{{ content["d" + n + "_text"] }}
              </li>
            </ul>
            <a
              class="btn bg-red text-white mt-3"
              [routerLink]="content.d1_link"
              >{{ content.d13_text }}</a
            >
          </div>
        </div>
      </div>
      <!-- HarvX -->

      <!-- block chain -->
      <a
        [routerLink]="content.e1_link"
        class="
          benefits-box
          wow
          animate__animated animate__fadeInUp animate__delay1-2s
        "
      >
        <div class="logo-holder">
          <img src="{{ content.e1_image }}" alt="" />
        </div>
        <div class="content-holder">
          <p>{{ content.e1_text }}</p>
        </div>
        <div class="icon-holder">
          <img src="assets/images/darkgreen-arrow.webp" alt="" />
        </div>
      </a>
      <!-- block chain -->
    </div>
  </section>
  <!-- benefits  details -->
</ng-container>
