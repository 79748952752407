import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Solution } from '../types/solution.type';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolutionService {

  constructor(private httpClient: HttpClient) { }

  getLang() {
    const lang = localStorage.getItem('lang');

    return lang ? JSON.parse(lang) : 'English';
  }

  get(solution: Solution) {
    const lang = this.getLang();

    return this.httpClient.get(environment.apiEndpoint + 'solution/' + solution.route + '/' + lang);
  }

  updateContent(solution: Solution) {
    return this.httpClient.post(environment.apiEndpoint + 'solution/' + solution.route + '/' + solution.id + '/content', solution.data);
  }

  updateImage(solution: Solution) {
    return this.httpClient.post(environment.apiEndpoint + 'solution/' + solution.route + '/' + solution.id + '/image', solution.data);
  }
}
