<ngx-ui-loader></ngx-ui-loader>
<div>
  <h1 _ngcontent-lre-c3="" class="text-light" style="    text-align: center;
  margin-top: 48px;
  margin-bottom: 48px;"><a style="color: black;" routerlink="admin/login"><img src="assets/images/logo-green.webp"></a></h1>
</div>

<div style="align-items: center !important; display:flex">

<div class="container" style="max-width: 960px;">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card-group">
        <div class="card p-4">
            <form  [formGroup]="signinForm" (ngSubmit)="login(templatechangeStatus,modalRefOtp1)">
          <div class="card-body">
            <h1>Login</h1>
            <p class="text-muted">Sign In to your account</p>
            <div class="input-group mb-3">
         
              <input class="form-control" type="text"  [(ngModel)]="loginData.email"  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" class="form-control" formControlName="username"  placeholder="Phone, email, or  username"  [ngClass]="{ 'error': submitted && f.username.errors }">
            </div>
            <div class="input-group mb-4">
            
              <input type="password" [(ngModel)]="loginData.password"   [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password"   class="form-control" placeholder="Password">
            </div>
            <div class="row">
              <div class="col-6">
                <button class="btn btn-primary px-4" type="submit">Login</button>
              </div>
              <!-- <div class="col-6 text-right">
                <button class="btn btn-link px-0" type="button" (click)="forgotPassword(forgotPasswordPOP)">Forgot password?</button>
              </div> -->
            </div>
          </div>
          </form>
        </div>
        
      </div>
    </div>
  </div>
</div>
</div>

<ng-template #templatechangeStatus>
  <div class="modal-header">
     <h4 class="modal-title pull-left">Scan Code</h4>
     <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefStatus.hide()">
     <span aria-hidden="true">&times;</span>
     </button>
  </div>
  <div class="modal-body">
     <div *ngIf="qrData" style = "text-align: center;">
       <img src="{{qrData}}">
     </div>
     <div style="text-align: center;
     margin: 15px;">
     <button _ngcontent-c4="" class="btn btn-blue" style="color:white;background-color: green;" type="button" (click) = "next(modalRefOtp1)">Next</button>
     <button _ngcontent-c4="" class="btn btn-danger" style="margin-left:10px" type="button" (click)="modalRefStatus.hide()">Cancel</button>
    </div>
  </div>
</ng-template>

<ng-template #modalRefOtp1>
  <div class="modal-header">
     <h4 class="modal-title pull-left">Enter Verification Code</h4>
     <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefOtp.hide()">
     <span aria-hidden="true">&times;</span>
     </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="otp">
        <input id="target1" [(ngModel)] = "key1" tabindex='1' (input)="onInputEntry($event,'target',1)"  class="form-control" placeholder="*" autocomplete="off" maxlength="1"/>
        <input id="target2" [(ngModel)] = "key2" tabindex='2' (input)="onInputEntry($event,'target',2)"  class="form-control" placeholder="*" autocomplete="off" maxlength="1"/>
        <input id="target3" [(ngModel)] = "key3" tabindex='3' (input)="onInputEntry($event,'target',3)"  class="form-control" placeholder="*" autocomplete="off" maxlength="1"/>
        <input id="target4"  [(ngModel)] = "key4" tabindex='4'  (input)="onInputEntry($event,'target',4)"   class="form-control" placeholder="*" autocomplete="off" maxlength="1"/>
        <input id="target5" [(ngModel)] = "key5" tabindex='5' (input)="onInputEntry($event,'target',5)"   class="form-control"  placeholder="*" autocomplete="off" maxlength="1"/>
        <input id="target6" [(ngModel)] = "key6" tabindex='6' (input)="onInputEntry($event,'target',6)"   class="form-control" placeholder="*" autocomplete="off" maxlength="1"/>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p style="margin-left: 19px;">Please enter the code from the Google Authenticator app to verify your identity.</p>
        </div>
      </div>

</div>

     <button _ngcontent-c4="" class="btn btn-blue" style="color:white;background-color: green;" type="button" (click) = "submitOtp()">Submit</button>
     <button _ngcontent-c4="" class="btn btn-danger" style="margin-left:10px" type="button" (click)="modalRefOtp.hide()">Cancel</button>
  </div>
</ng-template>