import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgwWowService } from "ngx-wow";

import { About } from "../types/about.type";
import { AboutService } from "../services/about.service";

@Component({
  selector: "app-carriers",
  templateUrl: "./carriers.component.html",
  styleUrls: ["./carriers.component.css"],
})
export class CarriersComponent implements OnInit {
  content: any = {};
  contentLoaded: boolean = false;
  @ViewChild("scrollHere") scrollHere: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private wowService: NgwWowService,
    private aboutService: AboutService,
    @Inject(DOCUMENT) private _doc: Document
  ) {}

  ngOnInit(): void {
    this.wowService.init();
    this.ngxService.start("loading-career");

    const about: About = {
      route: "career",
      id: 0,
      data: null,
    };

    this.aboutService
      .get(about)
      .toPromise()
      .then(
        (data: any) => {
          this.content = data;

          about.route = "job";
          this.aboutService
            .getAll(about)
            .toPromise()
            .then(
              (data: any) => {
                this.content.jobs = data;
                this.contentLoaded = true;
                this.ngxService.stop("loading-career");
              },
              () => {
                this.ngxService.stop("loading-career");
              }
            );
        },
        () => {
          this.ngxService.stop("loading-career");
        }
      );
  }

  viewMore(link) {
    this._doc.defaultView.open(link, "_blank");
  }

  openLink(link) {
    this._doc.defaultView.open(link, "_blank");
  }

  scroll() {
    var element = this.scrollHere.nativeElement;
    const offset = 100;
    const bodyRect = this._doc.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    this._doc.defaultView.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
}
