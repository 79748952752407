import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { NgxPaginationModule } from "ngx-pagination";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { ModalModule } from "ngx-bootstrap/modal";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { LoginService } from "./services/login.service";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { AngularSvgIconModule } from "angular-svg-icon";
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  NgxUiLoaderService,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from "ngx-ui-loader";
import { ToastrModule } from "ngx-toastr";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgwWowModule } from "ngx-wow";
import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TransferHttpCacheModule } from '@nguniversal/common';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "red",
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  fgsColor: "#0a9400",
  fgsType: SPINNER.ballSpinFadeRotating,
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 2, // progress bar thickness
};

import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { SiteLayoutComponent } from "./site-layout/site-layout.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { BlockchainComponent } from "./blockchain/blockchain.component";
import { BuyersComponent } from "./buyers/buyers.component";
import { CarriersComponent } from "./carriers/carriers.component";
import { HarvxComponent } from "./harvx/harvx.component";
import { MediaComponent } from "./media/media.component";
import { ProducerComponent } from "./producer/producer.component";
import { ProductsComponent } from "./products/products.component";
import { SeedAuditComponent } from "./seed-audit/seed-audit.component";
import { SolutionsComponent } from "./solutions/solutions.component";
import { StorageComponent } from "./storage/storage.component";
import { SylosysComponent } from "./sylosys/sylosys.component";
import { TeamComponent } from "./team/team.component";
import { TrumoditiyComponent } from "./trumoditiy/trumoditiy.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ContactComponent } from "./contact/contact.component";
import { NewsDetailsComponent } from "./news-details/news-details.component";
import { JobDetailsComponent } from "./job-details/job-details.component";
import { TermsServicesComponent } from "./terms-services/terms-services.component";
import { ContactFormComponent } from "./components/contact-form/contact-form.component";
import { ContactFormResponsiveComponent } from "./components/contact-form-responsive/contact-form-responsive.component";
import { SilosysTransformationComponent } from "./silosys-transformation/silosys-transformation.component";
import { BookADemoComponent } from "./shared/components/book-ademo/book-ademo.component";
import { ProductFooterComponent } from "./shared/components/product-footer/product-footer.component";
import { ProductTimelineComponent } from "./shared/components/product-timeline/product-timeline.component";
import { AdminLayoutComponent } from "./admin/admin-layout/admin-layout.component";
import { AdminLoginComponent } from "./admin/admin-login/admin-login.component";
import { AdminHeaderComponent } from "./admin/admin-header/admin-header.component";
import { AdminSidebarComponent } from "./admin/admin-sidebar/admin-sidebar.component";

import { SolutionFooterComponent } from "./shared/components/solution-footer/solution-footer.component";
import { CommodityClassic2022Component } from './commodity-classic2022/commodity-classic2022.component';
import { ImgLazyLoadDirective } from './shared/directives/img-lazy-load.directive';

@NgModule({
  declarations: [
    AppComponent,
    SiteLayoutComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    BlockchainComponent,
    BuyersComponent,
    CarriersComponent,
    HarvxComponent,
    MediaComponent,
    ProducerComponent,
    ProductsComponent,
    SeedAuditComponent,
    SolutionsComponent,
    StorageComponent,
    SylosysComponent,
    TeamComponent,
    TrumoditiyComponent,
    PrivacyPolicyComponent,
    ContactComponent,
    NewsDetailsComponent,
    JobDetailsComponent,
    TermsServicesComponent,
    ContactFormComponent,
    ContactFormResponsiveComponent,
    SilosysTransformationComponent,
    BookADemoComponent,
    ProductFooterComponent,
    ProductTimelineComponent,
    AdminLayoutComponent,
    AdminLoginComponent,
    AdminHeaderComponent,
    AdminSidebarComponent,
    SolutionFooterComponent,
    CommodityClassic2022Component,
    ImgLazyLoadDirective,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ToastrModule.forRoot({
      progressBar: true,
      preventDuplicates: true,
      timeOut: 5000,
    }),
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    CarouselModule,
    NgwWowModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AngularEditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularSvgIconModule.forRoot(),
    TransferHttpCacheModule,
  ],
  providers: [
    LoginService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
