<ng-container *ngIf="contentLoaded">
     <!-- banner starts  -->
     <section
        class="banner banner-harvx banner-2 bg-transparent"
        [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
    >
        <div class="container-inner">
            <div class="banner-content">
                <img class="title-image img-fluid  wow animate__animated animate__fadeInUp"
                    src="{{ content.b1_image }}" alt="">
                <p class="text-white wow animate__animated animate__fadeInUp animate__delay0-5s">
                    {{ content.a1_text }}
                </p>
                <app-book-ademo></app-book-ademo>
            </div>
        </div>
         <!-- scroll addded -->
         <div class="btn-scroll-wrapper" (click) = "scroll()">
            <button class="btn-scroll wow animate__animated animate__fadeInUp animate__delay-1s">
                <span class="fa fa-angle-down"></span>
            </button>
        </div>
        <!-- scroll addded -->
    </section>

    <div class="banner-floating-img">
        <img src="{{ content.c1_image }}" class="img-fluid" alt="">
    </div>
    <!-- banner starts  -->

    <app-product-timeline [selected]="'harv-x'"></app-product-timeline>

    <!-- about harvx -->
    <section #scrollHere class="about-sylosis about-harvx">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="about-sylosis-content">
                        <img src="{{ content.d1_image }}" alt=""
                            class="title-img img-fluid   wow animate__animated animate__fadeInUp">
                        <p class="wow animate__animated animate__fadeInUp animate__delay0-5s">
                            {{ content.b1_text }}
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="{{ content.d2_image }}" alt=""
                        class="display-img img-fluid wow animate__animated animate__fadeInRight animate__delay0-5s">
                </div>
            </div>
        </div>
    </section>
    <!-- about harvx -->

    <!-- sylosis process -->
    <section class="sylosis-process harxv-process">
        <!-- shape img -->
        <img class="img-fluid desktop-hide" src="assets/images/sylosis/bg-shape-1.webp" alt="">
        <!-- shape img -->

        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="process-box   wow animate__animated animate__fadeInUp">
                        <img src="{{ content.e1_image }}" alt="">
                        <h4>{{ content.c1_text }}</h4>
                        <p>{{ content.d1_text }}</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="process-box   wow animate__animated animate__fadeInUp">
                        <img src="{{ content.e2_image }}" alt="">
                        <h4>{{ content.c2_text }}</h4>
                        <p>{{ content.d2_text }}</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="process-box   wow animate__animated animate__fadeInUp">
                        <img src="{{ content.e3_image }}" alt="">
                        <h4>{{ content.c3_text }}</h4>
                        <p>{{ content.d3_text }}</p>
                    </div>
                </div>

            </div>
        </div>

        <img class="img-fluid desktop-hide" src="assets/images/sylosis/bg-shape-2.webp" alt="">
    </section>
    <!-- sylosis process -->

    <!-- harvx producers -->
    <section class="harvx-producer">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <img src="{{ content.f1_image }}"
                        class="img-fluid mobile-hide img-display wow animate__animated animate__fadeInLeft" alt="">
                </div>
                <div class="col-md-6">
                    <div class="harvx-producer-content">
                        <h2 class="title black text-left bottom font-weight-bold wow animate__animated animate__fadeInUp">
                            <span>{{ content.e1_text }}</span>
                        </h2>
                        <h4 class="wow animate__animated animate__fadeInUp animate__delay0-5s">
                            {{ content.f1_text }}
                        </h4>
                        <p class="wow animate__animated animate__fadeInUp animate__delay0-5s">
                            {{ content.g1_text }}
                        </p>
                    </div>
                </div>
            </div>

            <!-- mobile slider -->
            <ul class="producer-slider desktop-hide harvx-common-slider">
                <owl-carousel-o [options]="customOptions1" >
                    <ng-template carouselSlide [id]="1">
                        <li><img class="img-fluid" src="{{ content.i1_image }}" alt=""></li>
                    </ng-template>
                    <ng-template carouselSlide [id]="2">
                        <li><img class="img-fluid" src="{{ content.j1_image }}" alt=""></li>
                    </ng-template>
                    <ng-template carouselSlide [id]="3">
                        <li><img class="img-fluid" src="{{ content.k1_image }}" alt=""></li>
                    </ng-template>
                    <ng-template carouselSlide [id]="4">
                        <li><img class="img-fluid" src="{{ content.l1_image }}" alt=""></li>
                    </ng-template>

                    <ng-template carouselSlide [id]="5">
                        <li><img class="img-fluid" src="{{ content.i1_image }}" alt=""></li>
                    </ng-template>
                    <ng-template carouselSlide [id]="6">
                        <li><img class="img-fluid" src="{{ content.j1_image }}" alt=""></li>
                    </ng-template>
                    <ng-template carouselSlide [id]="7">
                        <li><img class="img-fluid" src="{{ content.k1_image }}" alt=""></li>
                    </ng-template>
                    <ng-template carouselSlide [id]="8">
                        <li><img class="img-fluid" src="{{ content.l1_image }}" alt=""></li>
                    </ng-template>
                </owl-carousel-o>        
            </ul>
        </div>
    </section>
    <!-- harvx producers -->

    <!-- harvx drivers -->

    <!-- shape bg -->
    <img src="assets/images/harvx/shape-bg-blue-2.webp" alt="" class="desktop-hide img-fluid">
    <!-- shape bg -->

    <section class="harvx-drivers">
        <div class="container">
            <div class="harvx-drivers-content">
                 <h2 class="title text-white  text-left white bottom  font-weight-bold   wow animate__animated animate__fadeInUp">
                     <span>{{ content.h1_text }}</span>
                 </h2>
                 <h4 class="text-white  wow animate__animated animate__fadeInUp  animate__delay0-5s">
                     {{ content.i1_text }}
                 </h4>
                 <p class="text-white  wow animate__animated animate__fadeInUp  animate__delay0-5s">
                    {{ content.j1_text }}
                 </p>
            </div>
            <div class="display-img mobile-hide ">
                <img src="{{ content.g1_image }}"
                    class="img-fluid w-100  wow animate__animated animate__fadeInUp  animate__delay0-5s" alt="">
            </div>

            <!-- mobile slider -->
            <ul class="drivers-slider  desktop-hide harvx-common-slider">
                <owl-carousel-o [options]="customOptions" >
                    <ng-template carouselSlide [id]="1">
                        <li><img class="img-fluid" src="{{ content.m1_image }}" alt=""></li>
                    </ng-template>
                    
                    <ng-template carouselSlide [id]="2">
                        <li><img class="img-fluid" src="{{ content.n1_image }}" alt=""></li>
                    </ng-template>
                        
                    <ng-template carouselSlide [id]="3">
                        <li><img class="img-fluid" src="{{ content.o1_image }}" alt=""></li>
                    </ng-template>
                            
                    <ng-template carouselSlide [id]="4">
                        <li><img class="img-fluid" src="{{ content.p1_image }}" alt=""></li>
                    </ng-template>
                                
                    <ng-template carouselSlide [id]="5">
                        <li><img class="img-fluid" src="{{ content.q1_image }}" alt=""></li>
                    </ng-template>
                </owl-carousel-o>
            </ul>
        </div>

    </section>
    <!-- harvx drivers -->

    <!-- shape img -->
    <img src="assets/images/harvx/shape-bg-blue-1.webp" class="img-fluid w-100" alt="">
    <!-- shape img -->

    <!-- harvx facility -->
    <div class="harvx-facility">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="harvx-facility-content">
                        <h2 class="title black bottom text-left font-weight-bold   wow animate__animated animate__fadeInUp">
                            <span>{{ content.k1_text }}</span>
                        </h2>
                        <h4 class=" wow animate__animated animate__fadeInUp  animate__delay0-5s">
                            {{ content.l1_text }}
                        </h4>
                        <p class=" wow animate__animated animate__fadeInUp  animate__delay0-5s">
                            {{ content.m1_text }}
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="{{ content.h1_image }}" alt=""
                        class="display-img mobile-hide img-fluid  wow animate__animated animate__fadeInRight  animate__delay0-5s">
                </div>
            </div>
            <!-- mobile slider -->
            <ul class="facility-slider desktop-hide harvx-common-slider">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide [id]="1">
                        <li><img class="img-fluid" src="{{ content.r1_image }}" alt=""></li>
                    </ng-template>
                    <ng-template carouselSlide [id]="2">
                        <li><img class="img-fluid" src="{{ content.s1_image }}" alt=""></li>
                    </ng-template>
                    <ng-template carouselSlide [id]="3">
                        <li><img class="img-fluid" src="{{ content.t1_image }}" alt=""></li>
                    </ng-template>
                    <ng-template carouselSlide [id]="4">
                        <li><img class="img-fluid" src="{{ content.u1_image }}" alt=""></li>
                    </ng-template>
                    <ng-template carouselSlide [id]="5">
                        <li><img class="img-fluid" src="{{ content.v1_image }}" alt=""></li>
                    </ng-template>
                </owl-carousel-o>
            </ul>
        </div>
    </div>

    <!-- harvx facility -->

    <img src="assets/images/sylosis/bg-shape-2.webp" class="img-fluid desktop-hide" alt="">

    <!-- product-details -->

    <div class="product-details product-details-2 harvx-product-details">
        <img class="w-100 img-fluid mobile-hide shape-1" src="assets/images/buyers/gray-shape-3.webp" alt="">

        <div class="title-image-wrapper title-image-wrapper-2  wow animate__animated animate__fadeInUp">
            <img src="{{ content.w1_image }}" alt="">
        </div>
        <h2 class="title orange small bottom bottom-center font-weight-bold   wow animate__animated animate__fadeInUp">
            <span>{{ content.n1_text }}</span>
        </h2>

        <app-product-footer [hideProduct]="'harv-x'"></app-product-footer>
    </div>

</ng-container>