import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgwWowService } from "ngx-wow";
import { Router } from "@angular/router";

import { Media } from "../types/media.type";
import { MediaService } from "../services/media.service";

@Component({
  selector: "app-media",
  templateUrl: "./media.component.html",
  styleUrls: ["./media.component.css"],
})
export class MediaComponent implements OnInit {
  content: any = {};
  contentLoaded: boolean = false;
  @ViewChild("scrollHere") scrollHere: any;
  selectedTab: "recent-news" | "press" = "recent-news";

  constructor(
    private ngxService: NgxUiLoaderService,
    private wowService: NgwWowService,
    private mediaService: MediaService,
    private router: Router,
    @Inject(DOCUMENT) private _doc: Document
  ) {}

  ngOnInit(): void {
    this.wowService.init();
    this.ngxService.start("loading-media");

    const media: Media = {
      route: "overview",
      id: 0,
      data: null,
    };

    this.mediaService
      .get(media)
      .toPromise()
      .then(
        (data: any) => {
          this.content = data;
          this.contentLoaded = true;
          this.ngxService.stop("loading-media");
        },
        () => {
          this.ngxService.stop("loading-media");
        }
      );
  }

  selectTab(tab) {
    this.selectedTab = tab;
  }

  viewMore() {
    this.router.navigateByUrl("/archive/" + this.selectedTab);
  }

  redirectTo(link) {
    this._doc.defaultView.open(link, "_blank");
  }

  scroll() {
    var element = this.scrollHere.nativeElement;
    const offset = 45;
    const bodyRect = this._doc.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    this._doc.defaultView.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
}
