<ng-container *ngIf="contentLoaded">
    <!-- banner -->
    <section
        class="banner banner-media bg-white banner-2"
        [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
    >
        <div class="container-inner">
            <div class="banner-content">
                <h1
                    class="
                        title
                        text-white
                        wow
                        animate__animated animate__fadeInUp
                    "
                >
                    <span>{{ content.a1_text }}</span>
                </h1>
                <p
                    class="
                        text-white
                        wow
                        animate__animated animate__fadeInUp animate__delay0-5s
                    "
                >
                    {{ content.b1_text }}
                </p>
            </div>
        </div>
        <!-- scroll addded -->
        <div class="btn-scroll-wrapper" (click)="scroll()">
            <button
                class="
                    btn-scroll
                    wow
                    animate__animated animate__fadeInUp animate__delay-1s
                "
            >
                <span class="fa fa-angle-down"></span>
            </button>
        </div>
        <!-- scroll addded -->
    </section>
    <!-- banner ends -->

    <!--media releases -->
    <section #scrollHere class="media-releases">
        <div class="container">
            <!-- media releases tabs -->
            <ul
                class="
                    media-releases-tabs
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <li
                    (click)="selectTab('recent-news')"
                    [ngClass]="{ active: selectedTab === 'recent-news' }"
                    data-tab="#recent-news"
                >
                    {{ content.c1_text }}
                </li>
                <li
                    (click)="selectTab('press')"
                    [ngClass]="{ active: selectedTab === 'press' }"
                    data-tab="#press-releases"
                >
                    {{ content.c2_text }}
                </li>
            </ul>
            <!-- media releases tabs -->

            <!-- media tab wrapper desktop -->
            <div
                class="
                    media-tab-content-wrapper
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <!-- media tabs content -->
                <!-- recent news -->
                <div
                    class="media-tabs-content"
                    [ngClass]="{ active: selectedTab === 'recent-news' }"
                    id="recent-news"
                >
                    <div class="row">
                        <div
                            class="col-md-4 col-6"
                            *ngFor="let recent of content.recents | slice: 0:12"
                        >
                            <div
                                class="media-post"
                                style="cursor: pointer"
                                (click)="redirectTo(recent.a1_link)"
                            >
                                <img
                                    class="img-fluid"
                                    src="{{ recent.a1_image }}"
                                    alt=""
                                />
                                <div class="media-post-content">
                                    <h2 class="post-title text-white">
                                        {{ recent.a1_text }}
                                    </h2>
                                    <div class="date-publisher text-white">
                                        <p class="release-date">
                                            <span>{{ recent.b1_text }}</span>
                                        </p>
                                        <p class="publisher">
                                            {{ recent.c1_text }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- recent news -->

                <!-- press releases -->
                <div
                    class="media-tabs-content"
                    [ngClass]="{ active: selectedTab === 'press' }"
                    id="press-releases"
                >
                    <div class="row">
                        <div
                            class="col-md-4 col-6"
                            *ngFor="let pres of content.press | slice: 0:12"
                        >
                            <div
                                style="cursor: pointer"
                                (click)="redirectTo(pres.a1_link)"
                                class="media-post"
                            >
                                <img
                                    class="img-fluid"
                                    src="{{ pres.a1_image }}"
                                    alt=""
                                />
                                <div class="media-post-content">
                                    <h2 class="post-title text-white">
                                        {{ pres.a1_text }}
                                    </h2>
                                    <div class="date-publisher text-white">
                                        <p class="release-date">
                                            <span>{{ pres.b1_text }}</span>
                                        </p>
                                        <p class="publisher">
                                            {{ pres.c1_text }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- press releases -->
            </div>

            <button
                class="
                    btn btn-view-more btn-primary
                    wow
                    animate__animated animate__fadeInUp animate__delay0-5s
                "
                id="more"
                *ngIf="
                    selectedTab === 'recent-news'
                        ? content.recents.length > 12
                        : content.press.length > 12
                "
                (click)="viewMore()"
            >
                {{ "More" | translate }}
            </button>
        </div>
    </section>
    <!-- media releaes -->

    <!-- about grainchain inc -->
    <section class="about-grainchain-inc">
        <div class="container-inner">
            <h2
                class="
                    title
                    font-weight-bold
                    bottom bottom-center
                    orange
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <span>{{ content.d1_text }}</span>
            </h2>
            <p
                class="
                    wow
                    animate__animated animate__fadeInUp animate__delay0-5s
                "
                [innerHtml]="content.e1_text"
            >
            </p>
        </div>
    </section>
    <!-- about grainchain inc -->

    <!-- pattern img -->
    <img
        class="img-fluid w-100"
        src="assets/images/block-chain/bg-shape-3.webp"
        alt=""
    />
    <!-- pattern img -->

    <section class="media-kit-download">
        <div class="container-inner">
            <h2
                class="
                    title
                    bottom
                    text-white
                    bottom-center
                    font-weight-bold
                    orange
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <span>{{ content.f1_text }}</span>
            </h2>
            <p
                class="
                    text-white
                    wow
                    animate__animated animate__fadeInUp animate__delay0-5s
                "
            >
                {{ content.g1_text }}
            </p>
            <a
                href="{{ content.a1_link }}"
                target="_blank"
                class="
                    btn btn-transparent
                    wow
                    animate__animated animate__fadeInUp animate__delay0-5s
                "
                >{{ "Download" | translate }}</a
            >
        </div>
    </section>

    <!-- pattern img -->
    <img
        class="img-fluid w-100"
        src="assets/images/block-chain/bg-shape-4.webp"
        alt=""
    />

    <!-- pattern img -->
    <!-- home contact -->
</ng-container>
