import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(description: string) {
    this.meta.updateTag({ name: 'description', content: description });
  }

  updateOgTitle(title: string) {
    this.meta.updateTag({ property: 'og:title', content: title });
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
  }

  updateOgType(type: string) {
    this.meta.updateTag({ property: 'og:type', content: type });
  }

  updateOgDescription(description: string) {
    this.meta.updateTag({ property: 'og:description', content: description });
  }

  updateOgImage(image: string) {
    this.meta.updateTag({ property: 'og:image', content: image });
  }

}
