import { Component, OnInit, ViewChildren, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgwWowService } from 'ngx-wow';

import { Product } from '../types/product.type';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  content: any = {};
  contentLoaded: boolean = false;

  @ViewChildren('products') products: any;

  constructor(private ngxService: NgxUiLoaderService, private wowService: NgwWowService, private productService: ProductService, @Inject(DOCUMENT) private _doc: Document) { }

  ngOnInit(): void {
    this.wowService.init(); 
    this.ngxService.start('loading-products');

    const product: Product = {
      route: 'overview',
      id: 0,
      data: null
    }

    this.productService.get(product)
    .toPromise()
    .then((data: any) => {
      this.content = data;
      this.contentLoaded = true;
      this.ngxService.stop('loading-products');
    }, () => {
      this.ngxService.stop('loading-products');
    });
  }

  showProductSubtitle(subtitle: string) {
    if (subtitle) {
      const arr = subtitle.split(' ');

      if (arr.length === 2) {
        return arr[0] + ' \n ' + arr[1];
      }

      if (arr.length === 3) {
        return arr[0] + ' ' + arr[1] + ' \n ' + arr[2];
      }
    }

    return subtitle;
  }

  scrollto(id){
    const element = this.products.find((product: any) => product.nativeElement.getAttribute('id') === id).nativeElement;
    const offset = 45;
    const bodyRect = this._doc.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    this._doc.defaultView.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
}
