import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgwWowService } from 'ngx-wow';

import { Product } from '../../../types/product.type';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-product-footer',
  templateUrl: './product-footer.component.html',
  styleUrls: ['./product-footer.component.css']
})
export class ProductFooterComponent implements OnInit {

  content: any = {};
  contentLoaded: boolean = false;
  @Input() hideProduct: 'trumodity' | 'seed-audit' | 'silosys' | 'silosys-transformation' | 'harv-x' | '' = '';
  @Input() hideProducts: string[] = [];

  constructor(private ngxService: NgxUiLoaderService, private wowService: NgwWowService, private productService: ProductService, private router: Router) { }

  ngOnInit(): void {
    this.wowService.init();
    this.ngxService.start('loading-product-footer');

    const product: Product = {
      route: 'footer',
      id: 0,
      data: null
    }

    this.productService.get(product)
    .toPromise()
    .then((data: any) => {
      this.content = data;
      this.contentLoaded = true;
      this.ngxService.stop('loading-product-footer');
    }, () => {
      this.ngxService.stop('loading-product-footer');
    });
  }

  redirect(route) {
    this.router.navigateByUrl('/' + route)
  }

  isInHideProducts(product: string) {
    return this.hideProducts.indexOf(product) !== -1;
  }

}
