<header>
    <div class="container">
        <div class="header-inner">
            <!-- logo -->
            <div class="logo">
                <a routerLink="/"><img class="img-fluid" src="assets/images/logo.webp" alt="GrainChain"></a>
                <img routerLink="/" class="img-fluid fixed-logo" src="assets/images/logo-green.webp" alt="GrainChain">
            </div>

            <!-- Primary  Menu Starts here  -->
            <!-- <nav class="primary-menu" id="menuHeader">
                <span class="menu-toggle menu-close" (click) = "close()"><img class="img-fluid" src="assets/images/close.webp"
                        alt="mobile toggle"></span>
                <ul>
                    <li><a  href="javascript:void(0);" (click) = "routerNavigate('products')" >Products</a></li>
                    <li><a  href="javascript:void(0);" (click) = "routerNavigate('solutions')">Solutions</a></li>
                    <li><a  href="javascript:void(0);" (click) = "routerNavigate('media')">Media</a></li>
                    <li><a  href="javascript:void(0);" (click) = "routerNavigate('team')">About</a></li>
                    <li><a href="#"><img src="assets/images/earth.webp" alt=""></a></li>
                </ul>
            </nav> -->
            <!-- Primary  Menu Starts here  -->
            <nav #menuHeader class="primary-menu"> 
                <span style = "z-index: 9999;" class="menu-toggle menu-close" (click) = "close()"><img class="img-fluid" src="assets/images/close.webp"
                        alt="mobile toggle"></span>
                <ul>
                    <li #openDrops (click)="opendrop($event)" (mouseenter)="opendrop($event)" (mouseleave)="opendrop($event)">
                        <a href="javascript:void(0)">{{'header.products' | translate}}</a>
                        <ul>
                            <li (click) = "routerNavigate('products')"><a href="javascript:void(0);" >{{'header.overview' | translate}}</a></li>
                            <li (click) = "routerNavigate('trumodity')"><a href="javascript:void(0);" >{{'header.trumodity' | translate}}</a></li>
                            <li (click) = "routerNavigate('seed-audit')"><a href="javascript:void(0);" >{{'header.seed' | translate}}</a></li>
                            <li (click) = "routerNavigate('silosys')"><a href="javascript:void(0);" >{{'header.siloSys' | translate}}</a></li>
                            <li (click) = "routerNavigate('silosys-transformation')"><a href="javascript:void(0);" >{{'header.siloSysTransformation' | translate}}</a></li>
                            <li  (click) = "routerNavigate('harvx')"><a href="javascript:void(0);">{{'header.harvX' | translate}}</a></li>
                        </ul>
                    </li>
                    <li #openDrops (click)="opendrop($event)" (mouseenter)="opendrop($event)" (mouseleave)="opendrop($event)">
                        <a href="javascript:void(0)">{{'header.solutions' | translate}}</a>
                        <ul>
                            <li (click) = "routerNavigate('solutions')"><a href="javascript:void(0);" >{{'header.overview' | translate}}</a></li>
                            <li (click) = "routerNavigate('producers')"><a href="javascript:void(0);" >{{'header.for_prod' | translate}}</a></li>
                            <li (click) = "routerNavigate('storage-operators')"><a href="javascript:void(0);" >{{'header.for_storage' | translate}}</a></li>
                            <li (click) = "routerNavigate('buyers')"><a href="javascript:void(0);" >{{'header.for_buyers' | translate}}</a></li>
                            <li (click) = "routerNavigate('blockchain')"><a href="javascript:void(0);" >{{'header.blockchain' | translate}}</a></li>
                        </ul>
                    </li>
                    <li #openDrops (click)="opendrop($event)" (mouseenter)="opendrop($event)" (mouseleave)="opendrop($event)">
                        <a href="javascript:void(0)">{{'header.media' | translate}}</a>
                        <ul>
                             <!-- <li><a href="javascript:void(0);" (click) = "routerNavigate('team')">Overview</a></li> -->
                            <li (click) = "routerNavigate('media')"><a href="javascript:void(0)" >{{'header.media' | translate}}</a></li>
                            <!-- <li><a href="javascript:void(0);" (click) = "routerNavigate('media')">Media</a></li> -->
                            <li><a href="https://grainchain.medium.com/" target="_blank" >Blog</a></li>
                        </ul>
                    </li>
                    <li #openDrops (click)="opendrop($event)" (mouseenter)="opendrop($event)" (mouseleave)="opendrop($event)">
                        <a href="javascript:void(0)">{{'header.about' | translate}}</a>
                        <ul>
                             <!-- <li><a href="javascript:void(0);" (click) = "routerNavigate('team')">Overview</a></li> -->
                            <li (click) = "routerNavigate('team')"><a href="javascript:void(0);" >{{'header.team' | translate}}</a></li>
                            <!-- <li><a href="javascript:void(0);" (click) = "routerNavigate('media')">Media</a></li> -->
                            <li (click) = "routerNavigate('careers')"><a href="javascript:void(0);" >{{'header.careers' | translate}}</a></li>
                        </ul>
                    </li>
                    <li #openDrops (click)="opendrop($event)" (mouseenter)="opendrop($event)" (mouseleave)="opendrop($event)">
                        <a class="langMenu" href="javascript:void(0)">
                            <img id="langMenuImg" src="assets/images/earth.webp" alt="">
                        </a>
                        <ul>
                            <!-- <li><a href="javascript:void(0);" (click) = "routerNavigate('team')">Overview</a></li> -->
                           <li #langSelect *ngFor="let lang of translate.getLangs()"  (click)="changeLang(lang)"><a href="javascript:void(0);" >{{ lang }}</a></li>
                          
                       </ul>
                    </li>
                </ul>
            </nav>
            <span class="menu-toggle menu-open">
                <img class="img-fluid" src="assets/images/menu-toggle.webp"
                    alt="mobile toggle" (click) = "open()">
                    <img class="img-fluid green-toggle" src="assets/images/menu-toggle-green.webp" (click) = "open()" alt="mobile toggle">
                
                </span>
        </div>
    </div>
</header>