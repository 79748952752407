import { Component, OnInit, AfterViewInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import * as $ from 'jquery';
import defaultLanguage from "../assets/i18n/English.json";
import { TranslateService } from '@ngx-translate/core';
import { SEOService } from './services/seo.service';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'grain-chain';

  constructor(private router: Router, private wowService: NgwWowService, private translate: TranslateService, private seoService: SEOService, private activatedRoute: ActivatedRoute, @Inject(DOCUMENT) private _doc: Document) {
    translate.setTranslation('English', defaultLanguage);
    translate.setDefaultLang('English');
  }

  ngOnInit() {
    this.router.events.pipe(
       filter((event) => event instanceof NavigationEnd),
       map(() => this.activatedRoute),
       map((route) => {
         while (route.firstChild) route = route.firstChild;
         return route;
       }),
       filter((route) => route.outlet === 'primary'),
       mergeMap((route) => route.data)
    )
    .subscribe((event) => {
      if (event['title'] === 'Admin') {
        this.seoService.updateTitle(event['title']);
      } else {
        this.seoService.updateTitle(event['title']);
        this.seoService.updateDescription(event['description'] || 'Providing solutions for all participants and at every stage of the global commodity supply chain.');

        this.seoService.updateOgTitle(event['title']);
        this.seoService.updateOgUrl(environment.appUrl + this.router.url);
        this.seoService.updateOgType('website');
        this.seoService.updateOgDescription('Providing solutions for all participants and at every stage of the global commodity supply chain.');
        this.seoService.updateOgImage(environment.appUrl + '/assets/images/seo-thumbnails/' + event['ogImage']);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.showBackToTop();
      this.fixedHeader();

      // scroll top on button click
      this._doc.getElementById('back-to-top').addEventListener('click', () => {
        scroll({
          top: 0,
          behavior: "smooth"
        });
      });
    }, 1);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
      // show/hide back to top button on scroll
      this.showBackToTop();

      // show/hide header on scroll
      this.fixedHeader();
  }

  showBackToTop() {
      if (this._doc.defaultView.scrollY > 500) {
          // Show fixed nav
          this._doc.getElementById('back-to-top').classList.remove('d-none');
      } else {
          // Hide white nav
          this._doc.getElementById('back-to-top').classList.add('d-none');
      }
  }

  fixedHeader() {
      if (this._doc.defaultView.scrollY > 100) {
        // Show fixed nav
        this._doc.getElementsByTagName('header')[0].classList.add('fixed-header');
        let langMenuImg: any = this._doc.getElementById('langMenuImg');
        langMenuImg.src = "assets/images/earth_toggle.webp";
      } else {
        // Hide white nav
        this._doc.getElementsByTagName('header')[0].classList.remove('fixed-header');
        let langMenuImg: any = this._doc.getElementById('langMenuImg');
        langMenuImg.src = "assets/images/earth.webp";
      }
  }
}
