<ng-container *ngIf="contentLoaded">
    <!-- banner -->
    <section
        class="banner banner-career banner-2"
        [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
    >
        <div class="container-inner">
            <div class="banner-content">
                <h1
                    class="
                        title
                        text-white
                        wow
                        animate__animated animate__fadeInUp
                    "
                >
                    <span>{{ content.a1_text }}</span>
                </h1>
            </div>
        </div>
        <!-- scroll addded -->
        <div class="btn-scroll-wrapper" (click)="scroll()">
            <button
                class="
                    btn-scroll
                    wow
                    animate__animated animate__fadeInUp animate__delay-1s
                "
            >
                <span class="fa fa-angle-down"></span>
            </button>
        </div>
        <!-- scroll addded -->
    </section>
    <!-- banner ends -->

    <!-- innovative-team -->
    <section class="career-innovative-team">
        <div class="img-holder mobile-hide">
            <img class="img-fluid" src="{{ content.b1_image }}" alt="" />
        </div>
        <div class="innovative-team-content">
            <h2
                class="
                    title
                    text-left
                    orange
                    bottom
                    font-weight-bold
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <span>{{ content.b1_text }}</span>
            </h2>
            <div
                class="
                    wow
                    animate__animated animate__fadeInUp animate__delay0-5s
                "
                [innerHtml]="content.c1_text"
            ></div>
            <div class="learn-more-tab-wrapper">
                <ul>
                    <li
                        class="
                            wow
                            animate__animated
                            animate__fadeInRight
                            animate__delay0-5s
                        "
                    >
                        <a [routerLink]="content.a1_link">{{
                            content.d1_text
                        }}</a>
                    </li>
                    <li
                        class="
                            wow
                            animate__animated
                            animate__fadeInRight
                            animate__delay0-5s
                        "
                    >
                        <a [routerLink]="content.a2_link">{{
                            content.d2_text
                        }}</a>
                    </li>
                    <li
                        class="
                            wow
                            animate__animated
                            animate__fadeInRight
                            animate__delay0-5s
                        "
                    >
                        <a [routerLink]="content.a3_link">{{
                            content.d3_text
                        }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </section>

    <img
        class="img-fluid desktop-hide"
        src="assets/images/products/bg-pattern-4.webp"
        alt=""
    />
    <!-- innovative-team -->
    <section #scrollHere class="current-openings">
        <div class="container-inner">
            <h2
                class="
                    title
                    bottom
                    orange
                    bottom-center
                    font-weight-bold
                    wow
                    animate__animated animate__fadeInUp animate__delay0-5s
                "
            >
                <span>{{ content.e1_text }}</span>
            </h2>
            <div class="openings-list">
                <div class="row">
                    <div
                        class="col-md-4 col-sm-6"
                        *ngFor="let job of content.jobs | slice: 0:9"
                    >
                        <div
                            style="cursor: pointer"
                            (click)="openLink(job.a1_link)"
                            class="
                                opening-box
                                wow
                                animate__animated
                                animate__fadeInUp
                                animate__delay0-5s
                            "
                        >
                            <div class="opening-icon">
                                <img src="{{ job.a1_image }}" alt="" />
                            </div>
                            <div class="opening-content">
                                <p class="job-title">{{ job.a1_text }}</p>
                                <p class="job-location">{{ job.b1_text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    class="btn btn-primary btn-view-more"
                    (click)="viewMore(content.b1_link)"
                >
                    {{ "More" | translate }}
                </button>
            </div>
        </div>
    </section>

    <!-- pattern img -->
    <img
        class="
            img-fluid
            desktop-hide
            wow
            animate__animated animate__fadeInUp animate__delay0-5s
        "
        src="assets/images/products/bg-pattern-3.webp"
        alt=""
    />
</ng-container>
