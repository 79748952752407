<ng-container *ngIf="contentLoaded">
    <section
        class="banner banner-2 banner-products bg-transparent"
        [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
    >
        <div class="container-inner">
            <div class="banner-content">
                <h1 class="title text-white bottom bottom-center  wow animate__animated animate__fadeInUp">
                    <span>{{ content.a1_text }}</span>
                </h1>
                <p class="text-white wow animate__animated animate__fadeInUp animate__delay0-5s">
                    {{ content.b1_text }}
                </p>
                <app-book-ademo></app-book-ademo>
            </div>
        </div>
    </section>

    <section class="banner-floating animate__animated animate__fadeInUp animate__delay0-5s">
        <div class="container-inner mt-3">
            <div class="row" style="cursor: pointer;">
                <div class="col" *ngFor="let num of [1, 2, 3, 4, 5]">
                    <img
                        (click)="scrollto(
                            num === 1 ? 'trumodity' : 
                            num === 2 ? 'seedAudit' : 
                            num === 3 ? 'silosys' : 
                            num === 4 ? 'silosysTransformation' : 
                            num === 5 ? 'harvX' : 
                            ''
                        )"
                        class="img-fluid mb-3"
                        src="{{ 
                            num === 1 ? content.trumodity?.a1_image : 
                            num === 2 ? content.seedAudit?.a1_image : 
                            num === 3 ? content.silosys?.a1_image : 
                            num === 4 ? content.silosysTransformation?.a1_image : 
                            num === 5 ? content.harvX?.a1_image : 
                            ''
                        }}"
                        alt=""
                    >
                    <h4 class="text-center mobile-hide" style="font-size: 18px; white-space: pre;">
                        {{ showProductSubtitle(content['c' + num + '_text']) }}
                    </h4>
                </div>
            </div>
        </div>
    </section>

    <div class="container-fluid mb-5 pb-5">
        <div class="row"
            *ngFor="let letter of ['b', 'c', 'd', 'e', 'f']; let index = index"
            [ngClass]="{ 'product-gray-background': index % 2 !== 0 }"
        >
            <div class="col">
                <div
                    class="container py-5 d-flex align-items-center"
                    style="min-height: 600px;"
                    #products
                    id="{{
                        letter === 'b' ? 'trumodity' : 
                        letter === 'c' ? 'seedAudit' : 
                        letter === 'd' ? 'silosys' : 
                        letter === 'e' ? 'silosysTransformation' : 
                        letter === 'f' ? 'harvX' : 
                        ''
                    }}"
                >
                    <div class="row">
                        <div
                            class="col-md-6 d-flex align-items-center"
                            [ngClass]="{ 'order-1': index % 2 === 0, 'order-2': index % 2 !== 0 }"
                        >
                            <div>
                                <img
                                    class="desktop-hide img-fluid mb-3 wow animate__animated animate__fadeInUp"
                                    src="{{ content['g' + (index + 1) + '_image'] }}"
                                    alt=""
                                >
                                <img
                                    class="mobile-hide logo-img img-fluid mb-5 wow animate__animated animate__fadeInUp"
                                    src="{{ content[letter + '1_image'] }}"
                                >
                                <p class="animate__animated animate__fadeInUp animate__delay0-5s">
                                    {{ content[letter === 'b' ? 'trumodity' : '']?.a1_text }}
                                    {{ content[letter === 'c' ? 'seedAudit' : '']?.a1_text }}
                                    {{ content[letter === 'd' ? 'silosys' : '']?.a1_text }}
                                    {{ content[letter === 'e' ? 'silosysTransformation' : '']?.a1_text }}
                                    {{ content[letter === 'f' ? 'harvX' : '']?.a1_text }}
                                </p>
                                <div
                                    class="action wow animate__animated animate__fadeInUp animate__delay0-5s text-center text-md-left"
                                >
                                    <a
                                        routerLink="{{
                                            letter === 'b' ? '/trumodity' : 
                                            letter === 'c' ? '/seed-audit' : 
                                            letter === 'd' ? '/silosys' : 
                                            letter === 'e' ? '/silosys-transformation' : 
                                            letter === 'f' ? '/harvx' : 
                                            ''
                                        }}"
                                        class="btn text-white mt-3 mt-md-0"
                                        [ngClass]="{
                                            'bg-yellow': letter == 'b',
                                            'bg-light-green': letter == 'c',
                                            'bg-blue': letter == 'd',
                                            'bg-purple': letter == 'e',
                                            'bg-red': letter == 'f'
                                        }"
                                    >
                                        {{ 'learn' | translate }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mobile-hide" [ngClass]="{ 'order-1': index % 2 !== 0, 'order-2': index % 2 === 0 }">
                            <div
                                class="wow animate__animated d-flex align-items-center"
                                [ngClass]="{ 'animate__fadeInLeft': index % 2 === 0, 'animate__fadeInRight': index % 2 !== 0 }"
                            >
                                <img
                                    class="img-fluid display-img"
                                    src="{{ content[letter + '2_image'] }}" style="max-height: 550px;"
                                    alt=""
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <a class="bottom-cta-btn btn bg-green text-white  animate__animated animate__fadeInUp animate__delay-2s mb-5" routerLink="/solutions">
            <span>{{ content.footer?.a1_text }}</span>
            <img src="assets/images/buyers/arrow-product.webp" alt="">
        </a>
    </div>
</ng-container>