import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject} from 'rxjs';
import {environment } from './../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
 
  userData: any; 
  catData:any

  private logoutType: Subject<any> = new Subject<any>();

  public logoutType$ = this.logoutType.asObservable();

  public sendLogout(data: any){

    this.logoutType.next(data);
}

  
  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { 
    //console.log(environment)
  }

  login(authCredentials) {
    return this.http.post(environment.apiEndpoint + 'login', authCredentials,this.noAuthHeader);
  }
  

  confirmNewPassword(newPassword) {
    return this.http.post(environment.apiEndpoint + 'confirmPassword', newPassword,this.noAuthHeader);
  }
  forgotPassword(email) {
    return this.http.post(environment.apiEndpoint + 'checkEmailExists', email,this.noAuthHeader);
  }
  validateFogetToken(token) {
    return this.http.post(environment.apiEndpoint + 'validateFogetToken', token,this.noAuthHeader);
  }
  //Helper Methods

  setToken(token: string) {
    console.log(token)
    localStorage.setItem('token', token);
  }
  setUserDetails(data:any){
    localStorage.setItem('userDetailsHandyman',  JSON.stringify(data));
  }

  registerSocial(data) {
    return this.http.post(environment.apiEndpoint + 'registerSocial', data,this.noAuthHeader);
  }

  firstTimeSocial(data) {
    return this.http.post(environment.apiEndpoint + 'firstTimeSocial', data,this.noAuthHeader);
  }
  
  getUserDetails(){
    let details = localStorage.getItem('userDetailsHandyman');
    return JSON.parse(details);
  }

  setCatID(data:any){
    localStorage.setItem('setCatID',  JSON.stringify(data));
  }
  
  getCatID(){
    let details = localStorage.getItem('setCatID');
    return JSON.parse(details);
  }

  setHomeData(data:any){
    localStorage.setItem('setHomeData',  JSON.stringify(data));
  }
  
  getHomeData(){
    let details = localStorage.getItem('setHomeData');
    return JSON.parse(details);
  }

  confirmAccount(id){
    return this.http.get(environment.apiEndpoint  + 'confirm-account/'+id);
  }
  cancelStatus(id,userID){
    return this.http.get(environment.apiEndpoint  + 'cancelStatus/'+id+'/'+userID);
  }


  getToken() {
    return localStorage.getItem('token');
  }
  deleteUserDetails() {
    localStorage.removeItem('userDetailsHandyman');
  }
  deleteToken() {
    localStorage.removeItem('token');
  }

  getUserPayload() {
    var token = this.getToken();
    if (token) {
      try{
        var userPayload = atob(token.split('.')[1]);
        return JSON.parse(userPayload);
      }catch(e){
        return token;
      }
     
    }
    else
      return null;
  }

  isLoggedIn() {
    var userPayload = this.getUserPayload();
    if (userPayload)
      return userPayload.exp > Date.now() / 1000;
    else
      return false;
  }
  getListLangs() {
    return this.http.get(environment.apiEndpoint  + 'admin/getListLang');
  }
  registerUser(jsonPayload):Observable<any>{
    return this.http.post(environment.apiEndpoint + 'register',jsonPayload,this.noAuthHeader);
  }
  registerLang(jsonPayload):Observable<any>{
    return this.http.post(environment.apiEndpoint + 'registerLang',jsonPayload,this.noAuthHeader);
  }
  registerUserAdmin(jsonPayload):Observable<any>{
    return this.http.post(environment.apiEndpoint + 'registerUserAdmin',jsonPayload,this.noAuthHeader);
  }
  becomePro(jsonPayload):Observable<any>{
    return this.http.post(environment.apiEndpoint + 'becomePro',jsonPayload,this.noAuthHeader);
  }
  addCategory(jsonPayload):Observable<any>{
    return this.http.post(environment.apiEndpoint + 'addCategory',jsonPayload,this.noAuthHeader);
  }
  addsubCategory(jsonPayload,id):Observable<any>{
    return this.http.post(environment.apiEndpoint + 'addsubCategory/'+id,jsonPayload,this.noAuthHeader);
  }


  
  privacy() {
    let lang = this.getLang();
    if(!lang){
      lang = "English"
    }
    return this.http.get(environment.apiEndpoint  + 'admin/privacy/'+lang);
  }

  getsubcategoryDetails(id) {
    return this.http.get(environment.apiEndpoint  + 'getsubcategoryDetails/'+id);
  }
  getQuoteDetails(id) {
    return this.http.get(environment.apiEndpoint  + 'getQuoteDetails/'+id);
  }


  getListQuotes(id) {
    return this.http.get(environment.apiEndpoint  + 'getListQuotesUser/'+id);
  }
  terms() {
    let lang = this.getLang();
    if(!lang){
      lang = "English"
    }
    return this.http.get(environment.apiEndpoint  + 'admin/terms/'+lang);
  }

  about() {
    let lang = this.getLang();
    if(!lang){
      lang = "English"
    }
    return this.http.get(environment.apiEndpoint  + 'admin/about');
  }
  aboutImages() {
    let lang = this.getLang();
    if(!lang){
      lang = "English"
    }
    return this.http.get(environment.apiEndpoint  + 'aboutImages/'+lang);
  }
  contact(data) {
    return this.http.post(environment.apiEndpoint  + 'contact',data);
  }

  validateUser(email) {
    return this.http.get(environment.apiEndpoint  + 'validateUser/'+email);
  }
  getPostDetails(id) {
    let lang = this.getLang();
    if(!lang){
      lang = "English"
    }
    return this.http.get(environment.apiEndpoint  + 'getPostDetails/'+id);
  }
  getNewsDetails(id) {
    let lang = this.getLang();
    if(!lang){
      lang = "English"
    }
    return this.http.get(environment.apiEndpoint  + 'newsDetails/'+id);
  }


  //Dont delete
  listRecords() {
    return this.http.get(environment.apiEndpoint  + 'domains');
  }

  listRecordsAdmin() {
    return this.http.get(environment.apiEndpoint  + 'getDomainsAdmin');
  }

  listOrders(id) {
    return this.http.get(environment.apiEndpoint  + 'backorders/'+id);
  }
  deleteBackorder(id) {
    return this.http.get(environment.apiEndpoint  + 'deleteBackorder/'+id);
  }

  listBookings(id) {
    return this.http.get(environment.apiEndpoint  + 'listBookings/'+id);
  }

  addOrder(data,id) {
    return this.http.post(environment.apiEndpoint  + 'order/'+id,data);
  }
  addBackorder(data,id) {
    return this.http.post(environment.apiEndpoint  + 'addBackorder/'+id,data);
  }
  private imageUrl: Subject<any> = new Subject<any>();
  public imageUrl$ = this.imageUrl.asObservable();
  public sendImageUpdate(data: any){
    this.imageUrl.next(data);
}
updateProfileImage(jsonPayload){
  return this.http.post(environment.apiEndpoint + 'updateProfileImage',jsonPayload);
}
updateUser(jsonPayload,id):Observable<any>{  
  return this.http.post(environment.apiEndpoint + 'updateUser/'+id,jsonPayload);
}

updateCategory(jsonPayload,id):Observable<any>{  
  return this.http.post(environment.apiEndpoint + 'updateCategory/'+id,jsonPayload);
}
updateSubCategory(jsonPayload,id):Observable<any>{  
  return this.http.post(environment.apiEndpoint + 'updateSubCategory/'+id,jsonPayload);
}
subscribeEmail(jsonPayload):Observable<any>{  
  return this.http.post(environment.apiEndpoint + 'subscribe',jsonPayload);
}
activate(jsonPayload):Observable<any>{  
  return this.http.post(environment.apiEndpoint + 'activateSubscribe',jsonPayload);
}
listCountries() {
  return this.http.get(environment.apiEndpoint  + 'countries');
}

addRecord(jsonPayload){
  return this.http.post(environment.apiEndpoint + 'addDomain',jsonPayload);
}
editRecord(id,jsonPayload){
  return this.http.post(environment.apiEndpoint + 'editDomain/'+id,jsonPayload);
}
deleteRecord(id){
  return this.http.get(environment.apiEndpoint + 'deleteDomain/'+id);
}
getAdminBackorders(){
  return this.http.get(environment.apiEndpoint + 'listBookingsAdmin');
}

getAdminHandpicked(){
  return this.http.get(environment.apiEndpoint + 'adminHandpicked');
}

changeUserPassword(data){
  return this.http.post(environment.apiEndpoint + 'changeUserPassword',data);
}

getCatAndSub(){
  return this.http.get(environment.apiEndpoint + 'catAndSub');
}
subbyId(id){
  return this.http.get(environment.apiEndpoint + 'subbyId/'+id);
}
setBookingDetails(data:any){
  localStorage.setItem('setBookingDetailsHandyman',  JSON.stringify(data));
}

getBookingDetails(){
  let details = localStorage.getItem('setBookingDetailsHandyman');
  return JSON.parse(details);
}

setServiceDetails(data:any){
  localStorage.setItem('setServiceDetails',  JSON.stringify(data));
}

getServiceDetails(){
  let details = localStorage.getItem('setServiceDetails');
  return JSON.parse(details);
}
order(data){
  return this.http.post(environment.apiEndpoint + 'order',data);
}

setBookingID(data:any){
  localStorage.setItem('setBookingID',  JSON.stringify(data));
}

getBookingID(){
  let details = localStorage.getItem('setBookingID');
  return JSON.parse(details);
}
quoteJob(data){
  return this.http.post(environment.apiEndpoint + 'quoteJob',data);
}
updateQuoteJob(data,id){
  return this.http.post(environment.apiEndpoint + 'updateQuoteJob/'+id,data);
}
validatePin(data){
  return this.http.post(environment.apiEndpoint + 'validatePin',data);
}



// starts here
getHomeContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + `getContent/${lang}`);
}
updateHomeContent(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateHomeContent/'+lang,data);
}

updateHomeTitle(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateHomeTitle/'+lang,data);
}
updateHeaderImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateHeaderImage/'+lang,data);
}
updateHeaderImageOnly(data, id){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateHeaderImageOnly/'+lang + '?id='+id,data);
}
updateHowData(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateHowData/'+lang,data);
}
updateSolData(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSolData/'+lang,data);
}
updateSolDataImages(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSolDataImages/'+lang,data);
}
updateSolBottom(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSolBottom/'+lang,data);
}
updateProdBottom(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateProdBottom/'+lang,data);
}
updateBlockBottom(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBlockBottom/'+lang,data);
}
updateTruBottom(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateTruBottom/'+lang,data);
}
updateNewsBottom(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateNewsBottom/'+lang,data);
}
updatecarrsBottom(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updatecarrsBottom/'+lang,data);
}
updateAboutBottom(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateAboutBottom/'+lang,data);
}
updateAboutBottomLower(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateAboutBottomLower/'+lang,data);
}
updateNewsBottomLower(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateNewsBottomLower/'+lang,data);
}

updateSilBottom(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSilBottom/'+lang,data);
}
updateProduBottom(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateProduBottom/'+lang,data);
}
updateStorageBottom(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateStorageBottom/'+lang,data);
}

updateBuyersBottom(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBuyersBottom/'+lang,data);
}

updateProduBottom1(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateProduBottom1/'+lang,data);
}

deleteHeaderImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteHeaderImage/'+lang,data);
}
updateNewsContent(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateNewsContent/'+lang,data);
}

deleteNewsImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteNewsImage/'+lang,data);
}
deleteCarrerImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteCarrerImage/'+lang,data);
}

getHeaderImage(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getHeaderImage/'+lang);
}
updateHowImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateHowImage/'+lang,data);
}
updateHowImagec(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateHowImagec/'+lang,data);
}
updateHowImageOnly(data, id){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateHowImageOnly/'+lang + '?id='+id,data);
}
deleteHowImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteHowImage/'+lang,data);
}
getHowImage(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getHowImage/'+lang);
}
updateWorkImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateWorkImage/'+lang,data);
}
updateWorkImageOnly(data, id){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateWorkImageOnly/'+lang + '?id='+id,data);
}
deleteWorkImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteHowImage/'+lang,data);
}
getWorkImage(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getWorkImage/'+lang);
}
updatePartnerImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updatePartnerImage/'+lang,data);
}

deletePartnerImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deletePartnerImage/'+lang,data);
}
getPartnerImage(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getPartnerImage/'+lang);
}

getSocailContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getSocailContent/'+lang);
}
updateSocialContent(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSocialContent/'+lang,data);
}
getProductContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getProductContent/'+lang);
}
getNewsContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getNewsContent/'+lang);
}
getAboutContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getAboutContent/'+lang);
}

getCarrerContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getCarrerContent/'+lang);
}
getProductLower(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getProductLower/'+lang);
}
getNews11Images(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getNewsImages/'+lang);
}
getCarrierImages(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getCarrierImages/'+lang);
}
getSolHeader(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getSolHeader/'+lang);
}
getSolContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getSolContent/'+lang);
}
getSolImages(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getSolImages/'+lang);
}
getProblemImages(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getProblemImages/'+lang);
}
getBottomImages(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getBottomImages/'+lang);
}

getProducerContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getProducerContent/'+lang);
}
getProducerContentStorage(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getProducerContentStorage/'+lang);
}
getBlockChainContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getBlockChainContent/'+lang);
}
getBlockChainBenifts(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getBlockChainBenifts/'+lang);
}
// whyBlockchain(){
//   return this.http.get(environment.apiEndpoint + 'whyBlockchain');
// }
// smartContract(){
//   return this.http.get(environment.apiEndpoint + 'smartContract');
// }
// blockchainTechnology(){
//   return this.http.get(environment.apiEndpoint + 'blockchainTechnology');
// }



getProducerContentBuyer(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getProducerContentBuyer/'+lang);
}

getBottomProImage(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getBottomProImage/'+lang);
}
getBottomProImageStorage(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getBottomProImageStorage/'+lang);
}
getBottomProImageBuyer(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getBottomProImageBuyer/'+lang);
}
getBeniftsImage(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getBeniftsImage/'+lang);
}
getBeniftsImageStorage(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getBeniftsImageStorage/'+lang);
}
getBeniftsImageBuyer(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getBeniftsImageBuyer/'+lang);
}
updateProblemImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateProblemImage/'+lang,data);
}
deleteProblemImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteProblemImage/'+lang,data);
}

updateSolImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSolImage/'+lang,data);
}
updateBottomImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBottomImage/'+lang,data);
}
deleteSolImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteSolImage/'+lang,data);
}
deleteBottomImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteBottomImage/'+lang,data);
}
updateSolContent(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSolContent/'+lang,data);
}
updateSolHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSolHeader/'+lang,data);
}

deleteSolHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteSolHeader/'+lang,data);
}

updateProduct(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateProduct/'+lang,data);
}
updateAbout(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateAbout/'+lang,data);
}


updateCarrer(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateCarrer/'+lang,data);
}

updateNews(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateNews/'+lang,data);
}

updateProdHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateProdHeader/'+lang,data);
}
updateProdHeaderStorage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateProdHeaderStorage/'+lang,data);
}
updateBlockchainTechnology(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBlockchainTechnology/'+lang,data);
}
updateSmartContracts(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSmartContracts/'+lang,data);
}
updateWhyImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateWhyImage/'+lang,data);
}
updateBeniftsImageBlockchain(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBeniftsImageBlockchain/'+lang,data);
}
updateBlockchainHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBlockchainHeader/'+lang,data);
}

updateProdHeaderBuyer(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateProdHeaderBuyer/'+lang,data);
}
deleteProHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteProHeader/'+lang,data);
}

deleteChallengeImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteChallengeImage'+lang,data);
}
deleteChallengeImageStorage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteChallengeImageStorage/'+lang,data);
}
deleteChallengeImageBuyer(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteChallengeImageBuyer/'+lang,data);
}
updateChallengeImg(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateChallengeImg/'+lang,data);
}
updateChallengeImgStorage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateChallengeImgStorage/'+lang,data);
}
updateChallengeImgBuyer(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateChallengeImgStorage/'+lang,data);
}
updateBeniftsImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBeniftsImage/'+lang,data);
}
updateBeniftsImageStorage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBeniftsImageStorage/'+lang,data);
}
updateBeniftsImageBuyer(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBeniftsImageBuyer/'+lang,data);
}
deleteBeniftsImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteBeniftsImage/'+lang,data);
}
deleteBeniftsImageStorage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteBeniftsImageStorage/'+lang,data);
}
deleteblockChainImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteblockChainImage/'+lang,data);
}

deleteBeniftsImageBuyer(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteBeniftsImageBuyer/'+lang,data);
}
updateBottomProImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBottomProImage/'+lang,data);
}
updateBottomProImageStorage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBottomProImageStorage/'+lang,data);
}

updateBottomProImageBuyer(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBottomProImageBuyer/'+lang,data);
}
deleteBottomProImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteBottomProImage/'+lang,data);
}
deleteBottomProImageStorage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteBottomProImageStorage/'+lang,data);
}
deleteBottomProImageBuyer(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteBottomProImageBuyer/'+lang,data);
}
updateProContent(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateProContent/'+lang,data);
}
updateProContentStorage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateProContentStorage/'+lang,data);
}

updateBlockchainContent(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBlockchainContent/'+lang,data);
}

updateProContentBuyer(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateProContentBuyer/'+lang,data);
}
updateLowerHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateLowerHeader/'+lang,data);
}
updateNewsMedia(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateNewsMedia/'+lang,data);
}
updateProductHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateProductHeader/'+lang,data);
}

updateNewsHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateNewsHeader/'+lang,data);
}
updateAboutHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateAboutHeader/'+lang,data);
}

updateGerainHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateGerainHeader/'+lang,data);
}
deleteAboutImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteAboutImage/'+lang,data);
}

updateCarrerHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateCarrerHeader/'+lang,data);
}
updateLowerSection(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateLowerSection/'+lang,data);
}
updateLowerSectionImage(data, id){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateLowerSectionImage/'+lang + '?id='+id,data);
}

updateNewsImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateNewsImage/'+lang,data);
}

updateBioImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBioImage/'+lang,data);
}
updatCarrerImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updatCarrerImage/'+lang,data);
}

//code starts here
getTrumoditiyContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getTrumoditiyContent/'+lang);
}
getTrumoditiyFooter(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getTrumoditiyFooter/'+lang);
}
getTrumoditiyFooter1(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getTrumoditiyFooter1/'+lang);
}
updateTrumoditiyHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateTrumoditiyHeader/'+lang,data);
}
bottomTrumodityImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'bottomTrumodityImage/'+lang,data);
}
bottomTrumodityImage1(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'bottomTrumodityImage1/'+lang,data);
}
updateTrumodityWhatTitleImage(data, id){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateTrumodityWhatTitleImage/'+lang + '?id='+id,data);
}
updateTrumodityMobile(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateTrumodityMobile/'+lang,data);
}
whatTrumodity(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'whatTrumodity/'+lang,data);
}
updateCommonFooter(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateCommonFooter/'+lang,data);
}

updateCommonFooter1(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateCommonFooter1/'+lang,data);
}
updateTruContent(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateTruContent/'+lang,data);
}
deleteCommonFooter(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteCommonFooter/'+lang,data);
}
deleteCommonFooter1(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'deleteCommonFooter1/'+lang,data);
}
getsilContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getsilContent/'+lang);
}
updateHeaderSil(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateHeaderSil/'+lang,data);
}
whatSil(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'whatSil/'+lang,data);
}
updateProducersImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateProducersImage/'+lang,data);
}
updateElevatorImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateElevatorImage/'+lang,data);
}
updateBuyersSil(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateBuyersSil/'+lang,data);
}
updateSilContent(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSilContent/'+lang,data);
}
updateSilWhatTitleImage(data, id){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSilWhatTitleImage/'+lang + '?id='+id,data);
}
getSilosysTransformationContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getSilosysTransformationContent/'+lang);
}
updateSilosysTransformationContent(data, id){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSilosysTransformationContent/' + lang + '?id=' + id, data);
}
updateSilosysTransformationImage(data, id){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSilosysTransformationImage/' + lang + '?id=' + id, data);
}
getseedContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getseedContent/'+lang);
}
updateSeedHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSeedHeader/'+lang,data);
}
updateSeedImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSeedImage/'+lang,data);
}
bottomSeedImage(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'bottomSeedImage/'+lang,data);
}
updateSeedContent(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSeedContent/'+lang,data);
}
updateSeedWhatTitleImage(data, id){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateSeedWhatTitleImage/'+lang + '?id='+id,data);
}
getharvxContent(){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.get(environment.apiEndpoint + 'getharvxContent/'+lang);
}
updateharvxContent(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateharvxContent/'+lang,data);
}
updateharvxHeader(data){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateharvxHeader/'+lang,data);
}
updateHarvxWhatTitleImage(data, id){
  let lang = this.getLang();
  if(!lang){
    lang = "English"
  }
  return this.http.post(environment.apiEndpoint + 'updateHarvxWhatTitleImage/'+lang + '?id='+id,data);
}
setLang(data:any){
  localStorage.setItem('lang',  JSON.stringify(data));
}
getLang(){
  let details = localStorage.getItem('lang');
  return JSON.parse(details);
}

}