import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  PLATFORM_ID,
} from "@angular/core";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";

@Component({
  selector: "app-book-ademo",
  templateUrl: "./book-ademo.component.html",
  styleUrls: ["./book-ademo.component.css"],
})
export class BookADemoComponent implements OnInit {
  public isBrowser = isPlatformBrowser(this.platformId);

  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {}

  book() {
    if (this.isBrowser) {
      this._doc
        .getElementById("bottom-scroll")
        .scrollIntoView({ behavior: "smooth" });
    }
  }
}
