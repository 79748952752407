import { Component, OnInit, ViewChild } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgwWowService } from "ngx-wow";

import { Solution } from "../types/solution.type";
import { SolutionService } from "../services/solution.service";

@Component({
  selector: "app-producer",
  templateUrl: "./producer.component.html",
  styleUrls: ["./producer.component.css"],
})
export class ProducerComponent implements OnInit {
  content: any = {};
  contentLoaded: boolean = false;
  @ViewChild("scrollHere") scrollHere: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private wowService: NgwWowService,
    private solutionService: SolutionService
  ) {}

  ngOnInit(): void {
    this.wowService.init();
    this.ngxService.start("loading-producer");

    const solution: Solution = {
      route: "producer",
      id: 0,
      data: null,
    };

    this.solutionService
      .get(solution)
      .toPromise()
      .then(
        (data: any) => {
          this.content = data;
          this.contentLoaded = true;
          this.ngxService.stop("loading-producer");
        },
        () => {
          this.ngxService.stop("loading-producer");
        }
      );
  }

  scroll() {
    this.scrollHere.nativeElement.scrollIntoView({ behavior: "smooth" });
  }
}
