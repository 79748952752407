import { Component, OnInit } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";

import { Agreement } from "../types/agreement.type";
import { AgreementService } from "../services/agreement.service";

@Component({
  selector: "app-terms-services",
  templateUrl: "./terms-services.component.html",
  styleUrls: ["./terms-services.component.css"],
})
export class TermsServicesComponent implements OnInit {
  content: string = "";

  constructor(
    private ngxService: NgxUiLoaderService,
    private agreementService: AgreementService
  ) {}

  ngOnInit(): void {
    const agreement: Agreement = {
      route: "term",
      data: null,
    };

    this.ngxService.start("terms");
    this.agreementService
      .get(agreement)
      .toPromise()
      .then(
        (data: any) => {
          this.content = data?.content ?? "";
          this.ngxService.stop("terms");
        },
        () => {
          this.ngxService.stop("terms");
        }
      );
  }
}
