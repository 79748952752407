<ng-container *ngIf="contentLoaded">
    <!-- banner starts  -->
    <section
        class="banner banner-sylosis banner-2 bg-transparent"
        [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
    >
        <div class="container-inner">
            <div class="banner-content">
                <img class="title-image img-fluid wow animate__animated animate__fadeInUp"
                    src="{{ content.b1_image }}" alt="">
                <p class="text-white wow animate__animated animate__fadeInUp animate__delay0-5s">
                    {{ content.a1_text }}
                </p>
                <app-book-ademo></app-book-ademo>
            </div>
        </div>
        <!-- scroll addded -->
        <div class="btn-scroll-wrapper" (click) = "scroll()">
            <button class="btn-scroll wow animate__animated animate__fadeInUp animate__delay-1s">
                <span class="fa fa-angle-down"></span>
            </button>
        </div>
        <!-- scroll addded -->
    </section>
    <!-- banner starts  -->

    <!-- floating img -->
    <div class="banner-floating-img">
        <img src="{{ content.c1_image }}" class="img-fluid" alt="">
    </div>
    <!-- floating img -->

    <app-product-timeline [selected]="'silosys'"></app-product-timeline>

    <!-- about sylosis -->
    <section #scrollHere class="about-sylosis">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="about-sylosis-content">
                        <img src="{{ content.d1_image }}" alt=""
                            class="title-img img-fluid  wow animate__animated animate__fadeInUp">
                        <p class="wow animate__animated animate__fadeInUp animate__delay0-5s">
                            {{ content.b1_text }}
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="{{ content.d2_image }}" alt=""
                        class="display-img img-fluid  wow animate__animated animate__fadeInRight animate__delay0-5s">
                </div>
            </div>
        </div>
    </section>

    <!-- sylosis process -->
    <section class="sylosis-process">
        <!-- shape img -->
        <img class="img-fluid desktop-hide" src="assets/images/sylosis/bg-shape-1.webp" alt="">
        <!-- shape img -->
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="process-box  wow animate__animated animate__fadeInUp">
                        <img src="{{ content.e1_image }}" alt="">
                        <h4>{{ content.c1_text }}</h4>
                        <p>{{ content.d1_text }}</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="process-box  wow animate__animated animate__fadeInUp">
                        <img src="{{ content.e2_image }}" alt="">
                        <h4>{{ content.c2_text }}</h4>
                        <p>{{ content.d2_text }}</p>

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="process-box  wow animate__animated animate__fadeInUp">
                        <img src="{{ content.e3_image }}" alt="">
                        <h4>{{ content.c3_text }}</h4>
                        <p>{{ content.d3_text }}</p>
                    </div>
                </div>
            </div>
        </div>
        <img class="img-fluid desktop-hide" src="assets/images/sylosis/bg-shape-2.webp" alt="">
    </section>
    <!-- sylosis process -->

    <img class="w-100 img-fluid mobile-hide" src="assets/images/trumodity/bg-shape-1.webp" alt="">
    <!-- product-details -->

    <div class="product-details product-details-2 sylosis-product-details">
        <div class="title-image-wrapper title-image-wrapper-2 wow animate__animated animate__fadeInUp ">
            <img src="{{ content.f1_image }}" alt="">
        </div>
        <h2 class="title orange small bottom bottom-center font-weight-bold wow animate__animated animate__fadeInUp">
            <span>{{ content.e1_text }}</span>
        </h2>
        
        <app-product-footer [hideProduct]="'silosys'"></app-product-footer>
    </div>

</ng-container>