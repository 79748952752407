<ng-container *ngIf="contentLoaded">
  <div class="text-center my-5 px-1" [innerHtml]="content.a1_text"></div>

  <div class="d-flex justify-content-center my-2 my-md-5">
    <button
      class="btn-right-first btn-seed-audit first right"
      [ngClass]="{
        'btn-seed-audit-active': selected === 'seed-audit',
        'd-none': isMobile && (selected === 'silosys' || selected === 'trumodity' || selected === 'silosys-transformation')
      }"
      (click)="select('seed-audit')"
    >
      <a href="#" (click)="false" [innerHtml]="content.b1_text"></a>
    </button>
    <button
      class="btn-right-second btn-harv-x second right"
      [ngClass]="{
        'btn-harv-x-active': selected === 'harv-x',
        'd-none': isMobile && (selected === 'trumodity' || selected === 'silosys-transformation')
      }"
      (click)="select('harv-x')"
    >
      <a href="#" (click)="false" [innerHtml]="content.b2_text"></a>
    </button>
    <button
      class="btn-right-third btn-silosys third right"
      [ngClass]="{
        'btn-silosys-active': selected === 'silosys',
        'd-none': isMobile && !selected
      }"
      (click)="select('silosys')"
    >
      <a href="#" (click)="false" [innerHtml]="content.b3_text"></a>
    </button>
    <button
      class="btn-right-second btn-trumodity fourth right"
      [ngClass]="{
        'btn-trumodity-active': selected === 'trumodity',
        'd-none': isMobile && (selected === 'seed-audit' || selected === 'harv-x')
      }"
      (click)="select('trumodity')"
    >
      <a href="#" (click)="false" [innerHtml]="content.b4_text"></a>
    </button>
    <button
      class="btn-right-first btn-silosys-transformation fifth right"
      [ngClass]="{
        'btn-silosys-transformation-active': selected === 'silosys-transformation',
        'd-none': isMobile && (selected === 'seed-audit' || selected === 'harv-x' || selected === 'silosys')
      }"
      (click)="select('silosys-transformation')"
    >
      <a href="#" (click)="false" [innerHtml]="content.b5_text"></a>
    </button>
  </div>

  <div class="container my-2 my-md-5">
    <div class="row">
      <div class="col-3 d-flex justify-content-end align-items-center">
        <div class="green-circle d-flex justify-content-center align-items-center" [ngClass]="{ 'gray-circle': isFirst }" (click)="back()">
          <i class="fa fa-chevron-left text-white"></i>
        </div>
      </div>
      <div class="col-6" *ngIf="selected === 'seed-audit'">
        <div class="card border-0">
          <div class="card-body d-flex justify-content-center align-items-center">
            <img class="img-fluid" src="{{ content.a1_image }}">
          </div>
          <div class="card-footer bg-white border-0" class="text-center" [innerHtml]="content.c1_text">
          </div>
        </div>
      </div>
      <div class="col-6" *ngIf="selected === 'harv-x'">
        <div class="card border-0">
          <div class="card-body d-flex justify-content-center align-items-center">
            <img class="img-fluid" src="{{ content.a2_image }}">
          </div>
          <div class="card-footer bg-white border-0" class="text-center" [innerHtml]="content.c2_text">
          </div>
        </div>
      </div>
      <div class="col-6" *ngIf="selected === 'silosys'">
        <div class="card border-0">
          <div class="card-body d-flex justify-content-center align-items-center">
            <img class="img-fluid" src="{{ content.a3_image }}">
          </div>
          <div class="card-footer bg-white border-0" class="text-center" [innerHtml]="content.c3_text">
          </div>
        </div>
      </div>
      <div class="col-6" *ngIf="selected === 'trumodity'">
        <div class="card border-0">
          <div class="card-body d-flex justify-content-center align-items-center">
            <img class="img-fluid" src="{{ content.a4_image }}">
          </div>
          <div class="card-footer bg-white border-0" class="text-center" [innerHtml]="content.c4_text">
          </div>
        </div>
      </div>
      <div class="col-6" *ngIf="selected === 'silosys-transformation'">
        <div class="card border-0">
          <div class="card-body d-flex justify-content-center align-items-center">
            <img class="img-fluid" src="{{ content.a5_image }}">
          </div>
          <div class="card-footer bg-white border-0" class="text-center" [innerHtml]="content.c5_text">
          </div>
        </div>
      </div>
      <div class="col-3 d-flex justify-content-start align-items-center">
        <div class="green-circle d-flex justify-content-center align-items-center" [ngClass]="{ 'gray-circle': isLast }" (click)="next()">
          <i class="fa fa-chevron-right text-white"></i>
        </div>
      </div>
    </div>
  </div>
</ng-container>