import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgwWowService } from 'ngx-wow';

import { Product } from '../types/product.type';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-harvx',
  templateUrl: './harvx.component.html',
  styleUrls: ['./harvx.component.css']
})

export class HarvxComponent implements OnInit {

  content: any = {};
  contentLoaded: boolean = false;
  @ViewChild('scrollHere') scrollHere: any;

  customOptions1: OwlOptions = {
    loop: true,
    margin: 10,
    // responsiveClass: true,
    navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
    dots: false,
    nav: true,
    autoplay:true,
    items: 4,
    responsive: {
      0: {
          items: 2,
      },
      479: {
          items: 4
      }
    }
  }

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    nav: true,
    margin: 20,
    navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
    //autoplay: 2500,
    //responsiveClass: true,
    items: 4,
    responsive: {
      0: {
          items: 2,
      },
      479: {
          items: 4
      }
    }
  }

  constructor(private ngxService: NgxUiLoaderService, private wowService: NgwWowService, private productService: ProductService, @Inject(DOCUMENT) private _doc: Document) { }

  ngOnInit(): void {
    this.wowService.init(); 
    this.ngxService.start('loading-harv-x');

    const product: Product = {
      route: 'harv-x',
      id: 0,
      data: null
    }

    this.productService.get(product)
    .toPromise()
    .then((data: any) => {
      this.content = data;
      this.contentLoaded = true;
      this.ngxService.stop('loading-harv-x');
    }, () => {
      this.ngxService.stop('loading-harv-x');
    });
  }

  scroll(){
    var element = this.scrollHere.nativeElement;
    const offset = 100;
    const bodyRect = this._doc.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    
    this._doc.defaultView.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
    
  }
}
