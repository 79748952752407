import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgwWowService } from "ngx-wow";
import { Router, ActivatedRoute } from "@angular/router";

import { Media } from "../types/media.type";
import { MediaService } from "../services/media.service";

@Component({
  selector: "app-news-details",
  templateUrl: "./news-details.component.html",
  styleUrls: ["./news-details.component.css"],
})
export class NewsDetailsComponent implements OnInit {
  content: any = {};
  contentLoaded: boolean = false;
  items: any = [];
  @ViewChild("scrollHere") scrollHere: any;
  selectedTab: "recent-news" | "press" = "recent-news";
  page: number = 1;

  constructor(
    private ngxService: NgxUiLoaderService,
    private wowService: NgwWowService,
    private mediaService: MediaService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private _doc: Document
  ) {}

  ngOnInit(): void {
    this.wowService.init();
    this.ngxService.start("loading-media");

    this.selectedTab = this.activatedRoute.snapshot.paramMap.get("id") as
      | "recent-news"
      | "press";

    const media: Media = {
      route: "overview",
      id: 0,
      data: null,
    };

    this.mediaService
      .get(media)
      .toPromise()
      .then(
        (data: any) => {
          this.content = data;
          this.items =
            this.selectedTab === "recent-news"
              ? this.content.recents
              : this.content.press;
          this.contentLoaded = true;
          this.ngxService.stop("loading-media");
        },
        () => {
          this.ngxService.stop("loading-media");
        }
      );
  }

  scroll() {
    let el = this.scrollHere.nativeElement;
    if (el != null) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  selectTab(tab) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate(["/archive/" + tab]));
  }

  pageChanged(event) {
    if (event) {
      this.page = event;
      this.scroll();
    }
  }

  linkTo(link) {
    this._doc.defaultView.open(link, "_blank");
  }
}
