import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgwWowService } from "ngx-wow";

import { Solution } from "../../../types/solution.type";
import { SolutionService } from "../../../services/solution.service";

@Component({
  selector: "app-solution-footer",
  templateUrl: "./solution-footer.component.html",
  styleUrls: ["./solution-footer.component.css"],
})
export class SolutionFooterComponent implements OnInit {
  content: any = {};
  contentLoaded: boolean = false;
  activeBox: number = -1;

  constructor(
    private ngxService: NgxUiLoaderService,
    private wowService: NgwWowService,
    private solutionService: SolutionService,
    @Inject(DOCUMENT) private _doc: Document
  ) {}

  ngOnInit(): void {
    this.wowService.init();
    this.ngxService.start("loading-solution-footer");

    const solution: Solution = {
      route: "footer",
      id: 0,
      data: null,
    };

    this.solutionService
      .get(solution)
      .toPromise()
      .then(
        (data: any) => {
          this.content = data;
          this.contentLoaded = true;
          this.ngxService.stop("loading-solution-footer");
        },
        () => {
          this.ngxService.stop("loading-solution-footer");
        }
      );
  }

  accord(active: number) {
    if (this.activeBox === active) {
      this.activeBox = -1;
    } else {
      this.activeBox = active;
    }
  }
}
