import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgwWowService } from "ngx-wow";

import { About } from "../types/about.type";
import { AboutService } from "../services/about.service";

@Component({
  selector: "app-team",
  templateUrl: "./team.component.html",
  styleUrls: ["./team.component.css"],
})
export class TeamComponent implements OnInit {
  content: any = {};
  contentLoaded: boolean = false;
  @ViewChild("scrollHere") scrollHere: any;

  public text: String;
  private wasInside = false;
  isFocusInsideComponent = false;
  isComponentClicked = false;
  selectedMember = -1;

  @HostListener("click")
  clickInside() {
    this.isFocusInsideComponent = true;
    this.isComponentClicked = true;
  }

  @HostListener("document:click")
  clickout() {
    if (!this.isFocusInsideComponent && this.isComponentClicked) {
      // do the heavy process
      this.selectedMember = -1;
      this.isComponentClicked = false;
    }

    this.isFocusInsideComponent = false;
  }

  constructor(
    private ngxService: NgxUiLoaderService,
    private wowService: NgwWowService,
    private aboutService: AboutService
  ) {}

  ngOnInit(): void {
    this.wowService.init();
    this.ngxService.start("loading-team");

    const about: About = {
      route: "team",
      id: 0,
      data: null,
    };

    this.aboutService
      .get(about)
      .toPromise()
      .then(
        (data: any) => {
          this.content = data;

          about.route = "member";
          this.aboutService
            .get(about)
            .toPromise()
            .then(
              (data: any) => {
                this.content.members = data;
                this.contentLoaded = true;
                this.ngxService.stop("loading-team");
              },
              () => {
                this.ngxService.stop("loading-team");
              }
            );
        },
        () => {
          this.ngxService.stop("loading-team");
        }
      );
  }

  team(index) {
    this.selectedMember = index;
  }

  close() {
    this.selectedMember = -1;
  }

  scroll() {
    this.scrollHere.nativeElement.scrollIntoView({ behavior: "smooth" });
  }
}
