<ng-container *ngIf="contentLoaded">
    <!-- banner starts  -->
    <section
        class="banner banner-trumodity banner-2 bg-transparent"
        [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
    >
        <div class="container-inner">
            <div class="banner-content">
                <img class="title-image img-fluid" src="{{ content.b1_image }}" alt="">
                <p class="text-white wow animate__animated animate__fadeInUp animate__delay0-5s">
                   {{ content.a1_text }}
               </p>
               <app-book-ademo></app-book-ademo>
            </div>
        </div>
       <!-- scroll addded -->
       <div class="btn-scroll-wrapper" (click) = "scroll()">
            <button class="btn-scroll wow animate__animated animate__fadeInUp animate__delay-1s">
                <span class="fa fa-angle-down"></span>
            </button>
        </div>
        <!-- scroll addded -->
    </section>
    <!-- banner starts  -->

    <app-product-timeline [selected]="'trumodity'"></app-product-timeline>

    <!-- about trumodity -->
    <section #scrollHere class="what-is-trumodity">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mobile-hide">
                    <img src="{{ content.c2_image }}" alt=""
                        class="display-img img-fluid mt-0 wow animate__animated animate__fadeInLeft">
                </div>
                <div class="col-md-6">
                    <div class="about-trumodity">
                        <img src="{{ content.c1_image }}" alt=""
                            class="title-img img-fluid   wow animate__animated animate__fadeInUp animate__delay0-5s">
                        <p class="wow animate__animated animate__fadeInUp animate__delay0-5s">
                            {{ content.b1_text }}
                        </p>
                    </div>
                </div>
                <div class="col-md-6 desktop-hide">
                    <img src="{{ content.c2_image }}" alt=""
                        class="display-img img-fluid mt-3 wow animate__animated animate__fadeInLeft">
                </div>
            </div>
        </div>
    </section>

    <!-- about trumodity -->
    <div class="mobile-app-features">
        <div class="container">
            <div class="row">
                <div class="col-md-5 mobile-hide">
                    <div class="mobile-app-content">
                        <h2
                            class="title orange bottom text-left font-weight-bold wow animate__animated animate__fadeInUp">
                            <span>{{ content.c1_text }}</span>
                        </h2>
                        <ul class="features-list">
                            <li class="wow animate__animated animate__fadeInUp animate__delay0-5s">
                                {{ content.d1_text }}
                            </li>
                            <li class="wow animate__animated animate__fadeInUp animate__delay0-5s">
                                {{ content.d2_text }}
                            </li>
                            <li class="wow animate__animated animate__fadeInUp animate__delay0-5s">
                                {{ content.d3_text }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-7">
                    <h2
                        class="title orange bottom text-left font-weight-bold desktop-hide wow animate__animated animate__fadeInUp">
                        <span>{{ content.c1_text }}</span></h2>
                   
                    <div class="mobile-app-features-right text-center">
                        <img src="{{ content.d1_image }}" alt="" class="img-fluid feature-img">
                        <ul class="mobile-app-features-text-slider  desktop-hide">
                            <owl-carousel-o [options]="customOptions1">
                                <ng-template carouselSlide [id]="1">
                                    <li>{{ content.d1_text }}</li>
                                </ng-template>
                                <ng-template carouselSlide [id]="1">
                                    <li>{{ content.d2_text }}</li>
                                </ng-template>
                                <ng-template carouselSlide [id]="1">
                                    <li>{{ content.d3_text }}</li>
                                </ng-template>
                            </owl-carousel-o>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- shape img -->
    <img class="w-100 img-fluid mobile-hide" src="assets/images/trumodity/bg-shape-1.webp" alt="">
    <!-- product-details -->
    <div class="img-wrapper-shape bg-white">
        <img class="w-100 img-fluid desktop-hide" src="assets/images/buyers/gray-shape-3.webp" alt="">
    </div>

    <div class="product-details product-details-2 trumodity-product-details">
        <div class="title-image-wrapper">
            <img src="{{ content.e1_image }}" alt="">
        </div>
        <h2 class="title orange small bottom bottom-center font-weight-bold   wow animate__animated animate__fadeInUp">
            <span>{{ content.e1_text }}</span>
        </h2>

        <app-product-footer [hideProduct]="'trumodity'"></app-product-footer>
    </div>
    <!-- shape -->
    <div class="bottom-img-shape">
        <img src="assets/images/buyers/buyers-shape-bg-2.webp" class="img-fluid desktop-hide w-100" alt="">
    </div>
    <!-- shape -->

    <!-- home contact -->
</ng-container>