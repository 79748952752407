
    <div class="sidebars" id="contain" style="height:100%">
                <nav class="sidebar-nav">
                  <ul class="nav" style="    padding-top: 54px;">
                   
                    <li class="nav-item" (click) = "redirectUrl('users')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'users'}">
                        <i class="nav-icon icon-user"></i> Admin Users</a>
                    </li>
                     
                    <li class="nav-item" (click) = "redirectUrl('admin-home')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-home'}">
                        <i class="nav-icon icon-home"></i>Homepage</a>
                    </li>
                    <li class="nav-item"  (click) = "redirectUrl('social-links')" style="cursor: pointer;">
                      <a class="nav-link"  [ngClass]="{'adminActive':selectedPage == 'social-links'}">
                        <i class="nav-icon icon-home"></i>Social Links</a>
                    </li>

                    <li class="nav-item" (click) = "redirectUrl('admin-products')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-products'}">
                        <i class="nav-icon icon-layers"></i>Products Overview</a>
                    </li>
                    <li class="nav-item" (click) = "redirectUrl('admin-trumodity')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-trumodity'}">
                        <i class="nav-icon icon-layers"></i>Trumodity</a>
                    </li>
                    <li class="nav-item" (click) = "redirectUrl('admin-seedaudit')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-seedaudit'}">
                        <i class="nav-icon icon-layers"></i>Seed Audit</a>
                    </li>
                    <li class="nav-item" (click) = "redirectUrl('admin-silosys')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-silosys'}">
                        <i class="nav-icon icon-layers"></i>Silosys</a>
                    </li>
                    <li class="nav-item" (click) = "redirectUrl('admin-silosys-transformation')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-silosys-transformation'}">
                        <i class="nav-icon icon-layers"></i>Silosys Transformation</a>
                    </li>
                    <li class="nav-item" (click) = "redirectUrl('admin-harvx')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-harvx'}">
                        <i class="nav-icon icon-layers"></i>Harvx</a>
                    </li>
                    <li class="nav-item" (click) = "redirectUrl('admin-products-footer')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-products-footer'}">
                        <i class="nav-icon icon-layers"></i>Products Shortcut</a>
                    </li>
                    <li class="nav-item" (click) = "redirectUrl('admin-products-timeline')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-products-timeline'}">
                        <i class="nav-icon icon-layers"></i>Products Timeline</a>
                    </li>

                    <li class="nav-item" (click) = "redirectUrl('admin-solutions')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-solutions'}">
                        <i class="nav-icon icon-direction"></i>Solutions Overview</a>
                    </li>
                    <li class="nav-item" (click) = "redirectUrl('admin-producers')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-producers'}">
                        <i class="nav-icon icon-direction"></i>Producers</a>
                    </li>
                    <li class="nav-item" (click) = "redirectUrl('admin-storage')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-storage'}">
                        <i class="nav-icon icon-direction"></i>Storage</a>
                    </li>
                    <li class="nav-item" (click) = "redirectUrl('admin-buyers')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-buyers'}">
                        <i class="nav-icon icon-direction"></i>Buyers</a>
                    </li>
                    <li class="nav-item" (click) = "redirectUrl('admin-blockchain')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-blockchain'}">
                        <i class="nav-icon icon-direction"></i>Blockchain</a>
                    </li>
                    <li class="nav-item" (click) = "redirectUrl('admin-solutions-footer')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-solutions-footer'}">
                        <i class="nav-icon icon-direction"></i>Solutions Shortcut</a>
                    </li>

                    <li class="nav-item" (click) = "redirectUrl('admin-news')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-news'}">
                        <i class="nav-icon icon-note"></i>Media</a>
                    </li>

                    <li class="nav-item" (click) = "redirectUrl('about-grain')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'about-grain'}">
                        <i class="nav-icon icon-list"></i>Team</a>
                    </li>
                    <li class="nav-item" (click) = "redirectUrl('admin-carrers')" style="cursor: pointer;">
                      <a class="nav-link "  [ngClass]="{'adminActive':selectedPage == 'admin-carrers'}">
                        <i class="nav-icon icon-list"></i>Careers</a>
                    </li>                    
                    
                    <li class="nav-item"  (click) = "redirectUrl('privacy')" style="cursor: pointer;">
                      <a class="nav-link"  [ngClass]="{'adminActive':selectedPage == 'privacy'}">
                        <i class="nav-icon icon-folder"></i> Privacy Policy</a>
                    </li>
                    <li class="nav-item"  (click) = "redirectUrl('terms')" style="cursor: pointer;">
                      <a class="nav-link"  [ngClass]="{'adminActive':selectedPage == 'terms'}">
                        <i class="nav-icon icon-folder"></i> T & C</a>
                    </li>

                    <li class="nav-item"  (click) = "redirectUrl('languages')" style="cursor: pointer;">
                      <a class="nav-link"  [ngClass]="{'adminActive':selectedPage == 'terms'}">
                        <i class="nav-icon icon-folder"></i> Languages</a>
                    </li>
                    <!-- <li class="nav-item"  (click) = "redirectUrl('quotes')" style="cursor: pointer;">
                      <a class="nav-link"  [ngClass]="{'adminActive':selectedPage == 'quotes'}">
                        <i class="nav-icon icon-note"></i> Quotes</a>
                    </li>
                    <li class="nav-item"  (click) = "redirectUrl('categories')" style="cursor: pointer;">
                        <a class="nav-link" [ngClass]="{'adminActive':selectedPage == 'categories'}">
                          <i class="nav-icon icon-grid"></i> Categories</a>
                                    </li>
                          <li class="nav-item"  (click) = "redirectUrl('postal-codes')" style="cursor: pointer;">
                            <a class="nav-link" [ngClass]="{'adminActive':selectedPage == 'postal-codes'}">
                              <i class="nav-icon icon-grid"></i> Postal Codes</a>
                          </li>
                         
                      <li class="nav-item"  (click) = "redirectUrl('support-fee')" style="cursor: pointer;">
                        <a class="nav-link" [ngClass]="{'adminActive':selectedPage == 'support-fee'}">
                          <i class="nav-icon icon-grid"></i> Support Fee</a>
                      </li>
                     
                        <li class="nav-item"  (click) = "redirectUrl('cms')" style="cursor: pointer;">
                          <a class="nav-link"  [ngClass]="{'adminActive':selectedPage == 'cms'}">
                            <i class="nav-icon icon-people"></i> CMS</a>
                        </li>
                       
                       
                        <li class="nav-item"  (click) = "redirectUrl('about')" style="cursor: pointer;">
                          <a class="nav-link"  [ngClass]="{'adminActive':selectedPage == 'about'}">
                            <i class="nav-icon icon-share-alt"></i> About Us</a>
                        </li>
                        <li class="nav-item"  (click) = "redirectUrl('how-it-works')" style="cursor: pointer;">
                          <a class="nav-link"  [ngClass]="{'adminActive':selectedPage == 'how-it-works'}">
                            <i class="nav-icon icon-share-alt"></i> How it works</a>
                        </li>
                        <li class="nav-item"  (click) = "redirectUrl('faqs')" style="cursor: pointer;">
                          <a class="nav-link"  [ngClass]="{'adminActive':selectedPage == 'faqs'}">
                            <i class="nav-icon icon-share-alt"></i> Faqs</a>
                        </li>
                        <li class="nav-item"  (click) = "redirectUrl('learn-more')" style="cursor: pointer;">
                          <a class="nav-link"  [ngClass]="{'adminActive':selectedPage == 'learn-more'}">
                            <i class="nav-icon icon-share-alt"></i> Learn More</a>
                        </li> -->
                  </ul>
                </nav>
               
              </div>

         