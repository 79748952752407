<ng-container *ngIf="contentLoaded">
    <!-- banner -->
    <section
        class="banner banner-blockchain banner-2 bg-white"
        [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
    >
        <div class="container-inner">
            <div class="banner-content">
                <h1
                    class="
                        title
                        text-white
                        wow
                        animate__animated animate__fadeInUp
                    "
                >
                    <span>{{ content.a1_text }}</span>
                </h1>
                <p
                    class="
                        text-white
                        wow
                        animate__animated animate__fadeInUp animate__delay0-5s
                    "
                >
                    {{ content.b1_text }}
                </p>
                <app-book-ademo></app-book-ademo>
            </div>
        </div>
        <!-- scroll addded -->
        <div class="btn-scroll-wrapper" (click)="scroll()">
            <button
                class="
                    btn-scroll
                    wow
                    animate__animated animate__fadeInUp animate__delay-1s
                "
            >
                <span class="fa fa-angle-down"></span>
            </button>
        </div>
        <!-- scroll addded -->
    </section>
    <!-- banner -->

    <!-- why blockchain -->
    <section #scrollHere class="why-blockchain">
        <div class="why-blockchain-content">
            <h2
                class="
                    title
                    text-left
                    font-weight-bold
                    bottom
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <span>{{ content.c1_text }}</span>
            </h2>
            <p
                class="
                    wow
                    animate__animated animate__fadeInUp animate__delay0-5s
                "
            >
                {{ content.d1_text }}
            </p>
        </div>
        <div class="why-blockchain-img animate__animated animate__fadeInUp">
            <img class="img-fluid" src="{{ content.b1_image }}" alt="" />
        </div>
    </section>
    <!-- why blockchain -->

    <!-- mobile shape -->
    <img
        class="img-fluid desktop-hide"
        src="assets/images/block-chain/bg-shape-1.webp"
        alt=""
    />
    <!-- mobile shape -->

    <!-- blockchain contracts -->
    <section class="blockchain-contracts">
        <div
            class="
                blockchain-contracts-img
                wow
                animate__animated animate__fadeInLeft
                mobile-hide
            "
        >
            <img class="img-fluid" src="{{ content.c1_image }}" alt="" />
        </div>
        <div class="blockchain-contracts-content">
            <h2
                class="
                    title
                    text-left
                    font-weight-bold
                    bottom
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <span>{{ content.e1_text }}</span>
            </h2>
            <p
                class="
                    wow
                    animate__animated animate__fadeInUp animate__delay0-5s
                "
            >
                {{ content.f1_text }}
            </p>
        </div>
    </section>

    <!-- blockchain contracts -->

    <!-- blockchain technology -->
    <section class="blockchain-technology">
        <div class="container-inner">
            <h2
                class="
                    title
                    text-left
                    orange
                    font-weight-bold
                    bottom
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <span>{{ content.g1_text }}</span>
            </h2>

            <div
                class="
                    blockchain-technology-flow
                    mobile-hide
                    wow
                    animate__animated animate__fadeInUp animate__delay0-5s
                "
            >
                <img
                    class="img-fluid w-100"
                    src="{{ content.d1_image }}"
                    alt=""
                />
            </div>
            <!-- mobile flow -->
            <div class="blockchain-technology-flow-mobile desktop-hide">
                <owl-carousel-o [options]="customOptions">
                    <ng-template
                        carouselSlide
                        [id]="n"
                        *ngFor="let n of [1, 2, 3, 4, 5, 6]"
                    >
                        <div class="flow-item">
                            <img
                                class="img-fluid"
                                src="{{ content['e' + n + '_image'] }}"
                                alt=""
                            />
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </section>

    <!-- blockchain technology -->

    <!-- mobile shape -->
    <img
        class="img-fluid desktop-hide"
        src="assets/images/block-chain/bg-shape-2.webp"
        alt=""
    />
    <!-- mobile shape -->

    <!-- mobile shape green -->
    <img
        class="img-fluid desktop-hide green-top"
        src="assets/images/block-chain/bg-shape-3.webp"
        alt=""
    />
    <!-- mobile shape green -->

    <!-- blockchain benefits -->
    <section class="blockchain-benefits">
        <div class="container-inner">
            <h2
                class="
                    title
                    text-white text-left
                    orange
                    font-weight-bold
                    bottom
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <span>{{ content.h1_text }}</span>
            </h2>

            <ul class="blockchain-benefits-list mobile-hide pb-5">
                <li
                    class="
                        wow
                        animate__animated animate__fadeInUp animate__delay0-5s
                    "
                    *ngFor="let n of [1, 2, 3, 4, 5, 6]"
                >
                    <div class="img-wrapper">
                        <img
                            class="img-fluid"
                            src="{{ content['f' + n + '_image'] }}"
                            alt=""
                        />
                    </div>
                    <div
                        class="text-white"
                        [innerHtml]="content['i' + n + '_text']"
                    ></div>
                </li>
            </ul>

            <ul
                class="
                    blockchain-benefits-list blockchain-benefits-list-mobile
                    desktop-hide
                "
                style="display: block"
            >
                <owl-carousel-o [options]="customOptions1">
                    <ng-template
                        carouselSlide
                        [id]="n"
                        *ngFor="let n of [1, 2, 3, 4, 5, 6]"
                    >
                        <li>
                            <div class="img-wrapper">
                                <img
                                    class="img-fluid"
                                    src="{{ content['f' + n + '_image'] }}"
                                    alt=""
                                />
                            </div>
                            <div
                                class="text-white"
                                [innerHtml]="content['i' + n + '_text']"
                            ></div>
                        </li>
                    </ng-template>
                </owl-carousel-o>
            </ul>
        </div>
    </section>
    <!-- blockchain benefits -->

    <!-- mobile shape green -->
    <img
        class="img-fluid desktop-hide green-bottom"
        src="assets/images/block-chain/bg-shape-4.webp"
        alt=""
    />
    <!-- mobile shape green -->
</ng-container>
