<!-- banner -->
<section class="banner banner-career  banner-2">
    <div class="container-inner">
        <div class="banner-content">
            <h1 class="title text-white  wow animate__animated animate__fadeInUp"><span>Careers</span></h1>

        </div>
    </div>
     <!-- scroll addded -->
     <div class="btn-scroll-wrapper" (click) = "scroll()">
        <button class="btn-scroll wow animate__animated animate__fadeInUp animate__delay-1s"><span
                class="fa fa-angle-down"></span></button>
    </div>
     <!-- scroll addded -->
</section>
<!-- banner ends -->

<!-- innovative-team -->
<section #scrollHere class="career-innovative-team">

    <div class="img-holder mobile-hide">
        <img class="img-fluid" src="assets/images/careers/display-img.webp" alt="">
    </div>
    <div class="innovative-team-content" *ngIf="pgContent">
        <h2 class="title text-left orange bottom font-weight-bold wow animate__animated animate__fadeInUp">
            <span>{{pgContent.h2text}}</span></h2>
        <p class="wow animate__animated animate__fadeInUp animate__delay0-5s">{{pgContent.h3text}}</p>
        <div class="learn-more-tab-wrapper">
            <p class="wow animate__animated animate__fadeInUp animate__delay0-5s">Learn more about</p>
            <ul>
                <li class="wow animate__animated animate__fadeInRight animate__delay0-5s"><a routerLink="/team">Our
                        Team</a></li>
                <li class="wow animate__animated animate__fadeInRight animate__delay0-5s"><a
                    routerLink="/products">Our Products</a></li>
                <li class="wow animate__animated animate__fadeInRight animate__delay0-5s"><a
                    routerLink="/solutions">Our Solutions</a></li>
            </ul>
        </div>
    </div>

</section>

<img class="img-fluid desktop-hide" src="assets/images/products/bg-pattern-4.webp" alt="">
<!-- innovative-team -->
<section class="current-openings">
    <div class="container-inner">
        <h2
            class="title bottom orange bottom-center font-weight-bold wow animate__animated animate__fadeInUp animate__delay0-5s">
            <span>Current Openings</span></h2>
        <div class="openings-list">
            <div class="row">
                <div class="col-md-4 col-sm-6" *ngFor="let x of imgs | slice:0:6;">
                    <div style="cursor:pointer" (click)="openLink(x.link)" class="opening-box wow animate__animated animate__fadeInUp animate__delay0-5s">
                        <div class="opening-icon">
                            <img src="{{x.image}}" alt="">
                        </div>
                        <div class="opening-content">
                            <p class="job-title">{{x.type}}</p>
                            <p class="job-location">{{x.title}}</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4 col-sm-6">
                    <div class="opening-box wow animate__animated animate__fadeInUp animate__delay0-5s">
                        <div class="opening-icon">
                            <img src="assets/images/careers/career-icon-2.webp" alt="">
                        </div>
                        <div class="opening-content">
                            <p class="job-title">iOS Developer,</p>
                            <p class="job-location">Guadalajara</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="opening-box wow animate__animated animate__fadeInUp animate__delay0-5s">
                        <div class="opening-icon">
                            <img src="assets/images/careers/career-icon-3.webp" alt="">
                        </div>
                        <div class="opening-content">
                            <p class="job-title">Project Manager,</p>
                            <p class="job-location">Honduras</p>
                        </div>
                    </div>
                </div>


                <div class="col-md-4 col-sm-6">
                    <div class="opening-box wow animate__animated animate__fadeInUp animate__delay0-5s">
                        <div class="opening-icon">
                            <img src="assets/images/careers/career-icon-4.webp" alt="">
                        </div>
                        <div class="opening-content">
                            <p class="job-title">Marketing Associate, </p>
                            <p class="job-location">McAllen</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="opening-box wow animate__animated animate__fadeInUp animate__delay0-5s">
                        <div class="opening-icon">
                            <img src="assets/images/careers/career-icon-5.webp" alt="">
                        </div>
                        <div class="opening-content">
                            <p class="job-title">Customer Service Specialist,</p>
                            <p class="job-location">McAllen</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="opening-box wow animate__animated animate__fadeInUp animate__delay0-5s">
                        <div class="opening-icon">
                            <img src="assets/images/careers/career-icon-3.webp" alt="">
                        </div>
                        <div class="opening-content">
                            <p class="job-title">Project Manager,</p>
                            <p class="job-location">Miami</p>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- <button class="btn btn-primary btn-view-more ">View More</button> -->
        </div>
        </div>
</section>

<!-- pattern img -->
<img class="img-fluid desktop-hide  wow animate__animated animate__fadeInUp animate__delay0-5s"
    src="assets/images/products/bg-pattern-3.webp" alt="">

