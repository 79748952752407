<ng-container *ngIf="contentLoaded">
  <!-- banner starts  -->
  <section
    class="banner banner-sylosis banner-2 bg-transparent"
    [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
  >
    <div class="container-inner">
      <div class="banner-content">
        <img
          class="title-image img-fluid wow animate__animated animate__fadeInUp"
          src="{{ content.b1_image }}"
          alt=""
        />
        <p class="text-white wow animate__animated animate__fadeInUp animate__delay0-5s">
          {{ content.a1_text }}
        </p>
        <app-book-ademo></app-book-ademo>
      </div>
    </div>
    <!-- scroll addded -->
    <div class="btn-scroll-wrapper" (click)="scroll()">
      <button
        class="btn-scroll wow animate__animated animate__fadeInUp animate__delay-1s"
      >
        <span class="fa fa-angle-down"></span>
      </button>
    </div>
    <!-- scroll addded -->
  </section>
  <!-- banner starts  -->

  <app-product-timeline [selected]="'silosys-transformation'"></app-product-timeline>

  <div #scrollHere class="what-is-silosys-transformation container">
    <div class="row">
      <div class="col-md-6">
        <div class="silosys-transformation-content">
          <img
            src="{{ content.c1_image }}"
            class="section-title img-fluid wow animate__animated animate__fadeInUp"
            alt=""
          />
          <p class="wow animate__animated animate__fadeInUp animate__delay0-5s">
            {{ content.b1_text }}
          </p>
        </div>
      </div>
      <div class="col-md-6 text-center">
        <img
          style="height: 60%;"
          src="{{ content.c2_image }}"
          alt=""
          class="img-fluid wow animate__animated animate__fadeInRight animate__delay0-5s display-img"
        />
      </div>
    </div>
  </div>

  <!-- silosys transformation benefits -->
  <div class="silosys-transformation-benefits" >
    <div class="container">
      <h2
        class="title text-center bottom font-weight-bold wow animate__animated animate__fadeInUp"
      >
        <span>{{ content.c1_text }}</span>
      </h2>
    </div>
    <div class="container px-3">
      <div class="row">
        <div class="col-12 col-md text-center">
          <img
            src="{{ content.e1_image }}"
            alt=""
            class="img-fluid wow animate__animated animate__fadeInUp"
          />
          <p class="my-3">{{ content.d1_text }}</p>
        </div>
        <div class="col-12 col-md text-center">
          <img
            src="{{ content.e2_image }}"
            alt=""
            class="img-fluid wow animate__animated animate__fadeInUp"
          />
          <p class="my-3">{{ content.d2_text }}</p>
        </div>
        <div class="col-12 col-md text-center">
          <img
            src="{{ content.e3_image }}"
            alt=""
            class="img-fluid wow animate__animated animate__fadeInUp"
          />
          <p class="my-3">{{ content.d3_text }}</p>
        </div>
        <div class="col-12 col-md text-center">
          <img
            src="{{ content.e4_image }}"
            alt=""
            class="img-fluid wow animate__animated animate__fadeInUp"
          />
          <p class="my-3">{{ content.d4_text }}</p>
        </div>
        <div class="col-12 col-md text-center">
          <img
            src="{{ content.e5_image }}"
            alt=""
            class="img-fluid wow animate__animated animate__fadeInUp"
          />
          <p class="my-3">{{ content.d5_text }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- silosys transformation benefits -->

  <!-- transformation process examples -->
  <div class="silosys-transformation-process-examples" >
    <div class="container">
      <h2 class="title text-center bottom font-weight-bold wow animate__animated animate__fadeInUp">
        <span>{{ content.f1_text }}</span>
      </h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md d-none d-md-flex"></div>
        <div class="col-4 col-md text-center">
          <div
            class="silosys-transformation-process-circle mx-auto d-flex align-items-center justify-content-center"
            [ngClass]="{ 'silosys-transformation-process-circle-active': selectedExample === 'wheat' }"
            (click)="selectExample('wheat')"
          >
            <svg-icon
              class="d-none d-md-flex"
              src="assets/images/silosys-transformation/SST-wheat-icon.svg"
              [svgStyle]="{ 'width.px':130, 'fill': selectedExample === 'wheat' ? '#3ea949' : '#3f20a0' }"
              [svgClass]="'silosys-transformation-process-icon'"
            ></svg-icon>
            <svg-icon
              class="d-flex d-md-none"
              src="assets/images/silosys-transformation/SST-wheat-icon.svg"
              [svgStyle]="{ 'width.px':100, 'fill': selectedExample === 'wheat' ? '#3ea949' : '#3f20a0' }"
              [svgClass]="'silosys-transformation-process-icon'"
            ></svg-icon>
          </div>
          <h3 class="title font-weight-bold my-3">{{ content.g1_text }}</h3>
        </div>
        <div class="col-4 col-md text-center">
          <div
            class="silosys-transformation-process-circle mx-auto d-flex align-items-center justify-content-center"
            [ngClass]="{ 'silosys-transformation-process-circle-active': selectedExample === 'palm-oil' }"
            (click)="selectExample('palm-oil')"
          >
            <svg-icon
              class="d-none d-md-flex"
              src="assets/images/silosys-transformation/SST-Palm-icon.svg"
              [svgStyle]="{ 'width.px':130 , 'fill': selectedExample === 'palm-oil' ? '#3ea949' : '#3f20a0' }"
              [svgClass]="'silosys-transformation-process-icon'"
            ></svg-icon>
            <svg-icon
              class="d-flex d-md-none"
              src="assets/images/silosys-transformation/SST-Palm-icon.svg"
              [svgStyle]="{ 'width.px':100 , 'fill': selectedExample === 'palm-oil' ? '#3ea949' : '#3f20a0' }"
              [svgClass]="'silosys-transformation-process-icon'"
            ></svg-icon>
          </div>
          <h3 class="title font-weight-bold my-3">{{ content.g2_text }}</h3>
        </div>
        <div class="col-4 col-md text-center">
          <div
            class="silosys-transformation-process-circle mx-auto d-flex align-items-center justify-content-center"
            [ngClass]="{ 'silosys-transformation-process-circle-active': selectedExample === 'coffee' }"
            (click)="selectExample('coffee')"
          >
            <svg-icon
              class="d-none d-md-flex"
              src="assets/images/silosys-transformation/SST-coffee-icon_1.svg"
              [svgStyle]="{ 'width.px':130, 'fill': selectedExample === 'coffee' ? '#3ea949' : '#3f20a0' }"
              [svgClass]="'silosys-transformation-process-icon'"
            ></svg-icon>
            <svg-icon
              class="d-flex d-md-none"
              src="assets/images/silosys-transformation/SST-coffee-icon_1.svg"
              [svgStyle]="{ 'width.px':100, 'fill': selectedExample === 'coffee' ? '#3ea949' : '#3f20a0' }"
              [svgClass]="'silosys-transformation-process-icon'"
            ></svg-icon>
          </div>
          <h3 class="title font-weight-bold my-3">{{ content.g3_text }}</h3>
        </div>
        <div class="col-md d-none d-md-flex"></div>
      </div>
    </div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <p class="text-center">{{ getExampleDescription() }}</p>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-3 mb-md-5">
      <div class="row">
        <div class="col text-center">
          <img
            src="{{ getExampleImage() }}"
            alt=""
            class="img-fluid mt-2 wow animate__animated animate__fadeInUp"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- transformation process examples -->

  <div class="silosys-transformation-sap container">
    <div class="row">
      <div class="col-md-6 text-center">
        <img
          src="{{ content.g1_image }}"
          alt=""
          class="img-fluid wow animate__animated animate__fadeInRight animate__delay0-5s display-img"
        />
      </div>
      <div class="col-md-6 pt-md-5 mt-md-5 mb-5">
        <h2
          class="title bottom text-left font-weight-bold wow animate__animated animate__fadeInUp mt-md-5 pt-md-5"
        >
          <span>{{ content.i1_text }}</span>
        </h2>
        <p
          class="wow animate__animated animate__fadeInUp animate__delay0-5s"
          style="line-height: 30px; font-size: 1.125rem; font-weight: 400;"
        >
          {{ content.j1_text }}
        </p>
        <img
          src="{{ content.h1_image }}"
          class="section-title img-fluid wow animate__animated animate__fadeInUp mt-4"
          alt=""
          style="cursor: pointer;"
          (click)="redirectTo(content.a1_link)"
          width="250"
        />
      </div>
    </div>
  </div>

  <div class="product-details product-details-2 harvx-product-details mt-md-5 mb-5">
      <img class="w-100 img-fluid mobile-hide shape-1" src="assets/images/buyers/gray-shape-3.webp" alt="">

      <div class="title-image-wrapper title-image-wrapper-2 wow animate__animated animate__fadeInUp mt-n5">
          <img src="{{ content.i1_image }}" alt="">
      </div>
      <h2 class="title orange small bottom bottom-center font-weight-bold   wow animate__animated animate__fadeInUp">
          <span>{{ content.k1_text }}</span>
      </h2>

      <app-product-footer [hideProduct]="'silosys-transformation'"></app-product-footer>
  </div>
</ng-container>