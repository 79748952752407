import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { NgwWowService } from "ngx-wow";

import { Home } from "../types/home.type";
import { HomeService } from "../services/home.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  url: any; // Not in use ...

  content: any = {};
  contentLoaded: boolean = false;

  constructor(
    private router: Router,
    private wowService: NgwWowService,
    private homeService: HomeService
  ) {}

  ngOnInit(): void {
    this.url = this.router.url;
    this.wowService.init();

    const home: Home = {
      route: "social",
      id: 0,
      data: null,
    };

    this.homeService
      .get(home)
      .toPromise()
      .then(
        (data: any) => {
          this.content = data;
          this.contentLoaded = true;
        },
        () => {
          //
        }
      );
  }

  redirect(type) {
    this.router.navigateByUrl(type);
  }

  get currentYear() {
    return new Date().getFullYear();
  }
}
