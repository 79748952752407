import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { SiteLayoutComponent } from "./site-layout/site-layout.component";
import { AdminLayoutComponent } from "./admin/admin-layout/admin-layout.component";

import { HomeComponent } from "./home/home.component";
import { AdminLoginComponent } from "./admin/admin-login/admin-login.component";

import { ProductsComponent } from "./products/products.component";
import { SolutionsComponent } from "./solutions/solutions.component";
import { TeamComponent } from "./team/team.component";
import { MediaComponent } from "./media/media.component";
import { BlockchainComponent } from "./blockchain/blockchain.component";
import { StorageComponent } from "./storage/storage.component";
import { HarvxComponent } from "./harvx/harvx.component";
import { SeedAuditComponent } from "./seed-audit/seed-audit.component";
import { TrumoditiyComponent } from "./trumoditiy/trumoditiy.component";
import { SylosysComponent } from "./sylosys/sylosys.component";
import { SilosysTransformationComponent } from "./silosys-transformation/silosys-transformation.component";
import { ProducerComponent } from "./producer/producer.component";
import { BuyersComponent } from "./buyers/buyers.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { CarriersComponent } from "./carriers/carriers.component";
import { NewsDetailsComponent } from "./news-details/news-details.component";
import { JobDetailsComponent } from "./job-details/job-details.component";
import { TermsServicesComponent } from "./terms-services/terms-services.component";
import { CommodityClassic2022Component } from "./commodity-classic2022/commodity-classic2022.component";

const routes: Routes = [
  {
    path: "",
    component: SiteLayoutComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
        data: {
          title: "GrainChain",
          ogImage: "GrainChain-Home.webp",
          description:
            "GrainChain's platform solves problems for all participants along the global agricultural supply chain, providing traceability, transparency, accountability and efficiency.",
        },
      },
      {
        path: "products",
        component: ProductsComponent,
        data: {
          title: "Products Overview",
          ogImage: "GrainChain-Products.webp",
          description:
            "Learn more about how GrainChain’s innovative suite of products work together to solve the biggest problems facing the global commodity trade and supply chain.",
        },
      },
      {
        path: "carrers/all",
        component: JobDetailsComponent,
        data: {
          title: "Careers",
          ogImage: "GrainChain-Careers.webp",
        },
      },
      {
        path: "solutions",
        component: SolutionsComponent,
        data: {
          title: "Solutions Overview",
          ogImage: "GrainChain-Solutions.webp",
          description:
            "GrainChain provides solutions for all participants along the supply chain, from farmers and producers to storage operators, buyers and more.",
        },
      },
      {
        path: "archive/:id",
        component: NewsDetailsComponent,
        data: {
          title: "Archive",
          ogImage: "GrainChain-Media.webp",
        },
      },
      {
        path: "team",
        component: TeamComponent,
        data: {
          title: "Our Team",
          ogImage: "GrainChain-OurTeam.webp",
          description:
            "GrainChain's team brings a wealth of expertise in agriculture, business, software development and blockchain technology together to build proven, world-class, innovative solutions to the agricultural commodity supply chain.",
        },
      },
      {
        path: "media",
        component: MediaComponent,
        data: {
          title: "Media",
          ogImage: "GrainChain-Media.webp",
          description:
            "Find the latest news and press releases from GrainChain, plus our media kit and more.",
        },
      },
      {
        path: "trumodity",
        component: TrumoditiyComponent,
        data: {
          title: "Trumodity",
          ogImage: "GrainChain-Trumodity.webp",
          description:
            "Trumodity is GrainChain's trusted transaction platform for commodities. Trumodity facilitates prompt payment to farmers and suppliers and the immediate availability of tradable commodities to buyers.",
        },
      },
      {
        path: "seed-audit",
        component: SeedAuditComponent,
        data: {
          title: "Seed Audit",
          ogImage: "GrainChain-SeedAudit.webp",
          description:
            "Seed Audit brings the efficiency, transparency and reliability of GrainChain's postharvest solutions to the preharvest process, securely tracking data and allowing for third-party audits.",
        },
      },
      {
        path: "silosys",
        component: SylosysComponent,
        data: {
          title: "Silosys",
          ogImage: "GrainChain-SiloSys.webp",
          description:
            "SiloSys is GrainChain's smart inventory management system for producers and storage operators.",
        },
      },
      {
        path: "silosys-transformation",
        component: SilosysTransformationComponent,
        data: {
          title: "Silosys Transformation",
          ogImage: "GrainChain-SiloSys-Transformation.webp",
          description:
            "Transformation is an added feature for our SiloSys inventory management solution for commodities that require complex processing like coffee, wheat and palm oil.",
        },
      },
      {
        path: "careers",
        component: CarriersComponent,
        data: {
          title: "Careers",
          ogImage: "GrainChain-Careers.webp",
          description:
            "Join the GrainChain team, find current job openings and apply online.",
        },
      },
      {
        path: "harvx",
        component: HarvxComponent,
        data: {
          title: "HarvX",
          ogImage: "GrainChain-HarvX.webp",
          description:
            "HarvX is GrainChain's logistics management solution that connects producers and drivers directly and connects data seamlessly with storage operators.",
        },
      },
      {
        path: "storage-operators",
        component: StorageComponent,
        data: {
          title: "For Storage Operators",
          ogImage: "GrainChain-Storage-Operators.webp",
          description:
            "GrainChain's innovative suite of products helps storage operators, producers, and drivers work together in a better, faster and more secure way.",
        },
      },
      {
        path: "blockchain",
        component: BlockchainComponent,
        data: {
          title: "Blockchain",
          ogImage: "GrainChain-Blockchain-and-Smart-Contract.webp",
          description:
            "Using the latest advances in blockchain technology, GrainChain has developed an approach to solve industry-wide problems while building security, transparency and efficiency into our solutions.",
        },
      },
      {
        path: "producers",
        component: ProducerComponent,
        data: {
          title: "For Producers",
          ogImage: "GrainChain-Producers.webp",
          description:
            "GrainChain's suite of products work together to level the playing field for producers, facilitating prompt payments and increasing the accuracy, efficiency and security of commodity data.",
        },
      },
      {
        path: "buyers",
        component: BuyersComponent,
        data: {
          title: "For Buyers",
          ogImage: "GrainChain-Buyers.webp",
          description:
            "GrainChain's blockchain and IoT-driven technology utilizes smart contracts to build a better market for buyers and all participants along the global agricultural supply chain.",
        },
      },
      {
        path: "terms-of-services",
        component: TermsServicesComponent,
        data: {
          title: "Terms of Service",
          ogImage: "GrainChain-Home.webp",
          description: "GrainChain Terms of Service",
        },
      },
      {
        path: "privacy-policy",
        component: PrivacyPolicyComponent,
        data: {
          title: "Privacy Policy",
          ogImage: "GrainChain-Home.webp",
          description: "GrainChain Privacy Policy",
        },
      },
      {
        path: "classic22",
        component: CommodityClassic2022Component,
        data: {
          title: "Commodity Classic 2022",
        },
      },
      {
        path: "worldagritech",
        component: CommodityClassic2022Component,
        data: {
          title: "World Agri-Tech South America 2022",
        },
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./admin/admin.module").then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: "admin/login",
    component: AdminLoginComponent,
    data: {
      title: "Admin",
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      initialNavigation: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
