import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Home } from '../types/home.type';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private httpClient: HttpClient) { }

  getLang() {
    const lang = localStorage.getItem('lang');

    return lang ? JSON.parse(lang) : 'English';
  }

  get(home: Home) {
    const lang = this.getLang();

    return this.httpClient.get(environment.apiEndpoint + 'home/' + home.route + '/' + lang);
  }

  updateContent(home: Home) {
    return this.httpClient.post(environment.apiEndpoint + 'home/' + home.route + '/' + home.id + '/content', home.data);
  }

  updateImage(home: Home) {
    return this.httpClient.post(environment.apiEndpoint + 'home/' + home.route + '/' + home.id + '/image', home.data);
  }

  getAll(home: Home) {
    return this.httpClient.get(environment.apiEndpoint + 'home/' + home.route);
  }

  save(home: Home) {
    return this.httpClient.post(environment.apiEndpoint + 'home/' + home.route, home.data);
  }

  remove(home: Home) {
    return this.httpClient.delete(environment.apiEndpoint + 'home/' + home.route + '/' + home.id);
  }
}

