import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Agreement } from "../types/agreement.type";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AgreementService {
  constructor(private httpClient: HttpClient) {}

  get(agreement: Agreement) {
    return this.httpClient.get(
      environment.apiEndpoint + "agreement/" + agreement.route
    );
  }

  save(agreement: Agreement) {
    return this.httpClient.post(
      environment.apiEndpoint + "agreement/" + agreement.route,
      agreement.data
    );
  }
}
