import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AdminService } from "./../services/admin.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare var $ :any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd,Router } from "@angular/router";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from "./../../services/login.service";
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {
  adminDetails:any;
  modalRefUpdate: BsModalRef | null;
  updateInfo: FormGroup;
  profileImg:any;
  submitted = false;
  userDetails:any;
  langs:any
    public files: any[];
    selLang:any
  public infos = { fname:'',  lname: '',email:'',phone:'',file:'',userId:'' };
  constructor(public translate: TranslateService,private loginService: LoginService,private adminService: AdminService,private modalService: BsModalService,private formBuilder:FormBuilder,private ngxService: NgxUiLoaderService,private toastr: ToastrService,private router : Router, @Inject(DOCUMENT) private _doc: Document) { }

  ngOnInit() {
    this.ngxService.start();
    this.adminService.getListLang().subscribe(
      res => {
        this.langs = res['success']
        let lng = this.loginService.getLang()
        if(lng){
          this.selLang = lng
        }else{
          this.selLang = "English"
        }
      
        this.ngxService.stop();
      },
      err => { 
        this.ngxService.stop();
        
      }
    )

    if(this._doc.defaultView.innerWidth <= 567) {
      // if larger or equal
      this._doc.getElementById('contain').classList.add('hide-sidebar-admin');
    }

    this.userDetails = this.adminService.getAdminDetails();
    this.updateInfo = this.formBuilder.group({    
      email: ['', [Validators.required,Validators.email]],
      phone: ['', [Validators.required]],

  });
    this.adminDetails =   this.adminService.getAdminDetails()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth <= 567) {
      // if larger or equal
      this._doc.getElementById('contain').classList.add('hide-sidebar-admin');
    }
  }

  sidebarToggle(){
    this._doc.getElementById('contain').classList.toggle('hide-sidebar-admin');
   }

   logout(){
    this.adminService.logout().subscribe((res) => {
        this.toastr.success(res['message']);
        this.adminService.deleteToken();
        this.adminService.deleteAdminDetails();
      
        this.router.navigate(["admin/login"])
      },
      err => {
        this.toastr.error(err['message']);
      });
   }

   settings(template:any){
    this.modalRefUpdate = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
    this.infos.email =  this.userDetails.email;
   }
   get upateF() { return this.updateInfo.controls; }

   update(){
  
        this.submitted = true;
        if (this.updateInfo.invalid) {
          this.toastr.error('Please provide the required information.');
             return;
       }
       this.ngxService.start()
       const formData = new FormData();
       formData.append('id', this.adminDetails.id);
       formData.append('email', this.infos.email);
       formData.append('password', this.infos.phone);
    
  

       this.adminService.updateUserAdmin(formData).subscribe((result) => {
        let AdminDetails = this.adminService.getAdminDetails();
      
        AdminDetails.email = this.infos.email
     this.adminService.setAdminDetails(AdminDetails)
     this.adminDetails = this.adminService.getAdminDetails();
        this.ngxService.stop();
        this.modalRefUpdate.hide();
       this.toastr.success('Your information updated successfully.', 'Success');
          
       }, (err) => {
        try{
          if(err.error.error.email){
            this.toastr.error(err.error.error.email[0]);
          }else if(err.error.error.c_password){
            this.toastr.error("password and confirm password does not matched.");
          }else if(err.error.error == "phoneError"){
            this.toastr.error("Provide phone number with country code..");
          }
          else if(err.error.error.phone){
            this.toastr.error("This phone number is already registerd.");
          }
        }catch(e){
          this.toastr.error("Internal server error.");
        }
        this.ngxService.stop();
        
       });
    
      }
      changeLang() {  
        this.loginService.setLang(this.selLang)
         localStorage.setItem('locale', this.selLang);  
       //  this.translate.use(language);  
         this._doc.defaultView.location.reload()
       }
}
