import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import {environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router
      ) {}

      intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const idToken = localStorage.getItem("tokenAdmin");
        if((/^\/assets\//.test(req.url)) || !(req.url.includes(`${environment.apiEndpoint}`)) || !idToken) {
            return next.handle(req);
        }
        else {
            const request = req.clone({
                headers: req.headers.set("Authorization",
                    "Bearer " + idToken)
            });

            return next.handle(request).pipe(
                catchError((err: HttpErrorResponse) => {
          
                  if (err.status === 401) {

                    console.log('HTTP STATUS 401');

                    this.router.navigateByUrl('/admin/login');
                  }
          
                  return throwError( err );
          
                })
            );
        }
    }
}