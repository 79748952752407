<section
  class="banner home-banner home-section bg-white"
  [ngStyle]="{ 'background-image': 'url(\'assets/images/banner.webp\')' }"
>
  <div class="container">
    <div class="banner-item-list">
      <div class="banner-item">
        <h1
          class="banner-title wow animate__animated animate__fadeInUp"
          [innerHtml]="title"
        ></h1>
      </div>
    </div>
  </div>
</section>

<section class="what-we-do first-box bg-white">
  <div class="container px-2">
    <div class="what-we-do-content inside-first-box bg-white no-max-width">
      <h2
        class="
          title
          wow
          animate__animated animate__fadeInUp
          text-md-left
          ml-md-5
        "
      >
        <span>Share Your Contact Information</span>
      </h2>
      <form (ngSubmit)="onSubmit(f)" #f="ngForm">
        <div class="row">
          <div class="col px-4 px-md-3">
            <div class="form-group px-md-5">
              <label>Name</label
              ><!-- Or Upload your business card (JPG, PNG, PDF) -->
              <input
                type="text"
                class="form-control"
                placeholder="Enter your name"
                [(ngModel)]="model.name"
                name="name"
                required
                #name="ngModel"
              />
              <!-- <div class="d-flex align-items-center mt-2">
                <label
                  for="businessCard"
                  type="button"
                  class="btn btn-secondary mt-1"
                >
                  UPLOAD
                </label>
                <p class="mt-3">
                  &nbsp;{{ businessCardFileName || "(Optional)" }}
                </p>
              </div>
              <input
                id="businessCard"
                type="file"
                class="d-none"
                accept="image/png, image/gif, image/jpeg"
                (change)="businessCardChange($event)"
              /> -->
            </div>
            <div class="form-group px-md-5">
              <label>E-mail:</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter your email"
                [(ngModel)]="model.email"
                name="email"
                required
                #email="ngModel"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              />
            </div>
            <!-- <div class="form-group px-md-5">
              <label>Phone:</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter your phone"
              />
            </div> -->
            <div class="form-group px-md-5">
              <label>Message:</label>
              <textarea
                [(ngModel)]="model.message"
                name="message"
                class="form-control"
                placeholder="What solutions are you interested in talking about?"
                required
              ></textarea>
            </div>
          </div>
        </div>
        <button
          type="submit"
          [disabled]="!f.form.valid"
          class="btn btn-primary btn-lg btn-block submit-button"
        >
          SUBMIT
        </button>
      </form>
    </div>
  </div>
</section>

<section class="what-we-do mt-5 bg-white">
  <div class="container px-2">
    <div class="what-we-do-content inside-second-box bg-white no-max-width">
      <h2
        class="
          title
          wow
          animate__animated animate__fadeInUp
          text-md-left
          ml-md-5
        "
      >
        <span>The GrainChain Team</span>
      </h2>
      <div class="row">
        <div class="col">
          <div class="team-member-main bg-white">
            <div class="team-member-list">
              <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                  <div
                    class="team-member wow animate__animated animate__fadeInUp"
                  >
                    <img
                      src="assets/images/team/Mr-Luis-Macias-Grain-Chain.webp"
                      class="img-fluid team-member-pic"
                      alt=""
                    />
                    <h4 class="team-member-name mb-0">LUIS MACIAS</h4>
                    <p class="team-member-post">
                      <em>Co-Founder, CEO & Board Member</em>
                    </p>
                    <p class="mb-0">lmacias@grainchain.io</p>
                    <p class="mb-0">+1 956-225-7834 / USA</p>
                    <p class="mb-0">+52 33-2655-2572 / MX</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div
                    class="team-member wow animate__animated animate__fadeInUp"
                  >
                    <img
                      src="assets/images/team/Fernando-Campos-Grain-Chain.webp"
                      class="img-fluid team-member-pic"
                      alt=""
                    />
                    <h4 class="team-member-name mb-0">FERNANDO CAMPOS</h4>
                    <p class="team-member-post">
                      <em>Co-Founder & Chief Operating Officer</em>
                    </p>
                    <p class="mb-0">fcampos@grainchain.io</p>
                    <p class="mb-0">+1 305-469-0134 / USA</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div
                    class="team-member wow animate__animated animate__fadeInUp"
                  >
                    <img
                      src="assets/images/team/Jaime-Lopez-Grain-Chain.webp"
                      class="img-fluid team-member-pic"
                      alt=""
                    />
                    <h4 class="team-member-name mb-0">JAIME LOPEZ</h4>
                    <p class="team-member-post">
                      <em>Co-Founder, Chief Strategy Officer & Board Member</em>
                    </p>
                    <p class="mb-0">jlopez@grainchain.io</p>
                    <p class="mb-0">+1 956-874-4527 / USA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="what-we-do mt-5 bg-white">
  <div class="container px-2">
    <div class="what-we-do-content inside-third-box bg-white no-max-width pb-5">
      <h2
        class="
          title
          wow
          animate__animated animate__fadeInUp
          text-md-left
          ml-md-5
          mb-5
        "
      >
        <span>Our Suite of Products</span>
      </h2>
      <div class="row px-4 mt-5">
        <div class="col px-0">
          <img
            (click)="goTo('/seed-audit')"
            src="assets/images/commodity-classic/SeedauditGC.webp"
            class="img-fluid product"
            alt=""
          />
        </div>
        <div class="col px-0">
          <img
            (click)="goTo('/harvx')"
            src="assets/images/commodity-classic/HarvXGC.webp"
            class="img-fluid product"
            alt=""
          />
        </div>
        <div class="col px-0">
          <img
            (click)="goTo('/silosys')"
            src="assets/images/commodity-classic/SSGC.webp"
            class="img-fluid product"
            alt=""
          />
        </div>
        <div class="col px-0">
          <img
            (click)="goTo('/trumodity')"
            src="assets/images/commodity-classic/TRUMODITYGC.webp"
            class="img-fluid product"
            alt=""
          />
        </div>
        <div class="col px-0">
          <img
            (click)="goTo('/silosys-transformation')"
            src="assets/images/commodity-classic/SSTGC.webp"
            class="img-fluid product"
            alt=""
          />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col px-5">
          <div class="d-flex justify-content-center">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/kgZxMdaDdmM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="commodity-footer bg-white"></div>
