import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product } from '../types/product.type';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient) { }

  getLang() {
    const lang = localStorage.getItem('lang');

    return lang ? JSON.parse(lang) : 'English';
  }

  get(product: Product) {
    const lang = this.getLang();

    return this.httpClient.get(environment.apiEndpoint + 'product/' + product.route + '/' + lang);
  }

  updateContent(product: Product) {
    return this.httpClient.post(environment.apiEndpoint + 'product/' + product.route + '/' + product.id + '/content', product.data);
  }

  updateImage(product: Product) {
    return this.httpClient.post(environment.apiEndpoint + 'product/' + product.route + '/' + product.id + '/image', product.data);
  }
}
