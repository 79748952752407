import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Contactform } from "../models/contactform";
import { NgForm } from "@angular/forms";
import { LoginService } from "../services/login.service";
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";

@Component({
  selector: "app-commodity-classic2022",
  templateUrl: "./commodity-classic2022.component.html",
  styleUrls: ["./commodity-classic2022.component.css"],
})
export class CommodityClassic2022Component implements OnInit {
  // @ViewChild("businessCard") businessCard: any;
  businessCardFileName: string = "";
  title: string = "";

  model = new Contactform("", "", "", "");
  submitted = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private loginService: LoginService
  ) {
    if (this.router.url === "/classic22") {
      this.title = "Commodity Classic <strong>2022</strong>";
    }

    if (this.router.url === "/worldagritech") {
      this.title = "World Agri-Tech South America <strong>2022</strong>";
    }
  }

  ngOnInit(): void {}

  onSubmit(f) {
    this.submitted = true;
    console.log(f);
    if (f.invalid) {
      this.toastr.error("Please provide the required information.");
      return;
    }
    this.ngxService.start();
    this.loginService.contact(this.model).subscribe(
      (result) => {
        this.toastr.success("Your message sent successfully.");
        f.reset();
        this.ngxService.stop();
      },
      (err) => {
        this.ngxService.stop();
      }
    );
  }

  businessCardChange(e: any) {
    if (e.target.value) {
      this.businessCardFileName = e.target.value.split("\\").pop();
    } else {
      this.businessCardFileName = "";
    }
  }

  get diagnostic() {
    return JSON.stringify(this.model);
  }

  goTo(url: string) {
    this.router.navigate([url]);
  }
}
