<ng-container *ngIf="contentLoaded">
    <section
        class="banner banner-producer banner-2 bg-transparent"
        [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
    >
        <div class="container-inner">
            <div class="banner-content">
                <h1
                    class="
                        title
                        text-white
                        wow
                        animate__animated animate__fadeInUp
                    "
                >
                    <span>{{ content.a1_text }} </span>
                </h1>
                <p
                    class="
                        text-white
                        wow
                        animate__animated animate__fadeInUp animate__delay0-5s
                    "
                >
                    {{ content.b1_text }}
                </p>
                <app-book-ademo></app-book-ademo>
            </div>
        </div>
        <!-- scroll addded -->
        <div class="btn-scroll-wrapper" (click)="scroll()">
            <button
                class="
                    btn-scroll
                    wow
                    animate__animated animate__fadeInUp animate__delay-1s
                "
            >
                <span class="fa fa-angle-down"></span>
            </button>
        </div>
    </section>

    <!-- buyers Challenges -->
    <section #scrollHere class="buyers-challenges producer-buyers-challenges">
        <div class="container-inner">
            <div class="buyers-challenges-inner">
                <div class="left">
                    <div
                        class="
                            left-inner
                            wow
                            animate__animated animate__fadeInUp
                        "
                    >
                        <img
                            class="img-fluid"
                            src="{{ content.b1_image }}"
                            alt=""
                        />
                        <h2 class="font-weight-bold">
                            {{ content.c1_text }}
                        </h2>
                    </div>
                </div>
                <div
                    class="
                        right
                        wow
                        animate__animated animate__fadeInUp animate__delay0-5s
                    "
                >
                    <div [innerHtml]="content.d1_text"></div>
                </div>
            </div>
        </div>
    </section>
    <!-- buyers Challenges -->

    <!-- benefits  details -->
    <section class="benefits buyers-benefits">
        <h2
            class="
                title
                font-weight-bold
                bottom bottom-center
                wow
                animate__animated animate__fadeInUp
            "
        >
            <span>{{ content.e1_text }}</span>
        </h2>
        <app-solution-footer></app-solution-footer>
    </section>

    <div class="buyer-benefit-shape mobile-hide">
        <img
            class="img-fluid w-100"
            src="assets/images/buyers/buyers-shape-bg-1.webp"
            alt=""
        />
    </div>

    <!-- product-details -->
    <div class="product-details">
        <img
            src="assets/images/buyers/grey-shape-2.webp"
            alt=""
            class="img-fluid desktop-hide"
        />
        <div class="product-details-inner" style="background: none">
            <div class="container">
                <h2
                    class="
                        title
                        orange
                        small
                        bottom bottom-center
                        font-weight-bold
                        wow
                        animate__animated animate__fadeInUp
                    "
                >
                    <span> {{ content.f1_text }}</span>
                </h2>

                <app-product-footer hideProduct="silosys-transformation"></app-product-footer>
            </div>
        </div>
    </div>

    <!-- shape -->
    <div class="bottom-img-shape">
        <img
            src="assets/images/buyers/buyers-shape-bg-2.webp"
            class="img-fluid desktop-hide w-100"
            alt=""
        />
    </div>
    <!-- shape -->
</ng-container>
