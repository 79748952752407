<ng-container *ngIf="contentLoaded">
  <div class="product-details-inner">
    <div class="container">
      <!-- product list  -->
      <div class="product-list">
        <div class="row">
          <div class="col-12 col-lg" *ngIf="hideProduct !== 'trumodity' && !isInHideProducts('trumodity')">
            <div
              class="product-details-box text-center wow animate__animated animate__fadeInUp animate__delay0-5s"
            >
              <img
                class="img-fluid"
                src="{{ content.a1_image }}"
                alt=""
                style="cursor: pointer"
                (click)="redirect('trumodity')"
              />
              <p>
                {{ content.a1_text }}
              </p>
            </div>
          </div>
          <div class="col-12 col-lg" *ngIf="hideProduct !== 'seed-audit' && !isInHideProducts('seed-audit')">
            <div
              class="product-details-box text-center wow animate__animated animate__fadeInUp animate__delay0-5s"
            >
              <img
                class="img-fluid"
                src="{{ content.a2_image }}"
                alt=""
                style="cursor: pointer"
                (click)="redirect('seed-audit')"
              />
              <p>
                {{ content.a2_text }}
              </p>
            </div>
          </div>
          <div class="col-12 col-lg" *ngIf="hideProduct !== 'silosys' && !isInHideProducts('silosys')">
            <div
              class="product-details-box text-center wow animate__animated animate__fadeInUp animate__delay0-5s"
            >
              <img
                class="img-fluid"
                src="{{ content.a3_image }}"
                alt=""
                style="cursor: pointer"
                (click)="redirect('silosys')"
              />
              <p>
                {{ content.a3_text }}
              </p>
            </div>
          </div>
          <div class="col-12 col-lg" *ngIf="hideProduct !== 'silosys-transformation' && !isInHideProducts('silosys-transformation')">
            <div
              class="product-details-box text-center wow animate__animated animate__fadeInUp animate__delay0-5s"
            >
              <img
                class="img-fluid"
                src="{{ content.a4_image }}"
                alt=""
                style="cursor: pointer"
                (click)="redirect('silosys-transformation')"
              />
              <p>
                {{ content.a4_text }}
              </p>
            </div>
          </div>
          <div class="col-12 col-lg" *ngIf="hideProduct !== 'harv-x' && !isInHideProducts('harv-x')">
            <div
              class="product-details-box text-center wow animate__animated animate__fadeInUp animate__delay0-5s"
            >
              <img
                class="img-fluid"
                src="{{ content.a5_image }}"
                alt=""
                style="cursor: pointer"
                (click)="redirect('harvx')"
              />
              <p>
                {{ content.a5_text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action wow animate__animated animate__fadeInUp animate__delay0-8s">
      <a class="btn bg-green text-white" routerLink="/products">
        <span>{{ content.b1_text }}</span>
        <img src="assets/images/buyers/arrow-product.webp" alt=""/>
      </a>
    </div>
  </div>
</ng-container>