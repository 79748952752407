import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { About } from '../types/about.type';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

  constructor(private httpClient: HttpClient) { }

  getLang() {
    const lang = localStorage.getItem('lang');

    return lang ? JSON.parse(lang) : 'English';
  }

  get(about: About) {
    const lang = this.getLang();

    return this.httpClient.get(environment.apiEndpoint + 'about/' + about.route + '/' + lang);
  }

  updateContent(about: About) {
    return this.httpClient.post(environment.apiEndpoint + 'about/' + about.route + '/' + about.id + '/content', about.data);
  }

  updateImage(about: About) {
    return this.httpClient.post(environment.apiEndpoint + 'about/' + about.route + '/' + about.id + '/image', about.data);
  }

  getAll(about: About) {
    return this.httpClient.get(environment.apiEndpoint + 'about/' + about.route);
  }

  save(about: About) {
    return this.httpClient.post(environment.apiEndpoint + 'about/' + about.route, about.data);
  }

  remove(about: About) {
    return this.httpClient.delete(environment.apiEndpoint + 'about/' + about.route + '/' + about.id);
  }

  saveWithLang(about: About) {
    const lang = this.getLang();

    return this.httpClient.post(environment.apiEndpoint + 'about/' + about.route + '/' + lang, about.data);
  }
}
