import { Component, Input, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { Product } from '../../../types/product.type';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-product-timeline',
  templateUrl: './product-timeline.component.html',
  styleUrls: ['./product-timeline.component.scss']
})
export class ProductTimelineComponent implements OnInit {

  content: any = {};
  contentLoaded: boolean = false;
  @Input() selected: string = 'seed-audit';
  products: Array<string> = [
    'seed-audit',
    'harv-x',
    'silosys',
    'trumodity',
    'silosys-transformation',
  ];

  isMobile: boolean = false;

  constructor(private ngxService: NgxUiLoaderService, private productService: ProductService, @Inject(DOCUMENT) private _doc: Document) { }

  ngOnInit(): void {
    this.checkMobile();
    this.ngxService.start('loading-product-timeline');

    const product: Product = {
      route: 'timeline',
      id: 0,
      data: null
    }

    this.productService.get(product)
    .toPromise()
    .then((data: any) => {
      this.content = data;
      this.contentLoaded = true;
      this.ngxService.stop('loading-product-timeline');
    }, () => {
      this.ngxService.stop('loading-product-timeline');
    });
  }

  @HostListener('window:resize')
  checkMobile() {
    this.isMobile = this._doc.defaultView.innerWidth <= 768;
  }

  get isLast() {
    return this.products.indexOf(this.selected) === this.products.length - 1;
  }

  get isFirst() {
    return this.products.indexOf(this.selected) === 0;
  }

  select(value: string) {
    this.selected = value;
  }

  back() {
    if (!this.isFirst) {
      const index = this.products.indexOf(this.selected);

      this.select(this.products[index - 1]);
    }
  }

  next() {
    if (!this.isLast) {
      const index = this.products.indexOf(this.selected);

      this.select(this.products[index + 1]);
    }
  }

}
