<ng-container *ngIf="contentLoaded">
    <!-- banner -->
    <section
        class="banner banner-team bg-white banner-2"
        [ngStyle]="{ 'background-image': 'url(' + content.a1_image + ')' }"
    >
        <div class="container-inner">
            <div class="banner-content">
                <h1
                    class="
                        title
                        text-white
                        wow
                        animate__animated animate__fadeInUp
                    "
                >
                    <span>{{ content.a1_text }}</span>
                </h1>
                <p
                    class="
                        text-white
                        wow
                        animate__animated animate__fadeInUp animate__delay0-5s
                    "
                >
                    {{ content.b1_text }}
                </p>
            </div>
        </div>
        <!-- scroll addded -->
        <div class="btn-scroll-wrapper" (click)="scroll()">
            <button
                class="
                    btn-scroll
                    wow
                    animate__animated animate__fadeInUp animate__delay-1s
                "
            >
                <span class="fa fa-angle-down"></span>
            </button>
        </div>
        <!-- scroll addded -->
    </section>
    <!-- banner ends -->

    <section class="team-intro">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div
                        class="
                            team-intro-content
                            wow
                            animate__animated animate__fadeInUp
                        "
                    >
                        <p>{{ content.c1_text }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- shape -->
    <img
        src="assets/images/team/gray-bg-1.webp"
        class="img-fluid w-100"
        alt=""
    />

    <!-- team member -->
    <div #scrollHere class="team-member-main">
        <div class="container">
            <h2
                class="
                    title
                    font-weight-bold
                    bottom bottom-center
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <span>{{ content.d1_text }}</span>
            </h2>
            <div class="team-member-list">
                <div class="row justify-content-center">
                    <div
                        class="col-md-6 col-lg-3"
                        *ngFor="
                            let member of content.members;
                            let index = index
                        "
                    >
                        <div
                            class="
                                team-member
                                wow
                                animate__animated animate__fadeInUp
                            "
                        >
                            <img
                                src="{{ member.a1_image }}"
                                class="img-fluid team-member-pic"
                                alt=""
                            />
                            <h4 class="team-member-name">
                                {{ member.a1_text }}
                            </h4>
                            <p class="team-member-post">{{ member.b1_text }}</p>
                            <button
                                (click)="team(index)"
                                class="btn btn-primary btn-view-more btn-bio"
                                data-member-id="#luis"
                            >
                                {{ "bio" | translate }}
                            </button>
                            <div
                                class="bio-details"
                                [ngClass]="{ active: selectedMember === index }"
                                id="luis"
                            >
                                {{ member.c1_text }}
                                <div class="close-info">
                                    <button
                                        (click)="close($event)"
                                        class="
                                            btn
                                            btn-primary
                                            btn-view-more
                                            btn-bio
                                        "
                                        data-member-id="#luis"
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- team member -->

    <!-- about grainchain inc -->
    <section class="about-grainchain-inc about-grainchain-inc-team">
        <div class="container-inner">
            <h2
                class="
                    title
                    font-weight-bold
                    bottom bottom-center
                    orange
                    wow
                    animate__animated animate__fadeInUp
                "
            >
                <span>{{ content.e1_text }}</span>
            </h2>
            <div class="text-center" [innerHtml]="content.f1_text"></div>
            <button
                class="
                    btn btn-primary btn-view-more
                    wow
                    animate__animated animate__fadeInUp animate__delay0-5s
                "
                [routerLink]="content.a1_link"
            >
                {{ "learn" | translate }}
            </button>
        </div>
    </section>
    <!-- about grainchain inc -->

    <!-- home contact -->
</ng-container>
