import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgwWowService } from 'ngx-wow';

import { Product } from '../types/product.type';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-silosys-transformation',
  templateUrl: './silosys-transformation.component.html',
  styleUrls: ['./silosys-transformation.component.css']
})
export class SilosysTransformationComponent implements OnInit {

  content: any = {};
  contentLoaded: boolean = false;
  selectedExample: 'wheat' | 'palm-oil' | 'coffee' = 'wheat';
  @ViewChild('scrollHere') scrollHere: any;

  constructor(private ngxService: NgxUiLoaderService, private wowService: NgwWowService, private productService: ProductService, @Inject(DOCUMENT) private _doc: Document) { }

  ngOnInit(): void {
    this.wowService.init(); 
    this.ngxService.start('loading-silosys-transformation');

    const product: Product = {
      route: 'silosys-transformation',
      id: 0,
      data: null
    }

    this.productService.get(product)
    .toPromise()
    .then((data: any) => {
      this.content = data;
      this.contentLoaded = true;
      this.ngxService.stop('loading-silosys-transformation');
    }, () => {
      this.ngxService.stop('loading-silosys-transformation');
    });
  }

  selectExample(example: 'wheat' | 'palm-oil' | 'coffee') {
    this.selectedExample = example;
  }

  getExampleDescription() {
    if (this.selectedExample === 'wheat')
      return this.content.h1_text;
    if (this.selectedExample === 'palm-oil')
      return this.content.h2_text;
    if (this.selectedExample === 'coffee')
      return this.content.h3_text;
    return '';
  }

  getExampleImage() {
    if (this.selectedExample === 'wheat')
      return this.content.f1_image;
    if (this.selectedExample === 'palm-oil')
      return this.content.f2_image;
    if (this.selectedExample === 'coffee')
      return this.content.f3_image;
    return '';
  }

  scroll(){
    var element = this.scrollHere.nativeElement;
    const offset = 45;
    const bodyRect = this._doc.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    
    this._doc.defaultView.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
    
  }

  redirectTo(link) {
    this._doc.defaultView.open(link, "_blank");
  }

}
