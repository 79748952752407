<ngx-ui-loader></ngx-ui-loader>
<div class="app header-fixed sidebar-fixed aside-menu-fixed sidebar-lg-show changeFont" >
<app-admin-header></app-admin-header>
<div class="app-body" style="margin:0px;">
<app-admin-sidebar></app-admin-sidebar>
<main class="main">
        <router-outlet></router-outlet>
        </main>
</div>


</div>

<style>
.changeFont{font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

</style>